import { Component, EventEmitter, OnInit } from '@angular/core';
import { ROUTES } from '../../../core/consts/routing';
import { Router } from '@angular/router';
import { Subscription, takeUntil } from 'rxjs';
import { ChatService } from '../../../core/services/chat.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserService } from 'libs/angular/src/services/user.service';
@Component({
  selector: 'app-global-footer',
  templateUrl: './global-footer.component.html',
  styleUrls: ['./global-footer.component.scss'],
})
export class GlobalFooterComponent implements OnInit {
  public FULL_ROUTES = ROUTES;
  validPaths: string[] = [
    `/${this.FULL_ROUTES.Settings._Path}`,
    `/${this.FULL_ROUTES.Settings.PersonalDetails._Path}`,
    `/${this.FULL_ROUTES.Settings.HelpAndSupport._Path}`,
    `/${this.FULL_ROUTES.Settings.Report._Path}`,
    `/${this.FULL_ROUTES.Settings.Contact._Path}`,
  ];

  destroyed$ = new EventEmitter<void>();
  unreadMessageCounts: number = 0;
  private subscription: Subscription;
  loggedRole: any;
  user: any;

  constructor(
    public Router: Router,
    private chatService: ChatService,
    private auth: AngularFireAuth,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.loggedRole = localStorage.getItem('loggedAsRole')
    this.auth.user.subscribe({
      next: (res: any) => {
        this.userService.read(res?.uid).subscribe((res:any)=>{
          this.user = res;
          this.loggedRole = this.user.role
          this.subscription = this.chatService
          .getRealTimeChatRoomsByUserWithUnread(this.user?.id)
          .subscribe((updatedChatRooms) => {
            this.unreadMessageCounts = updatedChatRooms.reduce(
              (total, room) => total + room.unreadMessageCount,
              0
            );
          });
        })
      }
      })
  }

  handleActiveClass() {
    const currentRoute = this.Router.url;
    return this.validPaths.some((currentRoute) => {
      return this.Router.isActive(currentRoute, true);
    });
  }

  handleNavigateToSettings() {
    this.Router.navigateByUrl(ROUTES.Settings._Path);
  }

  handleNavigateToHostSettings() {
    this.Router.navigateByUrl(ROUTES.HostSettings._Path);
  }

  redirectToChat() {
    this.Router.navigateByUrl(ROUTES.ChatApp.LIST._Path);
  }

  redirectToHome() {
    if (this.user.role == 'Guest') {
      this.Router.navigateByUrl(ROUTES.Home._Path);
    } else {
      this.Router.navigateByUrl(ROUTES.HomeHost._Path);
    }
  }
  redirectToTrips() {
    this.Router.navigateByUrl('/host-trip/my-trips');
  }
}
