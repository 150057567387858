import { Path, BuildPaths, ParamPath } from '@specno/routes';
import {
  AUTH,
  Auth,
  HOME,
  HOME_HOST,
  LISTING,
  Listing,
  TRIP_IN_PROGRESS,
  VERIFICATION,
  REQUEST,
  SETTINGS,
  Settings,
  LEGAL,
  Legal,
  HOST_BENEFITS,
} from './paths';
import { ALLVEHCILES } from './paths/all-vehicle';
import { HEART } from './paths/heart';
import { CHATAPP, chatApp, chatRouteParams } from './paths/chat-app';
import { TRIP_START } from './paths/trip-start';
import { BOOKING_DETAILS, Booking_details } from './paths/booking-details';
import { Booking, BOOKING } from './paths/booking';
import { Booking_Summary, BOOKING_SUMMARY } from './paths/booking-summary';
import { PAYMENT_GATEWAY } from './paths/payment-gateway';
import { TRIP } from './paths/trip';
import { CAR_DETAIL, car_detail } from './paths/car-detail';
import { NOTIFICATION, Notification_details } from './paths/notification';
import { HOSTSETTINGS, HostSettings } from './paths/host-setting';

export const ROUTES = BuildPaths({
  Auth: Path(AUTH, {
    Landing: Path(Auth.LANDING),
    Explainer: Path(Auth.EXPLAINER),
    SignUp: Path(Auth.SIGN_UP),
    LogIn: Path(Auth.LOG_IN),
    ForgotPassword: Path(Auth.FORGOT_PASSWORD),
    ResetPassword: Path(Auth.RESET_PASSWORD),
    Mobile: Path(Auth.MOBILE_VERIFICATION),
    Success: Path(Auth.SUCCESS),
    PaymentLoader: Path(Auth.PAYMENT_LOADING),
  }),
  Booking: Path(BOOKING, {
    BOOKING_DETAILS: Path(Booking.BOOKING_DETAILS, {
      Vehicle: ParamPath(Booking.VEHICLE_ID),
    }),
    BOOKING_SUMMARY: Path(Booking.BOOKING_SUMMARY, {
      Vehicle: ParamPath(Booking.VEHICLE_ID),
    }),
  }),
  Home: Path(HOME),
  HomeHost: Path(HOME_HOST),
  Listing: Path(LISTING, {
    Vehicle: ParamPath(Listing.VEHICLE_ID),
  }),
  AllVehicles: Path(ALLVEHCILES),
  CarDetail: Path(CAR_DETAIL, {
    Vehicle: ParamPath(car_detail.VEHICLE_ID),
  }),
  ChatApp: Path(CHATAPP, {
    LIST: Path(chatApp.List),
    MESSAGES: Path(chatApp.Messages, {
      chat_id: ParamPath(chatRouteParams.ChatId),
    }),
  }),

  Verification: Path(VERIFICATION),
  TripStart: Path(TRIP_START),
  // TripInProgress: Path(TRIP_IN_PROGRESS, {
  //   Vehicle: ParamPath(TripInProgress.VEHICLE_ID),
  // }),

  Request: Path(REQUEST),

  Settings: Path(SETTINGS, {
    Root: Path(Settings.ROOT),
    PersonalDetails: Path(Settings.PERSONAL_DETAILS),
    HelpAndSupport: Path(Settings.HELP_AND_SUPPORT),
    Report: Path(Settings.REPORT),
    Contact: Path(Settings.CONTACT),
    TermAndCondition: Path(Settings.TERMS_AND_CONDITIONS),
    Review: Path(Settings.REVIEW),
  }),

  HostSettings: Path(HOSTSETTINGS, {
    Root: Path(HostSettings.ROOT),
    HostPersonalDetails: Path(HostSettings.HOST_PERSONAL_DETAILS),
    HostHelpAndSupport: Path(HostSettings.HOST_HELP_AND_SUPPORT),
    HostReportAnIssue: Path(HostSettings.HOST_REPORT_AN_ISSUE),
    HostEarning: Path(HostSettings.HOST_EARNING),
    HostEarningDetails: Path(HostSettings.HOST_EARNING_DETAILS),
    HostReview: Path(HostSettings.HOST_REVIEW),
    HostRequestManagement:Path(HostSettings.HOST_REQUESTMANAGEMENT),
    HostRequestManagementView:Path(HostSettings.HOST_REQUESTMANAGEMENTVIEW)

  }),

  Heart: Path(HEART),
  Legal: Path(LEGAL, {
    TermsAndConditions: Path(Legal.TERMS_AND_CONDITIONS),
    PrivacyPolicy: Path(Legal.PRIVACY_POLICY),
    CancellationPolicy: Path(Legal.CANCELLATION_POLICY),
    ConstentForm: Path(Legal.CONSTENT_FORM),
  }),
  HostBenefits: Path(HOST_BENEFITS),
  BOOKING_DETAILS: Path(BOOKING_DETAILS, {
    Vehicle: ParamPath(Booking_details.VEHICLE_ID),
  }),
  BOOKING_SUMMARY: Path(BOOKING_SUMMARY, {
    Vehicle: ParamPath(Booking_Summary.VEHICLE_ID),
  }),
  PAYMENT_GATEWAY: Path(PAYMENT_GATEWAY),
  Trip: Path(TRIP),
  Notification: Path(NOTIFICATION),
  Notification_details: Path(NOTIFICATION, {
    notification: ParamPath(Notification_details.NOTIFICATION_ID),
  }),
});
