import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SplashScreenService {
  readonly shouldShowSplashScreen$ = new BehaviorSubject(true);

  show() {
    this.shouldShowSplashScreen$.next(true);
  }
  hide() {
    this.shouldShowSplashScreen$.next(false);
  }
  toggle() {
    if (this.shouldShowSplashScreen$.value) {
      return this.hide();
    }
    return this.show();
  }
}
