import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
  })
  export class ShardService {
  // changeTextHeader = new Subject<any>()
  private headerTextSubject = new BehaviorSubject<string>('');
  headerText$ = this.headerTextSubject.asObservable();

  updateHeaderText(newText: string) {
    this.headerTextSubject.next(newText);
  }
  }