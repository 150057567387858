<ion-content>
  <div class="h-100 d-flex justify-content-center align-items-center">
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-center">
        <img [src]="imgPath" class="l-img" />
      </div>
      <div class="mb-5 text-center">
        <h1 *ngIf="heading" class="heading">
          {{ heading }}<span>{{ subHeading }}</span>
        </h1>
        <h2 class="g-h2 g-text-white l-text">{{ title }}</h2>
        <p class="g-body-1-regular g-text-disabled-grey l-text" *ngIf="body">
          {{ body }}
        </p>
      </div>
      <div class="d-flex justify-content-center btnRoute">
        <div class="verifyBtn" *ngIf="buttonText1 == 'Done'">
          <ion-button (click)="close()" expand="block">{{
            buttonText1
          }}</ion-button>
        </div>
        <div class="closeBtn" *ngIf="!isChatRequest && buttonText1 != 'Done'">
          <ion-button (click)="close()" expand="block">{{
            buttonText
          }}</ion-button>
        </div>
        <div class="closeBtn" *ngIf="isChatRequest && buttonText1 != 'Done'">
          <ion-button (click)="redirectChat()" expand="block">{{
            buttonText
          }}</ion-button>
        </div>
        <br />
      </div>
    </div>
  </div>
</ion-content>
<ion-footer class="ion-no-border">
</ion-footer>
