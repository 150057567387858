import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateUtilService {
  constructor(
    private zone: NgZone,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  convertToOnlyDate(date: Date): Date {
    const adjustedDate = new Date(date);
    adjustedDate.setHours(0, 0, 0, 0);
    return adjustedDate;
  }

  updateDatesWithTime(bookingDetails: any): void {
    this.zone.run(() => {
      const { endDate, startDate, timeOfCollection } = bookingDetails;
      const endDateWithTime = new Date(endDate);
      const startDateWithTime = new Date(startDate);
      const [hours, minutes] = timeOfCollection.split(':');
      endDateWithTime.setHours(Number(hours), Number(minutes));
      startDateWithTime.setHours(Number(hours), Number(minutes));
      bookingDetails.endDate = endDateWithTime;
      bookingDetails.startDate = startDateWithTime;
    });
  }

}
