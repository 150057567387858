import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  userInfo: any;

  constructor(public httpClient: HttpClient) {}

  setHeaders() {
    let headers = new HttpHeaders();
      headers = headers.append('Authorization', `key=${environment.firebase.vapidKey}`);
      headers = headers.append("Content-Type", "application/json");
    return headers;
  }

  post(endpoint: string, data: any) {
    return this.httpClient.post(`${endpoint}`, data,
      {
        headers: this.setHeaders(),
      }
    );
  }

  triggerPush(data: any) { 
    return this.httpClient.post(`${environment.url}sendNotification`, data, { headers: this.setHeaders()});
  }

  sendEmailWithTemplateAndWithoutTemplate(data: any): Observable<any> {
    const url = `${environment.url}sendEmailWithTemplateAndWithoutTemplate`;
    return this.httpClient.post(url, data);
  }
}
