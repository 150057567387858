import { CloudFunction, User, UserRegistrationDTO } from '../models';

export const CreateUserCloudFunction = new CloudFunction<
  UserRegistrationDTO,
  User
>('userFunctions_CreateUser');

export const DeleteUserCloudFunction = new CloudFunction<never, boolean>(
  'userFunctions_DeleteUser'
);

export const SendNotificationCloudFunction = new CloudFunction<never, boolean>(
  'sendNotification'
);

export type UserCloudFunctions =
  | typeof CreateUserCloudFunction
  | typeof DeleteUserCloudFunction
  | typeof SendNotificationCloudFunction;
