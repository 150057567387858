import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { filter } from 'rxjs';
import { ErrorAction } from '../../../../../../libs/angular/src/models/errors';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private Actions: Actions, private ToastService: ToastService) {}

  listenToNGRXErrors() {
    this.listenToNGRXErrors = () => {};
    this.Actions.pipe(
      filter(
        (action: ErrorAction) => action.error && action.type.endsWith('Fail')
      )
    ).subscribe((action: ErrorAction) => this.handleNGRXError(action));
  }

  private handleNGRXError(action: ErrorAction) {
    if (action.showToast || action.toast) {
      if(action.error.code === 'auth/user-not-found')
      {
        this.ToastService.showBasic('The user record was not found');
      }else if(action.error.code ==='auth/wrong-password'){
        this.ToastService.showBasic('The password is Invalid');
      }else{
        this.ToastService.showBasicError(action.toast?.message);
      }
    }

    if (action.error) {
      console.groupCollapsed(
        `%c${action.type}`,
        'color:rgb(255, 128, 128); background-color:rgb(41, 0, 0);'
      );
      console.groupEnd();
    }
  }
}
