import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Vehicle, constructISODate } from '@tarlen/shared';
import * as fromVehicle from './vehicle.reducer';

export const selectVehicleState = createFeatureSelector<fromVehicle.State>(
  fromVehicle.vehicleFeatureKey
);

const { selectIds, selectEntities, selectAll, selectTotal } =
  fromVehicle.vehicleEntityAdapter.getSelectors();

export const selectVehicleEntities = createSelector(
  selectVehicleState,
  selectEntities
);

export const selectVehicles = createSelector(
  selectVehicleState,
  ({ ids, entities }) => {
    return ids.reduce((vehicles: Vehicle[], id) => {
      const vehicle = entities[id];
      if (typeof vehicle !== 'undefined') {
        vehicles.push(vehicle);
      }
      return vehicles;
    }, []);
  }
);

export const selectLastPaginationOptions = createSelector(
  selectVehicleState,
  (state) => state.lastPaginationOptions
);

export const selectVehicleById = (id: string) =>
  createSelector(selectVehicleState, ({ entities }) => entities[id]);

export const selectCurrentVehicle = createSelector(
  selectVehicleState,
  (state) => state.selectedVehicle
);

export const selectVehicleBookedDates = createSelector(
  selectVehicleState,
  ({ bookedDates }) =>
    new Set(bookedDates.map((date) => constructISODate(date)))
);
