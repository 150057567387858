
<ion-content >
<div  class="welcome_section">
<h4 class="heading">Welcome to Tarlen</h4>
<p class="subHeading">Congratulations on taking the first step towards a more connected, sustainable, and friendly way of traveling!</p>
<div class="container">
  <img  *ngIf="userRole == 'Guest'"src="../../../../assets/images/Guest/logo.png" alt="Logo">
  <img *ngIf="userRole == 'Host'" src="../../../../assets/images/Host/logo.png" alt="Logo">
</div>
<p class="subHeading">You're now part of a vibrant community that values sharing, caring, and exploring the world in the most delightful way possible.</p>
</div>
</ion-content>

<ion-footer [ngClass]="{'guest-background': userRole === 'Guest', 'host-background': userRole === 'Host'}">
<button (click)="updateDetails()">{{ userRole === 'Host' ? "Start Earning":'Start Exploring'}}
  <img src="../../../../assets/images/arrow_right.png" class="image">
</button>
</ion-footer>
