export function ConvertDateToTimestamp(date: any) {
    return new Date(date).getTime();
}
export function GetDateFromTimestamp(timestamp: number) {
    return new Date(timestamp);
}

export function AddTimeWithDate(date: any, timeOfCollection: any) {
    const parsedDate = new Date(date);
    const [hours, minutes] = timeOfCollection.split(':');
    parsedDate.setHours(Number(hours), Number(minutes));
    return parsedDate;
}

// Conver new Date the date/time in the south african timming 
export function convertDateTime(date: any = new Date()) {
    const saDateTime = new Date(date.toLocaleString('en-US', { timeZone: 'Africa/Johannesburg' }));
    // const saDateTime = new Date(date);
    return saDateTime;
}

// To check the exact date 
export function isSameDate(date1: Date, date2: Date): boolean {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    return d1.getTime() === d2.getTime();
}

// Format the date in "YYYY-MM-DD" format
export function dateFormatter(newDate: any) {
    const currentDate = convertDateTime(newDate);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function setHoursZero(date: any) {
    const new_date = new Date(date);
    new_date.setHours(0, 0, 0, 0);
    return new_date;
}

export function calculateReviewTime(bookingData: { startDate: any, prefferedTime: any }): string {
    const currentDate = new Date().toLocaleString('en-US', { timeZone: 'Africa/Johannesburg' });
    const preferredDateTime = new Date(AddTimeWithDate(
        bookingData?.startDate,
        bookingData?.prefferedTime
    ));
    
    // // Check if the preferred date and time are equal to or after the current date and time
    // if (preferredDateTime >= currentDate) {
    //     return ''; // Return an empty string if review time is not applicable
    // }
    
    const differenceInMilliseconds = preferredDateTime.getTime() - new Date(currentDate).getTime();
    const differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    
    let reviewTime: string;
    if (differenceInHours >= 24) {
        reviewTime = '24';
    } else {
        reviewTime = String(differenceInHours);
    }
    
    return reviewTime;
}

