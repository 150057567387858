import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationsCollection: AngularFirestoreCollection<any>;

  private notificationCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  notificationCount$ = this.notificationCountSubject.asObservable();

  constructor(private firestore: AngularFirestore, private http: HttpService) {
    this.notificationsCollection = firestore.collection('user_notifications');
  }

  // addNotification(payload1:any, payload2:any) {
  //   return this.notificationsCollection.add({
  //     userId: payload.userId,
  //     title: payload.title,
  //     message: payload.message,
  //     senderName:payload.senderName,
  //     timestamp: new Date(),
  //     read: false,
  //   });
  // }


  async addNotification(payload1: any, payload2: any) {
    const notificationPromises: any[] = [];  
    const addPayloadNotification = (payload:any) => {
      if (payload) {
        const notificationData:any = {
          userId: payload.userId,
          title: payload.title,
          message: payload.message,
          senderId: payload.senderId,
          type: payload.type,
          timestamp: new Date(),
          read: false,
        };
  
        if (payload?.reason) {
          notificationData['reason'] = payload.reason;
        }

        if (payload?.bookingId) {
          notificationData['bookingId'] = payload.bookingId;
        }

        if (payload?.senderName){
          notificationData['senderName'] = payload.senderName;

        }
        notificationPromises.push(this.notificationsCollection.add(notificationData));
      }
    };
  
    addPayloadNotification(payload1);
    addPayloadNotification(payload2);
  
    try {
      await Promise.all(notificationPromises);
    } catch (error) {
    }
  }
  

  getNotificationsForUser(userId: string): Observable<{ unreadNotificationCount: number; notifications: any[] }> {
    return this.firestore.collection('user_notifications', (ref) =>
      ref.where('userId', '==', userId).orderBy('timestamp', 'desc')
    ).valueChanges({ idField: 'notificationId' }).pipe(
      map((notifications: any[]) => {
        const unreadNotifications = notifications.filter(notification => !notification.read);
        return {
          unreadNotificationCount: unreadNotifications.length,
          notifications: notifications
        };
      })
    );
  }

  markNotificationAsRead(notificationId: string) {
    return this.notificationsCollection.doc(notificationId).update({ read: true });
  }

  markAllNotificationsAsRead() {
    const batch = this.firestore.firestore.batch();
    this.notificationsCollection.ref.get().then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        batch.update(doc.ref, { read: true });
      });
      return batch.commit();
    });
  }

  updateNotificationCount(newCount: number) {
    this.notificationCountSubject.next(newCount);
  }

  getNotificationDetails(notificationId: string): Observable<any> {
    return this.firestore.collection('user_notifications')
      .doc(notificationId)
      .valueChanges();
  }

  deleteNotificationsByUserId(userId: string) {
    return this.firestore
      .collection('user_notifications', (ref) =>
        ref.where('userId', '==', userId)
      )
      .get()
      .toPromise()
      .then((querySnapshot:any) => {
        querySnapshot.forEach((doc:any) => {
          doc.ref.delete();
        });
      })
      .catch((error) => {
      });
  }
}
