import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '@tarlen/angular';
import firebase from 'firebase/compat';
import { Observable, of } from 'rxjs';
import { tap, map, take, switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(): Observable<boolean> {
    return this.afAuth.authState.pipe(
      take(1),
      map((userData) => {
        if (userData) {
          this.userService.read(userData?.uid).subscribe((user: any) => {
            if (user?.createdByAdmin === true) {
              this.router.navigate(['create-new-password']);
            } else {
              if (user.role === 'Guest') {
                this.router.navigate(['/home']);
              } else {
                this.router.navigate(['/home-host']);
              }
            }
          });
          return false; // Prevent navigation to other routes
        } else {
          return true; // Allow navigation to other routes for unauthenticated users
        }
      })
    );
  }
}
