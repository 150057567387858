<div class="row d-flex align-items-center header" *ngIf="role!= 'Host'">
  <div class="col-2">
    <div *ngIf="!showBackButton">
      <img src="../../../../assets/images/Guest/Icon/TarlenIcon.svg" />
    </div>
    <div *ngIf="showBackButton" class="Go_back">
      <img src="../../../../assets/images/Guest/Icon/left-arrow.svg" (click)="goBack()" />
    </div>
  </div>
  <div class="col-8">
    <div
      [ngClass]="{headerRedText: urls?.url?.includes('delete_user'), headerText: !urls?.url?.includes('delete_user')}">
      <p>{{ headerText }}</p>
    </div>
  </div>
  <div class="col-2">
    <div class="d-flex position-relative buttonText" *ngIf="history">
      <button (click)="historyList()">History</button>
    </div>
    <div class="d-flex position-relative" *ngIf="showUser">
      <img src="../../../../assets/images/Guest/Icon/notificationIcon.svg" class="notification"
        (click)="redirectedToNotification()" />
      <div class="notification-badge" *ngIf="notificationCount > 0">
        {{ notificationCount }}
      </div>
      <div *ngIf="!profileData" (click)="redirectedToNotification()">
        <img src="../../../../../assets/images/Host/Icon/user.svg" alt="" />
      </div>
      <span *ngIf="profileData">
        <div class="OuterBox"*ngIf="!profileData?.profile_image">
          <h4 class="img" >{{ firstWordName | uppercase }}</h4>
        </div>
        <div class="OuterBox"*ngIf="profileData?.profile_image">
          <img class="uploaded_img" [src]="profileData?.profile_image"  />
        </div>
        <img *ngIf="profileData?.verifiedDriver == '2'" src="../../../../../assets/icons/rightIcon.svg" class="statusIcon" />
        <img *ngIf="profileData?.verifiedDriver == '1'" src="../../../../../assets/icons/rightYellowIcon.svg" (click)="verify()"
          class="statusIcon1" />
          <img *ngIf="profileData?.verifiedDriver == '3'" src="../../../../../assets/icons/rightYellowIcon.svg" (click)="verify()"
          class="statusIcon1" />
        <img *ngIf="profileData?.verifiedDriver == '0'" src="../../../../assets/icons/crossIcon.svg" (click)="verify()"
          class="statusIcon1" />
      </span>
    </div>
  </div>
</div>
<div class="row d-flex align-items-center header" *ngIf="role == 'Host'">
  <div class="col-2">
    <div *ngIf="!showBackButton">
      <img src="../../../../../assets/images/Host/Icon/HostLogo.svg" />
    </div>
    <div *ngIf="showBackButton" class="Go_back">
      <img src="../../../../assets/images/Guest/Icon/left-arrow.svg" (click)="goBack()" />
    </div>
  </div>
  <div class="col-8">
    <div
      [ngClass]="{headerRedText: urls?.url?.includes('delete_user'), headerText: !urls?.url?.includes('delete_user')}">
      <p>{{ headerText }}</p>
    </div>
  </div>
  <div class="col-2">
    <div class="d-flex position-relative buttonHostText" *ngIf="history">
      <button (click)="historyList()">History</button>
    </div>
    <div class="d-flex position-relative" *ngIf="showUser">
      <img src="../../../../assets/images/Guest/Icon/notificationIcon.svg" class="notification"
        (click)="redirectedToNotification()" />
      <div class="notification-badge" *ngIf="notificationCount > 0">
        {{ notificationCount }}
      </div>
      <div class="OuterBox" *ngIf="!profileData?.profile_image">
        <h4 class="img" >{{ firstWordName | uppercase }}</h4>
     
      </div>
      <div class="OuterBox" *ngIf="profileData?.profile_image">
        <img class="uploaded_img"  [src]="profileData?.profile_image" />
      </div>
    </div>
  </div>
</div>
