import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Observable, catchError, defer, from, map, mergeMap, of, retry, switchMap, throwError, timer } from 'rxjs';
import { getAuth, OAuthProvider, signInWithCredential } from 'firebase/auth';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { EmailAuthProvider } from 'firebase/auth';

const MAX_POLL_ATTEMPTS = 6;
const POLL_USER_DELAY_MS = 400;
const CUSTOM_CLAIM_KEYS = {
  ADMIN: 'admin',
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  auth() {
    throw new Error('Method not implemented.');
  }
  static NullUserError = new Error('Null User');
  userId:string;
  constructor(private AngularFireAuth: AngularFireAuth, private firestore: AngularFirestore) { 
    this.AngularFireAuth.user.subscribe({
      next: async (res: any) => {
        this.userId = res?.uid
      }});
  }

  currentUser() {
    return from(this.AngularFireAuth.currentUser);
  }

  pollCurrentUser() {
    return defer(() => this.currentUser()).pipe(
      map((user) => {
        if (!user) {
          throw AuthService.NullUserError;
        }
        return user;
      }),
      retry({
        delay: (errors, retryCount) => {
          if (retryCount >= MAX_POLL_ATTEMPTS) {
            throw AuthService.NullUserError;
          }
          return timer(POLL_USER_DELAY_MS);
        },
      }),
      catchError((error: Error) => {
        if (error?.message !== AuthService.NullUserError.message) {
          throw error;
        }
        return of(null);
      })
    );
  }

  createUserWithEmailAndPassword(email: string, password: string) {
    return from(
      this.AngularFireAuth.createUserWithEmailAndPassword(email, password)
        .then((result) => {
          //  this.SendVerificationMail(); // Sending email verification notification, when new user registers
        })
        .catch((error) => {
          window.alert(error.message);
        })
    );
  }
  SendVerificationMail() {
    return this.AngularFireAuth.currentUser
      .then((user) => {
        return user?.sendEmailVerification();
      })
      .then(() => {
        // this.router.navigate(['verify-email-address']);
      });
  }
  // pushNotification(){
  //   return this.AngularFireAuth.currentUser
  //   .then((user) =>{

  //   })
  // }
  signInWithEmailAndPassword(email: string, password: string) {
    return from(
      this.AngularFireAuth.signInWithEmailAndPassword(email, password)
    );
  }

  async signup(payload: any) {
    const userCredential: any = await this.AngularFireAuth.createUserWithEmailAndPassword(payload.email, payload.password);
    await this.firestore.collection('users').doc(userCredential.user.uid).set(payload);
    return userCredential.user.sendEmailVerification();
  }

  isAdmin() {
    return this.pollCurrentUser().pipe(
      mergeMap((user) => {
        if (!user) {
          return of(false);
        }

        return from(user.getIdTokenResult()).pipe(
          map((idTokenResult) => {
            return !!idTokenResult.claims[CUSTOM_CLAIM_KEYS.ADMIN];
          })
        );
      })
    );
  }

  sendPasswordResetEmail(
    ...args: Parameters<typeof this.AngularFireAuth.sendPasswordResetEmail>
  ) {
    return this.AngularFireAuth.sendPasswordResetEmail(...args);
  }

  confirmPasswordReset(code: string, password: string) {
    return this.AngularFireAuth.confirmPasswordReset(code, password);
  }

  logout() {
    return from(this.AngularFireAuth.signOut());
  }

  // Sign in with Google
  googleAuth(googleUser: any) {
    return this.AngularFireAuth.signInWithCredential(GoogleAuthProvider.credential(googleUser.authentication.idToken))
    // return this.AngularFireAuth.signInWithPopup(new GoogleAuthProvider());
  }

  // Sign in with Apple
  appleAuth() {
    const provider = new OAuthProvider('apple.com');
    return signInWithPopup(getAuth(), provider);
  }

  // Sign in with Apple Credentials Method
  appleSignInWithCredential(identityToken: string) {
    const provider = new OAuthProvider('apple.com');
    const credential = provider.credential({
      idToken: identityToken
    });
    return signInWithCredential(getAuth(), credential);
  }


  // Login using email and password
  login(email: string, password: string, expectedRole: string) {
    return this.AngularFireAuth.fetchSignInMethodsForEmail(email)
      .then((methods) => {
        if (methods.length === 0) {
          throw new Error('Email does not exist.');
        }

        // Attempt to sign in with the provided email and password
        return this.AngularFireAuth.signInWithEmailAndPassword(email, password);
      })
      .then((userCredential) => {
        const user: any = userCredential.user;

        return this.firestore.collection('users').doc(user.uid).get()
          .toPromise()
          .then((userDoc: any) => {
            if (userDoc.exists) {
              const userData = userDoc.data();
              const userRole = userData?.role;

              if (userRole === expectedRole) {
                // User's role matches the expected role; return user data
                return userData;
              } else {
                // User's role doesn't match the expected role; throw a custom error
                throw new Error('Role does not match.');
              }
            } else {
              // User document doesn't exist; throw a custom error
              throw new Error('User not found.');
            }
          })
          .catch((error) => {
            // Handle Firestore errors and custom errors
            throw error;
          });
      })
      .catch((error) => {
        // Handle Firebase Authentication errors
        if (error.code === 'auth/user-not-found') {
          throw new Error('Email does not exist.');
        } else if (error.code === 'auth/wrong-password') {
          throw new Error('Password is incorrect.');
        } else {
          throw error;
        }
      });
  }



  async signupAndSendVerificationEmail(payload: any): Promise<void> {
    try {
      const emailExists = await this.checkIfEmailExists(payload.email);
      if (emailExists) {
        return;
      }
      const userCredential = await this.AngularFireAuth.createUserWithEmailAndPassword(payload.email, payload.password);
      await this.addUserToFirestore(userCredential, payload);
      await this.sendEmailVerification(userCredential);
      } catch (error) {
    }
  }

  async checkIfEmailExists(email: string): Promise<boolean> {
    const userRecord = await this.AngularFireAuth.fetchSignInMethodsForEmail(email);
    return userRecord.length > 0;
  }

  async addUserToFirestore(userCredential: firebase.default.auth.UserCredential, userData: any): Promise<void> {
    if (userCredential && userCredential.user) {
      const userId = userCredential.user.uid;
      userData.id = userCredential.user.uid;
       await this.firestore.collection('users').doc(userId).set(userData);
    } else {
    }
  }

  async sendEmailVerification(userCredential: firebase.default.auth.UserCredential): Promise<void> {
    if (userCredential && userCredential.user) {
      await userCredential.user.sendEmailVerification();
    } else {
      }
  }

  deleteUser(): Promise<void> {
    const documentRef = this.firestore.collection('users').doc(this.userId);
    return documentRef.delete();
  }


  changePassword(payload:any): Observable<string> {
    return from(this.AngularFireAuth.currentUser)
      .pipe(
        catchError(error => {
          return throwError('Error getting current user');
        }),
        switchMap((user: any) => {
          if (!user) {
            return throwError('No user logged in');
          }

          // Re-authenticate user with old password
          const credential = EmailAuthProvider.credential(user.email, payload.oldPassword);
          return from(user.reauthenticateWithCredential(credential))
            .pipe(
              catchError(error => {
                return throwError('Old password does not match'); // Error message for incorrect old password
              }),
              switchMap(() => {
                // If re-authentication successful, update password
                return from(user.updatePassword(payload.password))
                  .pipe(
                    catchError(error => {
                      return throwError('Error updating password');
                    }),
                    switchMap(() => {
                      return of('Password updated successfully'); // Success message for password change
                    })
                  );
              })
            );
        })
      );
  }
}
