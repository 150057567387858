import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Router } from '@angular/router';
import {
  Capacitor
} from '@capacitor/core';
import { UserService } from './user.service';
import { LocalNotifications } from '@capacitor/local-notifications';

const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

@Injectable()
export class MessagingService {
  userId: any;
  public chatId: any;
  constructor(private router: Router,
    private _af: AngularFireAuth,
    private userService: UserService) {
    this._af.user.subscribe({
      next: async (res: any) => {
        this.userId = res?.uid;
      }
    });
  }

  initPushMessaging() {
    if (isPushNotificationsAvailable) {
      this.registerPush();
    }
  }

  registerPush() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    LocalNotifications.requestPermissions().then((permission) => {
     });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        localStorage.setItem('deviceToken', token.value);
        if (this.userId) {
          const payload = {
            deviceToken: token.value
          };
          this.userService.updateUser(this.userId, payload).subscribe((res: any) => { });
        }
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        //  alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        if (Capacitor.getPlatform() === 'android') {
          await PushNotifications.getDeliveredNotifications().then((x) => {
            PushNotifications.removeDeliveredNotifications(x);
          });
          this.foregrndLocalNotif(notification);
        }
        if (notification?.data?.chatId === this.chatId) {
          await PushNotifications.getDeliveredNotifications().then((x) => {
            PushNotifications.removeDeliveredNotifications(x);
          });
        }
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // Extract data from the notification
        const data = notification.notification.data;
        // Navigate to the desired page based on notification data
        if (data && data.url) {
          this.router.navigate([data.url]);
        }
      }
    );

    LocalNotifications.addListener('localNotificationActionPerformed', (notification: any) => {
      // Add your custom handling here
      if (notification.notification.extra.url) {
        this.router.navigate([notification.notification.extra.url]);
      }
    });
  }

  private foregrndLocalNotif(notification: any) {
    LocalNotifications.schedule({
      notifications: [
        {
          id: 1,
          title: notification.title,
          body: notification.body,
          schedule: { at: new Date(Date.now() + 100) },
          extra: notification.data,
        },
      ],
    });
  }
}
