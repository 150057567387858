import { Pipe, PipeTransform } from '@angular/core';
import { of, startWith } from 'rxjs';
import { FirestoreService } from '../../services';

const BASE64 = 'base64';

@Pipe({
  name: 'loadImageUrl',
})
export class LoadImageUrlPipe implements PipeTransform {
  constructor(private FirestoreService: FirestoreService) {}

  transform(url: string, placeholderUrl: string = '') {
    if (!url) {
      return of(placeholderUrl);
    }

    return url.includes(BASE64)
      ? of(url)
      : this.FirestoreService.pathToUrl(url).pipe(startWith(placeholderUrl));
  }
}
