import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { GlobalFooterComponent } from './global-footer.component';
import { Router, RouterModule } from '@angular/router';

@NgModule({
  declarations: [GlobalFooterComponent],
  imports: [
    CommonModule,
     IonicModule,
     RouterModule],
  exports: [GlobalFooterComponent],
})
export class GlobalFooterModule {}
