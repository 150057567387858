import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import * as actions from './vehicle.actions';
import { from } from 'rxjs';
import { VehicleService } from '../../services/vehicle.service';
import { PaginationQuery } from '@tarlen/shared';
import { Store } from '@ngrx/store';
import { vehicleSelectors } from '.';

@Injectable()
export class VehicleEffects {
  constructor(
    private actions$: Actions,
    private VehicleService: VehicleService,
    private Store: Store
  ) {}

  // #region ReqReadVehicleListings
  ReqReadVehicleListings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ReqReadVehicleListings),
      mergeMap(({ type, ...payload }) => {
        return from(
          this.VehicleService.readPaginatedVehicles(
            payload.userLocation,
            new PaginationQuery(),
            true
          )
        ).pipe(
          mergeMap((paginatedVehicles) => {
            return [actions.ReadVehicleListingsSuccess(paginatedVehicles)];
          }),
          catchError((error) => {
            return [
              actions.ReadVehicleListingsFail({
                error,
                toast: {
                  message: 'There was an error while retrieving listings',
                },
              }),
            ];
          })
        );
      })
    )
  );
  // #endregion

  // #region ReqLoadMoreVehicleListings
  ReqLoadMoreVehicleListings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ReqLoadMoreVehicleListings),
      withLatestFrom(
        this.Store.select(vehicleSelectors.selectLastPaginationOptions)
      ),
      mergeMap(([{ type, ...payload }, paginationOptions]) => {
        const updatedPaginatedOptions = {
          ...paginationOptions,
          pageNumber: paginationOptions.pageNumber + 1,
        };
        return from(
          this.VehicleService.readPaginatedVehicles(
            payload.userLocation,
            updatedPaginatedOptions
          )
        ).pipe(
          mergeMap((paginatedVehicles) => {
            return [actions.LoadMoreVehicleListingsSuccess(paginatedVehicles)];
          }),
          catchError((error) => {
            return [
              actions.LoadMoreVehicleListingsFail({
                error,
                toast: {
                  message: 'There was an error while retrieving listings',
                },
              }),
            ];
          })
        );
      })
    )
  );
  // #endregion

  // #region ReqReadVehicle
  ReqReadVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ReqReadVehicle),
      mergeMap(({ vehicleId }) => {
        return this.VehicleService.read(vehicleId).pipe(
          mergeMap((vehicle) => {
            return [
              actions.ReadVehicleSuccess({
                selectedVehicle: vehicle,
              }),
            ];
          }),
          catchError((error) => {
            return [
              actions.ReadVehicleFail({
                error,
                toast: {
                  message: 'There was an error loading that vehicle',
                },
              }),
            ];
          })
        );
      })
    )
  );
  // #endregion

  // #region ReqReadVehicleBookedDates
  // ReqReadVehicleBookedDates$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(actions.ReqReadVehicleBookedDates),
  //     mergeMap(({ type, ...payload }) => {
  //       return this.VehicleService.getVehicleBookedDates(
  //         payload.vehicleId
  //       ).pipe(
  //         mergeMap((bookedDates) => {
  //           return [actions.ReadVehicleBookedDatesSuccess({ bookedDates })];
  //         }),
  //         catchError((error) => {
  //           return [
  //             actions.ReadVehicleBookedDatesFail({
  //               error,
  //               toast: {
  //                 message:
  //                   'There was an error while retrieving vehicle availability',
  //               },
  //             }),
  //           ];
  //         })
  //       );
  //     })
  //   )
  // );
  // #endregion
}
