import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from 'libs/angular/src/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class HostGuard implements CanActivate {
  private userRole: string | null;

  constructor(private router: Router,
      private auth: AngularFireAuth,
    private userService: UserService) {
      this.auth.user.subscribe({
        next: (res: any) => {
          this.userService.read(res?.uid).subscribe((res:any)=>{
            // this.userRole = res.role;
          })
        }
      })
    }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.userRole = localStorage.getItem('loggedAsRole');
    if (this.userRole == 'Host') {
      return true;
    } else {
      // Redirect to unauthorized page or login page
      this.router.navigate(['/auth/landing']);
      return false;
    }
  }
}
