<ng-container *ngIf="!renderAsBackgroundImage; else ImageAsBackground">
  <img
    [src]="src"
    [alt]="alt"
    [style.height]="height"
    [style.width]="width"
    [style.maxWidth]="maxWidth"
    [style.maxHeight]="maxHeight"
    [style.border]="border"
    [style.borderRadius]="borderRadius"
    [style.verticalAlign]="verticalAlign"
    [style.objectFit]="objectFit"
    [style.objectPosition]="objectPosition"
    [ngClass]="class"
  />
</ng-container>
<ng-template #ImageAsBackground>
  <div
    role="img"
    [attr.aria-label]="alt"
    [style.height]="height"
    [style.width]="width"
    [style.maxWidth]="maxWidth"
    [style.maxHeight]="maxHeight"
    [style.border]="border"
    [style.borderRadius]="borderRadius"
    [style.backgroundRepeat]="backgroundRepeat"
    [style.backgroundPosition]="backgroundPosition"
    [style.backgroundSize]="backgroundSize"
    [ngClass]="class"
  ></div>
</ng-template>
