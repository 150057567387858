import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VehicleService } from '../../core/services/vehicle.service';

@Component({
  selector: 'tarlen-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss'],
})
export class ModalPopupComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<any>();

  constructor(
    private Router: Router,
    private vehicleService: VehicleService
  ) { }

  ngOnInit() {}

  addMore() {
    this.vehicleService.updateFormData(null);
    this.Router.navigate([
      `host-add-car/details`,
    ]);
  }

  home() {
    this.Router.navigate([
      `home-host`,
    ]);
  }
}
