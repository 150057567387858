import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { ActionCreator, Store } from '@ngrx/store';
import { TypedAction, Action } from '@ngrx/store/src/models';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NGRXService {
  constructor(private Store: Store, private Actions: Actions) {}

  dispatch<T extends Action>(actionToDispatch: T) {
    this.Store.dispatch(actionToDispatch as TypedAction<T['type']>);
  }

  dispatchAndListen<T extends Action, K extends ActionCreator[]>(
    actionToDispatch: T,
    actionsToListenTo: K
  ) {
    if (!actionsToListenTo?.length) {
      throw new Error('Empty action array');
    }

    const returnedAction = this.Actions.pipe(
      ofType(...actionsToListenTo),
      take(1)
    );

    setTimeout(() => this.dispatch(actionToDispatch));

    return returnedAction;
  }
}
