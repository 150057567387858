import { ActionReducerMap } from '@ngrx/store';
import { userState, userFeatureKey, userReducer, userEffects } from './user';
import {
  bookingState,
  bookingFeatureKey,
  bookingReducer,
  bookingEffects,
} from './booking';
import {
  vehicleState,
  vehicleFeatureKey,
  vehicleReducer,
  vehicleEffects,
} from './vehicle';

export * from './user';
export * from './booking';
export * from './vehicle';

export interface State {
  [userFeatureKey]: userState;
  [bookingFeatureKey]: bookingState;
  [vehicleFeatureKey]: vehicleState;
}

export const rootReducers: ActionReducerMap<State> = {
  [userFeatureKey]: userReducer,
  [bookingFeatureKey]: bookingReducer,
  [vehicleFeatureKey]: vehicleReducer,
};

export const rootEffects: any[] = [userEffects, bookingEffects, vehicleEffects];
