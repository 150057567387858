export const AUTH = 'auth';


export enum Auth {
  LANDING = 'landing',
  SIGN_UP = 'sign-up',
  LOG_IN = 'log-in',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  SUCCESS = 'success',
  EXPLAINER = "explainer",
  MOBILE_VERIFICATION = 'mobile',
  PAYMENT_LOADING = 'payment-loading-screen'
}
