import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from './user.actions';
import { selectUserId } from './user.selectors';
import { from, of } from 'rxjs';
import { catchError, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { ModalService } from '../../services/modal.service';
import { ROUTES } from '../../consts/routing';
import { ICONS } from '../../consts/assets';
import {
  AuthService,
  UserService,
} from '@tarlen/angular';
import { geohashForLocation } from 'geofire-common';

@Injectable()
export class UserEffects {
  role: any;
  constructor(
    private actions$: Actions,
    private AuthService: AuthService,
    private UserService: UserService,
    private ModalService: ModalService,
    private Router: Router,
    private Store: Store
  ) {}

  // #region ReqCreateUser
  ReqCreateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ReqCreateUser),
      mergeMap(({ type, ...userRegistration }) => {
        return this.UserService.create(userRegistration).pipe(
          mergeMap((user) => {
            return [
              actions.CreateUserSuccess({ user }),
              actions.ReqSignInWithEmailAndPassword({
                email: userRegistration.email,
                password: userRegistration.password,
                newlyCreatedUser: user,
              }),
            ];
          }),
          catchError((error) => {
            return [
              actions.CreateUserFail({
                error,
                toast: {
                  message:error.message,
                },
              }),
            ];
          })
        );
      })
    )
  );
  // #endregion

  // #region ReqSignInWithEmailAndPassword
  ReqSignInWithEmailAndPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ReqSignInWithEmailAndPassword),
      mergeMap(({ type, ...payload }) => {
        this.role = payload.role
        return this.AuthService.signInWithEmailAndPassword(
          payload.email,
          payload.password
        ).pipe(
          mergeMap((firebaseUser) => {
            if (payload.newlyCreatedUser) {
              return of(payload.newlyCreatedUser);
            }

            if (!firebaseUser.user?.uid) {
              throw new Error('User uid missing');
            }

            return this.UserService.read(firebaseUser.user.uid);
          }),
          mergeMap((user) => {
            if (payload.newlyCreatedUser) {
              if(this.role == 'Guest'){
                this.ModalService.showSuccessModal({
                  imgPath: ICONS.SUCCESS_TICK,
                  heading: 'Welcome to ',
                  subHeading: 'tarlen',
                  title: 'You have successfully Signed Up.',
                  body:'You can now browse our car options and verify yourself to start renting.',
                  buttonText: 'Verify Yourself',
                  buttonText1:'Browse cars'
                });
                this.Router.navigateByUrl(ROUTES.Home._Path, { replaceUrl: true });
              }
              else{
                this.ModalService.showSuccessModal({
                  imgPath: ICONS.SUCCESS_TICK,
                  heading: 'Welcome to ',
                  subHeading: 'tarlen',
                  title: 'You have successfully Signed Up.',
                  body:'You can now browse our car options and verify yourself to start renting.',
                  buttonText: 'Thank you',
                });
                this.Router.navigateByUrl(ROUTES.HomeHost._Path, { replaceUrl: true });
              }
            }
          
            return [actions.SignInWithEmailAndPasswordSuccess({ user })];
          }),
          catchError((error) => {
            let route =`${ROUTES.Auth.LogIn._Path}?data=${this.role}`
            return [
              actions.SignInWithEmailAndPasswordFail({
                error,
                toast: {
                  message: 'Could not sign you in, please try again.',
                },
              }),

              actions.ReqLogout(route),
            ];
          })
        );
      })
    )
  );
  // #endregion

  // #region ReqReauthorizeUser
  ReqReauthorizeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ReqReauthorizeUser),
      mergeMap(({ type, ...payload }) => {
        return this.AuthService.pollCurrentUser().pipe(
          take(1),
          mergeMap((firebaseUser) => {
            if (!firebaseUser?.uid) {
              return [
                actions.ReauthorizeUserFail({
                  error: new Error('User not logged in'),
                }),
              ];
            }

            return this.UserService.read(firebaseUser?.uid).pipe(
              mergeMap((user) => {
                return [actions.ReauthorizeUserSuccess({ user })];
              })
            );
          }),
          catchError((error) => {
            return [
              actions.ReauthorizeUserFail({
                error,
                toast: {
                  message: 'Could not log you in, please try again.',
                },
              }),
              actions.ReqLogout(),
            ];
          })
        );
      })
    )
  );

  // #endregion

  // #region ReqLogout
  ReqLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ReqLogout),
      mergeMap(({ type, ...payload }) => {
        return this.AuthService.logout().pipe(
          mergeMap(() => {
            this.Router.navigateByUrl(payload.route, {
              replaceUrl: true,
            });
            return [actions.LogoutSuccess()];
          }),
          catchError((error) => {
            return [
              actions.LogoutFail({
                error,
                toast: {
                  message: 'Could not logout, please try again.',
                },
              }),
            ];
          })
        );
      })
    )
  );
  // #endregion

  // #region ReqUpdatePersonalDetails
  ReqUpdatePersonalDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ReqUpdatePersonalDetails),
      mergeMap(({ type, ...payload }) => {
        const user = {
          ...payload.user,
          geohash: geohashForLocation([payload.user.lat, payload.user.long]),
        };
        return from(this.UserService.update(user)).pipe(
          mergeMap((user) => {
            return [actions.UpdatePersonalDetailsSuccess({ user })];
          }),
          catchError((error) => {
            return [
              actions.UpdatePersonalDetailsFail({
                error,
                toast: {
                  message: 'Could not update user, please try again.',
                },
              }),
            ];
          })
        );
      })
    )
  );
  // #endregion

  // #region ReqDeleteUser
  ReqDeleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ReqDeleteUser),
      mergeMap(({ type, ...payload }) => {
        return this.UserService.delete().pipe(
          mergeMap(() => {
            return [actions.DeleteUserSuccess(), actions.ReqLogout()];
          }),
          catchError((error) => {
            return [
              actions.DeleteUserFail({
                error,
                toast: {
                  message: 'Could not delete user, please try again.',
                },
              }),
            ];
          })
        );
      })
    )
  );
  // #endregion

  // #region ReqVerifyDriver
  // ReqVerifyDriver$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(actions.ReqVerifyDriver),
  //     mergeMap(({ type, ...payload }) => {
  //       return this.DriverVerificationService.create(payload).pipe(
  //         mergeMap(({ user, driverVerification }) => {
  //           return [actions.VerifyDriverSuccess({ user, driverVerification })];
  //         }),
  //         catchError((error) => {
  //           return [
  //             actions.VerifyDriverFail({
  //               error,
  //               toast: {
  //                 message:
  //                   'Could not submit driver verification details, please try again.',
  //               },
  //             }),
  //           ];
  //         })
  //       );
  //     })
  //   )
  // );
  // #endregion

  // #region ReqReadDriverVerificationDetails
  // ReqReadDriverVerificationDetails$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(actions.ReqReadDriverVerificationDetails),
  //     withLatestFrom(this.Store.select(selectUserId)),
  //     mergeMap(([{ type, ...payload }, userId]) => {
  //       return this.DriverVerificationService.read(userId).pipe(
  //         mergeMap((driverVerification) => {
  //           return [
  //             actions.ReadDriverVerificationDetailsSuccess({
  //               driverVerification,
  //             }),
  //           ];
  //         }),
  //         catchError((error) => {
  //           return [
  //             actions.ReadDriverVerificationDetailsFail({
  //               error,
  //               toast: {
  //                 message:
  //                   'Could not get verification details, please try again.',
  //               },
  //             }),
  //           ];
  //         })
  //       );
  //     })
  //   )
  // );
  // // #endregion
  
  // #region SignInWithGoogle Success
    SignInWithGoogleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SignInWithGoogleSuccess),
      mergeMap(({ type, ...payload }: any) => {
        return this.UserService.read(payload.uid).pipe(
          mergeMap((user) => {
            this.Router.navigateByUrl(ROUTES.Home._Path);
            return [actions.SignInWithGoogleSuccess({ user })];
          })
        )
      })
    )
  );
}
