import * as vehicleActions from './vehicle.actions';
import * as vehicleSelectors from './vehicle.selectors';
import { VehicleEffects as vehicleEffects } from './vehicle.effects';

import {
  vehicleFeatureKey,
  State as vehicleState,
  reducer as vehicleReducer,
} from './vehicle.reducer';

export {
  vehicleActions,
  vehicleEffects,
  vehicleSelectors,
  vehicleFeatureKey,
  vehicleState,
  vehicleReducer,
};
