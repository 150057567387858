import {
  CloudFunction,
  DriverVerification,
} from '../models';



export const UpdateDriverVerificationCloudFunction = new CloudFunction<
  DriverVerification,
  DriverVerification
>('driverVerificationFunctions_UpdateDriverVerification');

export type DriverVerificationCloudFunctions =
  | typeof UpdateDriverVerificationCloudFunction;
