export class PaginationQuery {
  pageNumber: number = 1;
  pageSize: number = 10;
}

export class PaginationOptions extends PaginationQuery {
  hasNextPage: boolean = false;
}

export class PaginatedData<T = any> implements PaginationOptions {
  items: T[] = [];
  pageNumber = 1;
  pageSize = 10;
  hasNextPage = false;

  constructor(args?: Partial<PaginatedData<T>>) {
    Object.assign(this, args || {});
    this.hasNextPage = this.items.length >= this.pageSize;
  }
}
