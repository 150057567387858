import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromBooking from './booking.reducer';

export const selectBookingState = createFeatureSelector<fromBooking.State>(
  fromBooking.bookingFeatureKey
);

export const selectCurrentBooking = createSelector(
  selectBookingState,
  ({ currentBooking }) => currentBooking
);
export const selectCurrentBookingDetails = createSelector(
  selectBookingState,
  ({ currentBooking, bookedVehicle }) => ({ currentBooking, bookedVehicle })
);

export const selectStoredBooking = createSelector(
  selectBookingState,
  ({ storedBooking }) => storedBooking
);
