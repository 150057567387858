/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  Card,
  CheckoutData,
  FirestoreCollections,
  PaymentObj,
  User,
} from '@tarlen/shared';
import { catchError, from, map, Observable, of, switchMap, tap } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CloudFunctionService, FirestoreService } from '@tarlen/angular';
import { environment } from 'apps/mobile/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PeachPaymentService {
  _userId: string;
  private Collection = this.FirestoreService.Scope<User>(
    FirestoreCollections.USERS
  );

  constructor(
    private http: HttpClient,
    private auth: AngularFireAuth,
    private FirestoreService: FirestoreService,
    private firestore: AngularFirestore
  ) {
    this.auth.user.subscribe({
      next: (res: any) => {
        this._userId = res?.uid;
      },
    });
  }

  read(id: string) {
    return this.Collection.Read(id);
  }

  update(id: string, data: Card, user: any) {
    if (!user?.cards?.length) user.cards = [data];
    const isExistingCard = user.cards.find(
      (card: Card) => card.card_number === data.card_number
    );
    if (!isExistingCard) user.cards.push(data);
    return this.Collection.Update(id, user);
  }

  add(card: Card) {
    return this.read(this._userId).pipe(
      switchMap((m: User) => this.update(this._userId, card, m))
    );
  }

  getCards(userId: string) {
    return this.Collection.Read(userId).pipe(
      map((item: User) => item?.cards || [])
    );
  }

  private setHeaders() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return headers;
  }

  /*------------------- Peach Payment Tokenization & Registration Flow -------------------*/
  // 1. Storing the payment data
  // 2. Using the stored payment data

  /*------------------- Storing the payment data starts here -------------------*/
  createRegistration(data: PaymentObj): Observable<any> {
    const payload: PaymentObj = {
      amount: data.amount,
      currency: data.currency,
      paymentBrand: data.paymentBrand, // VISA
      card_number: data.card_number,
      card_holder: data.card_holder,
      card_expiry_month: data.card_expiry_month,
      card_expiry_year: data.card_expiry_year,
      card_cvv: data.card_cvv,
      paymentType: data.paymentType,
      merchantTransactionID: data.merchantTransactionID,
    };
    const copiedObject: any = { ...payload };
    delete copiedObject.amount;
    delete copiedObject.currency;
    // this.add(copiedObject).subscribe();
    return this.http.post(`${environment.url}createRegistration`, payload, {
      headers: this.setHeaders(),
    });
  }

  /*------------------- Using the stored payment data starts here -------------------*/

  sendThePayment(data: PaymentObj, registrationId: string): Observable<any> {
    const payload: CheckoutData | any = {
      amount: data.amount,
      currency: data.currency,
      registrationId: registrationId,
    };
    return this.http.post(`${environment.url}sendThePayment`, payload, {
      headers: this.setHeaders(),
    });
  }

  refundPayment(data: any): Observable<any> {
    const payload: CheckoutData | any = {
      amount: data.amount,
      paymentId: data.paymentId,
    };
    return this.http.post(`${environment.url}refundPayment`, payload, {
      headers: this.setHeaders(),
    });
  }

  autoDebitPayment(data: any): Observable<any> {
    return new Observable((observer) => {
      const payload: CheckoutData | any = {
        amount: data.amount,
        registrationId: data.registrationId,
      };
      this.http
        .post(`${environment.url}autoDebitPayment`, payload, {
          headers: this.setHeaders(),
        })
        .subscribe(
          (response) => {
            observer.next(response);
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  capturePayment(data: any): Observable<any> {
    return new Observable((observer) => {
      const payload: CheckoutData | any = {
        amount: data.amount,
        paymentId: data.paymentId,
      };

      this.http
        .post(`${environment.url}capturePayment`, payload, {
          headers: this.setHeaders(),
        })
        .subscribe(
          (response) => {
            observer.next(response);
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  addMyEarnings(data: any, bookingid: string): Observable<string> {
    return from(
      this.firestore.collection('payments').doc(bookingid).ref.get()
    ).pipe(
      switchMap((docSnapshot) => {
        if (docSnapshot.exists) {
          // Document with the provided bookingid exists, so update it
          const id = docSnapshot.id; // Get the existing Firestore document ID
          const updatedData = { ...data, id: id, bookingid: bookingid }; // Add an "id" field and 'bookingid' to the data
          return from(docSnapshot.ref.update(updatedData)).pipe(
            switchMap(() => of('Update successful'))
          );
        } else {
          // Document with the provided bookingid does not exist, so add it
          return from(
            this.firestore
              .collection('payments')
              .doc(bookingid)
              .set({ ...data, bookingid: bookingid })
          ).pipe(switchMap(() => of('Create successful')));
        }
      })
    );
  }

  // Function to get payment data for a specific vehicleOwnerId
  getMyEarningsByUserId(vehicleOwnerId: string): Observable<any> {
    // Reference the 'payments' collection in Firestore
    const collectionRef = this.firestore.collection('payments', (ref) =>
      ref.where('vehicleOwnerId', '==', vehicleOwnerId)
    );

    // Return the result as an observable
    return collectionRef.snapshotChanges().pipe(
      map((snapshot) => {
        const payments = snapshot.map((doc) => {
          const data: any = doc.payload.doc.data();
          return { id: doc.payload.doc.id, ...data };
        });
        // Transform the data if needed
        // For example, you can add a 'success' property to indicate success
        return { success: true, data: payments };
      }),
      catchError((error) => {
        return of({
          success: false,
          error: 'An error occurred while fetching payments',
        });
      })
    );
  }

  reversePayment(data: any): Observable<any> {
    const payload: any = {
      paymentId: data.paymentId,
    };
    return this.http.post(`${environment.url}reversePayment`, payload, {
      headers: this.setHeaders(),
    });
  }

  async addPayment(paymentInfo: any): Promise<string> {
    try {
      const bookingId = paymentInfo.booking_id;
      const paymentsCollection = this.firestore
        .collection('peachPayments')
        .doc(bookingId);
      const doc = await paymentsCollection.ref.get();

      if (doc.exists) {
        const existingData: any = doc.data() || {};
        const transactions = existingData.transactions || [];
        const exists = transactions.some(
          (transaction: any) => transaction.id === paymentInfo.id
        );
        if (!exists) {
          transactions.push(paymentInfo);
          await paymentsCollection.update({ transactions });
        }
      } else {
        await paymentsCollection.set({ transactions: [paymentInfo] });
      }
      return 'Payment added successfully';
    } catch (error) {
      throw `Error adding payment: ${error}`;
    }
  }

  getPaymentById(id: any): Observable<any> {
    // Construct the URL for the Cloud Function
    const url = `${environment.url}getPaymentDetailById`;

    // Set the parameters
    const params = new HttpParams().set('id', id);

    // Make the HTTP GET request
    return this.http.get(url, { params });
  }
  
}
