export class CloudFunction<DATA extends Object, RESPONSE> {
  name: string;
  DATA: DATA;
  RESPONSE: {
    data: RESPONSE;
    error?: Object;
  };
  constructor(name: string) {
    this.name = name;
  }
}
