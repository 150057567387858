import { Action } from '@ngrx/store';

export type ErrorAction = Action & ErrorActionProps;
export type ErrorActionProps = {
  error?: any;
  showToast?: boolean;
  toast?: {
    message: string;
  };
};

export function OptionalErrorActionProps<T extends Object = {}>(
  props: ErrorActionProps & Partial<T> = {}
) {
  return props;
}
