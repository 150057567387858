export interface InsuranceOption {
  option: string;
  price: string;
  detail: string;
}

export function GetVerificationSortValue(
  verificationStatus: VerificationStatus
) {
  switch (verificationStatus) {
    case VerificationStatus.PENDING:
      return 0;
    case VerificationStatus.VERIFIED:
      return 1;
    default:
      return 2;
  }
}

export enum VerificationStatus {
  NOT_VERIFIED,
  PENDING,
  VERIFIED,
}

export interface QueryableLocationDetails {
  lat: number;
  long: number;
  geohash: string;
}
export interface MinimumLocationDetails {
  geohash: string;
  suburb: string;
  town: string;
}
export interface LocationDetails
  extends QueryableLocationDetails,
    MinimumLocationDetails {
  googlePlaceId: string;
  road: string;
}
export type LocationDetailsDTO = LocationDetails;

export interface BaseEntity {
  id: string;
  createdAt: number;
  updatedAt: number;
}
