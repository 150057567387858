import { Injectable } from '@angular/core';
import {
  FirestoreCollections,
  PaginatedData,
  PaginationQuery,
  QueryableLocationDetails,
  Vehicle,
} from '@tarlen/shared';
import { BoundVehicles, GeohashQueryCache } from '../models/vehicle';
import { CloudFunctionService, FirestoreService } from '@tarlen/angular';
import {
  BehaviorSubject,
  catchError,
  from,
  map,
  mergeMap,
  Observable,
  of,
  throwError,
} from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  private cache = new GeohashQueryCache();
  private _userId: string;
  private Collection = this.FirestoreService.Scope<Vehicle>(
    FirestoreCollections.VEHICLES
  );
  private addOnsCollection: AngularFirestoreCollection<any>; // Change 'any' to your data type

  private formData = new BehaviorSubject<any>({});
  deliveryCharges: any;

  constructor(
    private FirestoreService: FirestoreService,
    private CloudFunctionService: CloudFunctionService,
    private firestore: AngularFirestore,
    private auth: AngularFireAuth,
    public location: Location,
    private http: HttpClient
  ) {
    this.auth.user.subscribe({
      next: (res: any) => {
        this._userId = res?.uid;
      },
    });
    this.addOnsCollection = firestore.collection<any>('vehicles_addOns'); // Change 'any' to your data type
  }

  read(id: string) {
    return this.Collection.Read(id);
  }

  list(): Observable<[]> {
    return this.Collection.List();
  }

  getVerifiedVehicle(verified: any) {
    const query = verified
      ? this.Collection.ref
        .where('verified', '==', verified)
        .where('isLive', '==', true)
        .orderBy('createdAt', 'desc')
      : this.Collection.ref;
    // ? this.Collection.ref.where('verified', '==', verified).where('isRequestedHost', '==', true).orderBy('createdAt', 'desc')

    return query
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.map((doc) => doc.data() as Vehicle)
      );
  }

  getVehiclesWithLocation(
    verified: any,
    filters: any,
    startDate: any,
    endDate: any
  ) {
    if (
      filters.date != '' &&
      filters.delivery != '' &&
      filters.booking != '' &&
      filters.price != ''
    ) {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('availability', 'array-contains', startDate && endDate)
          .where('deilvery', '==', filters.delivery)
          .where('bookingPreference', '==', filters.booking)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (
      filters.date != '' &&
      filters.delivery != '' &&
      filters.booking != ''
    ) {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('availability', 'array-contains', startDate && endDate)
          .where('deilvery', '==', filters.delivery)
          .where('bookingPreference', '==', filters.booking)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (
      filters.date != '' &&
      filters.delivery != '' &&
      filters.price != ''
    ) {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('availability', 'array-contains', startDate && endDate)
          .where('deilvery', '==', filters.delivery)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (
      filters.date != '' &&
      filters.booking != '' &&
      filters.price != ''
    ) {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('availability', 'array-contains', startDate && endDate)
          .where('bookingPreference', '==', filters.booking)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (
      filters.delivery != '' &&
      filters.booking != '' &&
      filters.price != ''
    ) {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('deilvery', '==', filters.delivery)
          .where('bookingPreference', '==', filters.booking)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.date != '' && filters.delivery != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('availability', 'array-contains', startDate && endDate)
          .where('deilvery', '==', filters.delivery)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.date != '' && filters.booking != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('availability', 'array-contains', startDate && endDate)
          .where('bookingPreference', '==', filters.booking)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.date != '' && filters.price != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('availability', 'array-contains', startDate && endDate)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.delivery != '' && filters.booking != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('deilvery', '==', filters.delivery)
          .where('bookingPreference', '==', filters.booking)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.delivery != '' && filters.price != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('deilvery', '==', filters.delivery)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.booking != '' && filters.price != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('bookingPreference', '==', filters.booking)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.date != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('availability', 'array-contains', startDate && endDate)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.delivery != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('deilvery', '==', filters.delivery)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.booking != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('bookingPreference', '==', filters.booking)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('googlePlaceId', '==', filters.placeId)
        : this.Collection.ref;
      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    }
  }

  getVehiclesWithAvailabilityFilters(
    verified: any,
    startDate: any,
    endDate: any
  ) {
    const query = verified
      ? this.Collection.ref
        .where('verified', '==', verified)
        .where('isLive', '==', true)
        .where('availability', 'array-contains', startDate && endDate)
      : this.Collection.ref;
    // ? this.Collection.ref.where('verified', '==', verified).where('isRequestedHost', '==', true)
    return query
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.map((doc) => doc.data() as Vehicle)
      );
  }

  getVehiclesWithDeliveryFilters(verified: any, filters: any) {
    const query = verified
      ? this.Collection.ref
        .where('verified', '==', verified)
        .where('isLive', '==', true)
        .where('deilvery', '==', filters)
      : this.Collection.ref;
    // ? this.Collection.ref.where('verified', '==', verified).where('isRequestedHost', '==', true)
    return query
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.map((doc) => doc.data() as Vehicle)
      );
  }

  getVehiclesWithBookingFilters(verified: any, filters: any) {
    const query = verified
      ? this.Collection.ref
        .where('verified', '==', verified)
        .where('isLive', '==', true)
        .where('bookingPreference', '==', filters)
      : this.Collection.ref;
    // ? this.Collection.ref.where('verified', '==', verified).where('isRequestedHost', '==', true)
    return query
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.map((doc) => doc.data() as Vehicle)
      );
  }

  getVehiclesWithAllFilters(
    verified: any,
    filters: any,
    startDate: any,
    endDate: any
  ) {
    const query = verified
      ? this.Collection.ref
        .where('verified', '==', verified)
        .where('isLive', '==', true)
        .where('availability', 'array-contains', startDate && endDate)
        .where('deilvery', '==', filters.delivery)
        .where('bookingPreference', '==', filters.booking)
      : this.Collection.ref;
    // ? this.Collection.ref.where('verified', '==', verified).where('isRequestedHost', '==', true)
    return query
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.map((doc) => doc.data() as Vehicle)
      );
  }

  getVehiclesWithMultipleFilters(
    verified: any,
    filters: any,
    startDate: any,
    endDate: any
  ) {
    if (filters.date != '' && filters.delivery != '' && filters.booking != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('availability', 'array-contains', startDate && endDate)
          .where('deilvery', '==', filters.delivery)
          .where('bookingPreference', '==', filters.booking)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (
      filters.date != '' &&
      filters.delivery != '' &&
      filters.price != ''
    ) {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('availability', 'array-contains', startDate && endDate)
          .where('deilvery', '==', filters.delivery)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (
      filters.date != '' &&
      filters.booking != '' &&
      filters.price != ''
    ) {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('availability', 'array-contains', startDate && endDate)
          .where('bookingPreference', '==', filters.booking)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (
      filters.delivery != '' &&
      filters.booking != '' &&
      filters.price != ''
    ) {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('deilvery', '==', filters.delivery)
          .where('bookingPreference', '==', filters.booking)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.date != '' && filters.delivery != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('availability', 'array-contains', startDate && endDate)
          .where('deilvery', '==', filters.delivery)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.date != '' && filters.booking != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('availability', 'array-contains', startDate && endDate)
          .where('bookingPreference', '==', filters.booking)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.date != '' && filters.price != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('availability', 'array-contains', startDate && endDate)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.delivery != '' && filters.booking != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('deilvery', '==', filters.delivery)
          .where('bookingPreference', '==', filters.booking)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.delivery != '' && filters.price != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('deilvery', '==', filters.delivery)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else if (filters.booking != '' && filters.price != '') {
      const query = verified
        ? this.Collection.ref
          .where('verified', '==', verified)
          .where('isLive', '==', true)
          .where('bookingPreference', '==', filters.booking)
        : this.Collection.ref;

      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    } else {
      const query = this.Collection.ref;
      return query
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs.map((doc) => doc.data() as Vehicle)
        );
    }

    // ? this.Collection.ref.where('verified', '==', verified).where('isRequestedHost', '==', true)
  }

  getCountriesData() {
    return this.http.get('./assets/countries.json');
  }

  getVahiclesWithMapCoordinates() {
    return this.list().pipe(
      map((vehicles: Vehicle[]) => {
        const filteredVehicles = vehicles.filter(vehicle => vehicle.verified == 1);
        return this.getLatLongData(filteredVehicles);
      })
    );
  }

  getLatLongData(vehciles: Vehicle[]) {
    return vehciles.map(
      ({
        lat,
        long,
        brand,
        vehicle_model,
        vehicle_make,
        road,
        id,
        vehicle_year,
      }: Pick<
        Vehicle,
        | 'lat'
        | 'long'
        | 'brand'
        | 'vehicle_model'
        | 'vehicle_make'
        | 'road'
        | 'id'
        | 'vehicle_year'
      >) => {
        return {
          lat,
          long,
          brand,
          vehicle_model,
          road,
          id,
          vehicle_make,
          vehicle_year,
        };
      }
    );
  }

  async readPaginatedVehicles(
    userLocation: QueryableLocationDetails,
    paginationQuery: PaginationQuery,
    initialQuery = false
  ) {
    if (initialQuery) {
      this.cache.reset();
    }

    const queryBounds = this.cache.getQueryBounds(
      userLocation.lat,
      userLocation.long
    );

    const boundVehicleQueries: Promise<BoundVehicles>[] = [];
    for (let i = 0; i < queryBounds.length; i++) {
      const bound = queryBounds[i];
      let query = this.Collection.ref
        .where('archived', '==', false)
        .orderBy('geohash');

      const lastVehicle = this.cache.getCachedVehicle(bound);
      if (lastVehicle) {
        query = query.startAfter(lastVehicle);
      } else {
        query = query.startAt(bound[0]);
      }
      query = query.endAt(bound[1]).limit(paginationQuery.pageSize);

      const vehicleQuery = query.get().then((snapshot) => {
        return {
          bound,
          vehicles: snapshot.docs.map((doc) => doc.data()),
        };
      });
      boundVehicleQueries.push(vehicleQuery);
    }
    const boundVehicles = await Promise.all(boundVehicleQueries);

    const filteredVehicles = this.cache.filterBoundVehicles(boundVehicles);

    return new PaginatedData({
      ...paginationQuery,
      items: filteredVehicles,
      hasNextPage: filteredVehicles.length >= paginationQuery.pageSize,
    });
  }


  getFavorites(userId?: string) {
    return this.firestore
      .collection(FirestoreCollections.FAVORITES)
      .doc(this._userId || userId)
      .valueChanges();
  }

  addToFavorites(productId: string) {
    this.firestore
      .collection(FirestoreCollections.FAVORITES)
      .doc(this._userId)
      .set(
        {
          [productId]: true,
        },
        { merge: true }
      );
  }

  removeFromFavorites(productId: string) {
    this.firestore
      .collection(FirestoreCollections.FAVORITES)
      .doc(this._userId)
      .update({
        [productId]: null,
      });
  }

  // Function to check if a car is a favorite for the current user
  isCarFavorite(carId: string): Observable<boolean> {
    return this.firestore
      .doc(`${FirestoreCollections.FAVORITES}/${this._userId}`)
      .valueChanges()
      .pipe(map((data: any) => !!data && !!data[carId]));
  }

  getVehiclesData(userId: string): Observable<any[]> {
    return this.firestore
      .collection('vehicles', (ref) => ref.where('userId', '==', userId))
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((action) => {
            const id = action.payload.doc.id;
            const data: any = action.payload.doc.data();
            data.id = id;
            return data;
          });
        })
      );
  }

  // Fetch all distinct 'make' values from Firestore
  getMakes(): Observable<string[]> {
    return this.firestore
      .collection('vehicles-db')
      .valueChanges()
      .pipe(
        map((vehicles: any[]) => {
          const makes = vehicles.map((vehicle) => vehicle.make);
          return [...new Set(makes)]; // Get distinct 'make' values
        })
      );
  }

  // Fetch all distinct 'model' values from Firestore
  getModels(): Observable<string[]> {
    return this.firestore
      .collection('vehicles-db')
      .valueChanges()
      .pipe(
        map((vehicles: any[]) => {
          const models = vehicles.map((vehicle) => vehicle.model);
          return [...new Set(models)]; // Get distinct 'model' values
        })
      );
  }

  // Fetch all distinct 'year' values from Firestore
  getYears(): Observable<number[]> {
    return this.firestore
      .collection('vehicles-db')
      .valueChanges()
      .pipe(
        map((vehicles: any[]) => {
          const years = vehicles.map((vehicle) => vehicle.year);
          return [...new Set(years)]; // Get distinct 'year' values
        })
      );
  }

  getFormData() {
    return this.formData.asObservable();
  }

  updateFormData(data: any) {
    this.formData.next(data);
  }

  clearFormData() {
    this.formData.next(null);
  }

  goBack() {
    this.location.back();
  }

  // Add a new document to the "add-ons" collection
  addAddOn(addOnData: any): Promise<DocumentReference<any>> {
    // Change 'any' to your data type
    return this.addOnsCollection.add(addOnData);
  }

  getAddOns(): Observable<any[]> {
    return this.firestore
      .collection('vehicles_addOns')
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((action) => {
            const id = action.payload.doc.id;
            const data: any = action.payload.doc.data();
            // Include the "id" within the "data" object
            data.id = id;
            return data;
          });
        }),
        catchError((error) => {

          return throwError(error);
        })
      );
  }

  getAddOnsByUserId(userId: string): Observable<any[]> {
    return this.firestore
      .collection('vehicles_addOns', (ref) => ref.where('userId', '==', userId))
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((action) => {
            const id = action.payload.doc.id;
            const data: any = action.payload.doc.data();
            // Include the "id" within the "data" object
            data.id = id;
            return data;
          });
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  storeVehicles(data: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // Access the Firestore collection
      const collectionRef = this.firestore.collection('store_vehicles');

      // Add a new document with the provided data
      collectionRef
        .add(data)
        .then((docRef) => {
          const id = docRef.id; // Get the automatically generated Firestore document ID
          const updatedData = { ...data, id: id }; // Add an "id" field to the data with the document ID
          docRef
            .update(updatedData)
            .then(() => {
              resolve('Data has been stored successfully'); // Resolve the Promise with a success message
            })
            .catch((error) => {
              reject('Error storing data: ' + error.message); // Reject the Promise with an error message
            });
        })
        .catch((error) => {
          reject('Error storing data: ' + error.message); // Reject the Promise with an error message
        });
    });
  }

  async getAllVehiclesList(): Promise<any[]> {
    try {
      const querySnapshot: any = await this.firestore
        .collection('store_vehicles')
        .get()
        .toPromise();

      const vehicles = querySnapshot.docs.map((doc: any) => {
        const id = doc.id;
        const data: any = doc.data();
        data.id = id;
        return data;
      });

      return vehicles;
    } catch (error) {
      throw error;
    }
  }

  async getVehicleById(vehicleId: string): Promise<any | null> {
    try {
      const docSnapshot: any = await this.firestore
        .collection('store_vehicles')
        .doc(vehicleId)
        .get()
        .toPromise();

      if (docSnapshot.exists) {
        const vehicleData = docSnapshot.data();
        vehicleData.id = docSnapshot.id;
        return vehicleData;
      } else {
        // Document with the given ID does not exist
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  getVehiclesByOwner(ownerid: string): Observable<any[]> {
    return this.firestore
      .collection('store_vehicles', (ref) =>
        ref.where('ownerid', '==', ownerid)
      )
      .valueChanges();
  }
  // Function to get favorites vehicles data by ownerId
  getFavoritesVehicles(favoritesVehicleIds: string[]): Observable<any[]> {
    return new Observable((observer) => {
      this.Collection.ref.where('id', 'in', favoritesVehicleIds).onSnapshot({
        next: (res: any) => {
          observer.next(res.docs.map((m: any) => m.data()));
        },
        error: (err) => {
          observer.error(err);
        },
      });
    });
  }

  async getAddonsById(addOnsId: string): Promise<any | null> {
    try {
      const docSnapshot: any = await this.firestore
        .collection('vehicles_addOns')
        .doc(addOnsId)
        .get()
        .toPromise();
      if (docSnapshot.exists) {
        const vehicleData = docSnapshot.data();
        vehicleData.id = docSnapshot.id;
        return vehicleData;
      } else {
        // Document with the given ID does not exist
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  // Function to update a vehicle by its ID
  updateVehicleById(vehicleId: string, newData: any): Observable<void> {
    const documentRef = this.firestore
      .collection('store_vehicles')
      .doc(vehicleId);

    return new Observable<void>((observer) => {
      documentRef
        .update(newData)
        .then(() => {
          observer.next(); // Complete the Observable on success
          observer.complete();
        })
        .catch((error) => {
          observer.error(error); // Emit an error if update fails
        });
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  updateOne(id: string, data: any) {
    return this.Collection.UpdateOne(id, data);
  }

  fetchVehicleModels(
    jsonFilePath: string
  ): Observable<{
    allData: any[]; makes: string[]; models: string[]; years: number[]
  }> {
    return this.http
      .get<{ make: string; model: string; year: number }[]>(jsonFilePath)
      .pipe(
        map((dataArray) => {
          const allData = dataArray;
          const makes = [...new Set(dataArray.map((item) => item.make))];
          const models = [...new Set(dataArray.map((item) => item.model))];
          const years = [...new Set(dataArray.map((item) => item.year))];

          return { makes, models, years, allData };
        })
      );
  }

  async createUpdateRequest(vehicleId: string, updateData: any): Promise<void> {
    const docRef = this.firestore.collection('updateVehicleRequests').doc(vehicleId);

    const existingDoc: any = await docRef.get().toPromise();

    if (existingDoc.exists) {
      // Document already exists, update it
      updateData.status = 0;
      return docRef.update(updateData);
    } else {
      // Document does not exist, create a new one
      updateData.vehicleId = vehicleId;
      updateData.id = vehicleId;
      updateData.status = 0;
      return docRef.set(updateData);
    }
  }


  alreadyBookingAutoCancel(vehicleId: string): Observable<number> {
    return from(
      this.firestore
        .collection('bookings')
        .ref.where('vehicleId', '==', vehicleId)
        .where('bookingApproved', '==', 4)
        .get()
    ).pipe(
      map((querySnapshot: any) => {
        const numBookings = querySnapshot.docs.length;
        return numBookings;
      })
    );
  }

}
