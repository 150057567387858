export const SETTINGS = 'settings';

export enum Settings {
  ROOT = '',
  PERSONAL_DETAILS = 'personal-details',
  HELP_AND_SUPPORT = 'help-and-support',
  REPORT = 'report',
  CONTACT = 'contact',
  TERMS_AND_CONDITIONS = 'term-and-conditions',
  REVIEW = 'review',
  REQUESTMANAGEMENT = 'request-management'
}
