export const HOSTSETTINGS = 'host-settings';

export enum HostSettings {
  ROOT = '',
  HOST_PERSONAL_DETAILS = 'host-personal-details',
  HOST_HELP_AND_SUPPORT = 'host-help-and-support',
  HOST_REPORT_AN_ISSUE = 'host-report-an-issue',
  HOST_EARNING = 'host-earning',
  HOST_REVIEW = 'host-review',
  HOST_REQUESTMANAGEMENT = 'host-request-management',
  HOST_REQUESTMANAGEMENTVIEW ='host-request-management-view',
  HOST_EARNING_DETAILS = 'earn-more-details'
}
