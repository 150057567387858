<footer footer *ngIf="loggedRole != 'Host'">
  <ul class="guest">
      <li routerLink="/home"
      routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <div class="d-flex justify-content-center" >
        <img src="../../../../assets/images/Guest/Icon/Home.svg" />
      </div>
      <span>Home</span>
    </li>
    <li routerLink="/favorites"
    routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" >
      <div class="d-flex justify-content-center" >
        <img src="../../../../assets/images/Guest/Icon/Favourites.svg" />
      </div>
      <span>Favourites</span>
    </li>
    <li routerLink="/trip/upcoming-trip"
    routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <div class="d-flex justify-content-center" >
        <img src="../../../../assets/images/Guest/Icon/Trips.svg" />
      </div>
       <span>My Trips</span>
    </li>
    <li routerLink="/chat/list"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <div
        class="d-flex justify-content-center position-relative">
        <img src="../../../../assets/images/Guest/Icon/Chat.svg" />
        <div class="notification-badge" *ngIf="unreadMessageCounts > 0">
          {{ unreadMessageCounts }}
        </div>
      </div>
      <span>chat</span>
    </li>
    <li routerLink="/settings"
    routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <div class="d-flex justify-content-center">
        <img src="../../../../assets/images/Guest/Icon/more.svg" />
      </div>
       <span>More</span>
    </li>
  </ul>
</footer>

<!-- Host Footer -->
<footer footer *ngIf="loggedRole == 'Host'">
  <ul class="host">
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        routerLink="/home-host">
      <div class="d-flex justify-content-center" >
        <img src="../../../../assets/images/Guest/Icon/Home.svg" />
      </div>
      <span>Home</span> 
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
         routerLink="/all-cars-host">
      <div class="d-flex justify-content-center" >
        <img src="../../../../assets/images/Host/Icon/list.svg" />
      </div>
      <span>Car Listing</span>
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        routerLink="/host-trip/my-trips">
      <div class="d-flex justify-content-center" >
        <img src="../../../../assets/images/Guest/Icon/Trips.svg" />
      </div>
      <span>My Trips</span>
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
      routerLink="/host-chat/list">
      <div class="d-flex justify-content-center position-relative">
        <img src="../../../../assets/images/Guest/Icon/Chat.svg" />
        <div class="notification-badge" *ngIf="unreadMessageCounts > 0">
          {{ unreadMessageCounts }}
        </div>
      </div>
      <span>Inbox</span>
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
    routerLink="/host-settings">
      <div class="d-flex justify-content-center">
        <img src="../../../../assets/images/Guest/Icon/more.svg" />
      </div>
      <span>More</span>
    </li>
  </ul>
</footer>
