import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { filter } from 'rxjs';
import { IonicColorOptions } from '../../../core/models/color';

@Component({
  selector: 'app-standard-button',
  templateUrl: './standard-button.component.html',
  styleUrls: ['./standard-button.component.scss'],
})
export class StandardButtonComponent {
  @Input() loading: boolean;
  @Input() expand: 'block' | 'full';
  @Input() slot: string;
  @Input() id: string;
  @HostBinding('attr.class') @Input() hostClass: string;
  @Input() class: string;
  @Input() color: IonicColorOptions = 'dark';
  @Input() fill: 'clear' | 'outline' | 'solid';
  @Input() disabled: boolean;
  _click = new EventEmitter<MouseEvent>();
  @Output() clicked = this._click.pipe(
    filter(() => !(this.loading || this.disabled))
  );
}
