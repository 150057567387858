import { TEMPLATE_IDS } from './templates';

export const TEMPLATE_DATA = {
  SIGNUP(userCredential: any, role: string) {
    return {
      to: userCredential?.email,
      fromName: 'Tarlen Carshare',
      templateId:
        role == 'Guest' ? TEMPLATE_IDS.SIGNUP_GUEST : TEMPLATE_IDS.SIGNUP_HOST,
      dynamic_template_data: {
        subject: `Welcome ${userCredential?.displayName.toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}`,
        name: ` ${userCredential?.displayName
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())} `,
         urlPath: role == 'Guest' ? 'home' : 'home-host',
      },
    };
  },
  CAR_LISTING(user: any, image: any) {
    return {
      to: user?.email,
      fromName: 'Tarlen Carshare',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `Your listing has been submitted`,
        name: `Hi, ${user?.displayName
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}`,
        userDetails:
          'You’ve completed your car listing. It’s now being reviewed, we’ll get back to you soon.',
        image: image,
      },
    };
  },
  CAR_ADDED_FAVOURITE(user: any, image: any) {
    return {
      to: user.email,
      fromName: 'Tarlen Carshare',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `Car added to favorite`,
        name: `Hi, ${user.displayName
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}`,
        userDetails:
          `You’ve added a Car to favorite.
          You can now come back to book it at a later date.`,
        image: image,
      },
    };
  },
  CHECK_IN_BEFORE_2HOUR_GUEST(toEmail: any, name: string, image: any) {
    return {
      to: toEmail,
      fromName: 'Tarlen trip support',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `You have an upcoming Trip: You can now check-in`,
        name: `<h2  style="
        color: #777;">Hi ${name
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}<h2>`,
        image: image,
        userDetails: `<p  style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">You have a trip coming up on Tarlen and it’s now open to check-in.</p>
       <p  style="
       color: #777;
       font-size: 16px;
       font-style: normal;
       font-weight: 400;
       line-height: 150%;
     "> Please confirm pickup/delivery information from Host, and contact support at tripsupport@tarlen.co.za if you need any help getting access your car.<p>    
       <p  style="
       color: #777;
       font-size: 16px;
       font-style: normal;
       font-weight: 400;
       line-height: 150%;
     "> Please note that you have to complete check-in to activate your protection cover. You have only 2hours after your trip starts to check-in.<p>  
        <p  style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      "> To complete your check-in;<p>
        <ul style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; >
        <li style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;" >Take a selfie with your ID card in the frame.<li>
        <li style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;">Take pictures of the interior and exterior of the car.<li>
        <li style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;" >Take a picture of your cars dashboard documenting the mileage number and fuel level.<li>
        </ul>
        <p  style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">After that, you can drive-off with confidence knowing you’re covered.<p>`,
      },
    };
  },
  HOST_CHECK_IN_BEFORE_2_HOUR(toEmail: any, name: string, image: any) {
    return {
      to: toEmail,
      fromName: 'Tarlen trip support',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `You have an upcoming Trip: You can now check-in`,
        name: `<h2  style="
        color: #777;">Hi ${name
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}<h2>`,
        image: image,
        userDetails: `<P style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">You have a trip coming up on Tarlen and it’s now open to check-in.</p>

        <p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Please make sure you communicate pickup/Delivery information to your Guest, and contact support at tripsupport@tarlen.co.za if you need any help with drop-off.</p>
        
      <p style="
      color: #777;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    "> Please note that you have to complete check-in before handing over the keys to your Guest. This is an important process to activate your protection cover. </p>
        
      <p style="
      color: #777;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    "> To complete your check-in;</p>
      <ul style="
      color: #777;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    ">
      <li style="
      color: #777;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    ">Take pictures of the interior and exterior of your car.<li>
        <li style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Take a picture of your cars dashboard documenting the mileage number and fuel level.</li>
        </ul>
      <p style="
      color: #777;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    "> Now you can hand over the key to your Guest with confidence.<p>`,
      },
    };
  },
  GUSER_TRIP_ENDED_BEFORE_2_HOUR(toEmail: any, name: string, image: any) {
    return {
      to: toEmail,
      fromName: 'Tarlen trip support',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `Your trip is ending!`,
        name: `<h2  style="
        color: #777;">Hi ${name
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}<h2>`,
        image: image,
        userDetails: `<p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Your trip is coming to an end.</p>

        <p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      "> Your pick-up/delivery location is automatically your return location, if that is changing, communicate with your Host to reach an agreement about a new return location. Please contact support at tripsupport@tarlen.co.za if you need any help.</p>
        
        <p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">It’s advised to return your car promptly. If returned later than 29mins after your trip end time, you’ll be charged a late return fee. Before handing over the keys to your Host, you’re required to document the car condition.</p>
        
        <p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">To do this, you’ll need to;</p>
        <ul style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">
        <li style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Take pictures of the interior and exterior of the card.</li>
        <li style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Take a picture of the cars dashboard documenting the mileage number and fuel level.</li>
        </ul>
        <p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Now you can hand over the key to the owner. Please return the car clean and the fuel tank at the same level it was when you drove off, or a fee might be deducted from your deposit.</p>
        
      <p style="
      color: #777;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    "> Your deposit will be refunded to you 72hours after the end of the trip unless it’s a holiday or a weekend or if your Host raises an issue, then, Tarlen would wait until issue is resolved to reimburse your deposit.</p>`,
      },
    };
  },
  HOST_TRIP_ENDED_BEFORE_2_HOUR(toEmail: any, name: string, image: any) {
    return {
      to: toEmail,
      fromName: 'Tarlen trip support',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `Your trip is ending!`,
        name: `<h2  style="
        color: #777;">Hi ${name
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}<h2>`,
        image: image,
        userDetails: `<p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Your trip is coming to an end.</p>

        <p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Your pick-up/delivery location is automatically the return location, if that is changing, please communicate with your Guest to reach an agreement about a new return location. Or contact support at tripsupport@tarlen.co.za if you need any help.</p>
        
        <p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Please arrive promptly at the return location for your Guest. After getting your keys you have 12hours to document the car condition. </p>
        
      <p style="
      color: #777;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    "> To do this, you’ll need to;</p>
      <ul style="
      color: #777;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    ">
        <li style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Take pictures of the interior and exterior of your car.</li>
        <li style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Take a picture of the cars dashboard documenting the mileage number and fuel level.</li>
        </ul>
        <p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Contact support in-app or at tripsupport@tarlen.co.za to report a late return, damages or a cleaning violation. To request payment for traffic fines or fuel charges, navigate to the trip, click on the request reimbursement button and follow the instructions to have your request sent to the Guest.</p>`,
      },
    };
  },
  VERIFICATION_SUCCESSFULLY(userCredential: any) {
    return {
      to: userCredential?.email,
      fromName: 'Tarlen Carshare',
      subject: 'Tarlen Car Share',
      templateId: TEMPLATE_IDS?.OTHERS,
      dynamic_template_data: {
        subject: `Verification successful.`,
        name: `<h2  style="
        color: #777;">Hi ${userCredential?.displayName
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}<h2>`,
        image:
          'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2Fbg.png?alt=media&token=858d376c-141d-4834-b41c-a7d0f83546ae',
        userDetails: `<P style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Thanks for completing your verification. You can now rent and drive cars on Tarlen.</P>`,
      },
    };
  },
  GUEST_CHECK_IN(user: any, image: any) {
    return {
      to: user?.email,
      fromName: 'Tarlen trip support',
      subject: 'Tarlen Car Share',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `Check-in Successful`,
        name: `<h2  style="
        color: #777;">Hi ${user?.userName
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}<h2>`,
        image: image,
        userDetails: `<P style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Your check-in has been completed. Enjoy your trip and drive safely.</P>`,
      },
    };
  },
  HOST_CHECK_IN(user: any, image: any) {
    return {
      to: user?.ownerEmail,
      fromName: 'Tarlen trip support',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `Check-in Successful`,
        name: `<h2  style="
        color: #777;">Hi ${user?.ownerName
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())} <h2>`,
        image: image,
        userDetails: `<P style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Your check-in has been completed. You can now relax and start earning.</P>`,
      },
    };
  },
  GUSER_TRIP_ENDED(user: any, image: any) {
    return {
      to: user?.email,
      fromName: 'Tarlen trip support',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `Your trip has ended`,
        name: ` <p style="
        color: #777;>Hi ${user?.userName
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())} </p>`,
        image: image,
        userDetails: `<P style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Your trip has just ended. Thanks for trusting us! Don’t forget to leave a review and tell a friend about us!<P>`,
      },
    };
  },

  PAYMENT_FAILED(bookinData: any) {
    return {
      to: bookinData.email,
      fromName: 'Tarlen trip support',
      templateId: TEMPLATE_IDS.PAYMENT_FAILED,
      subject: 'Tarlen Car Share',
      dynamic_template_data: {
        subject: `Your payment has failed. Please retry your payment to complete your booking`,
        userDetails: bookinData.ownerName,
        urlPath: 'host-trip/my-trips',
        name: bookinData?.userName,
        image: bookinData?.vehicleData?.carCoverImage[0],
      },
    };
  },

  HOST_GET_BOOKING_REQUEST(bookinData: any, image: any) {
    return {
      to: bookinData?.booking?.ownerEmail,
      fromName: 'Tarlen trip support',
      templateId: TEMPLATE_IDS.HOST_RECIVE_BOOKING_REQUEST,
      dynamic_template_data: {
        subject: `${bookinData?.booking?.userName} wants to book your ${bookinData?.booking?.vehicleData?.vehicle_year} ${bookinData?.booking?.vehicleData?.vehicle_make} ${bookinData?.booking?.vehicleData?.vehicle_model}.`,
        userDetails: bookinData,
        urlPath: 'host-trip/my-trips',
        image: image,
      },
    };
  },
  GUEST_CONFIRM_BOOKING(bookinData: any, image: any) {
    return {
      to: bookinData?.booking?.email,
      fromName: 'Tarlen trip support',
      templateId: TEMPLATE_IDS.GUEST_CONFIRM_BOOKING,
      dynamic_template_data: {
        subject: `Your booking reservation for ${bookinData?.booking?.ownerName}’s  ${bookinData?.booking?.vehicleData?.vehicle_make} ${bookinData?.booking?.vehicleData?.vehicle_model} has
        been confirmed. Booking confirmation is ${bookinData?.booking?.id}`,
        userDetails: bookinData,
        urlPath: 'trip/upcoming-trip',
        image: image,
      },
    };
  },
  HOST_GET_CONFIRM_BOOKING(bookinData: any, image: any) {
    return {
      to: bookinData?.booking?.ownerEmail,
      templateId: TEMPLATE_IDS.HOST_CONFIRM_BOOKING,
      fromName: 'Tarlen trip support',
      dynamic_template_data: {
        subject: `Your ${bookinData?.booking?.vehicleData?.vehicle_year} ${bookinData?.booking?.vehicleData?.vehicle_make} ${bookinData?.booking?.vehicleData?.vehicle_model} has been booked by ${bookinData?.booking?.ownerName}’. Booking confirmation is ${bookinData?.booking?.id}`,
        userDetails: bookinData,
        urlPath: 'host-trip/my-trips',
        image: image,
      },
    };
  },
  HOST_EXTENSION_REQUEST(user: any, image: any) {
    return {
      to: user.ownerEmail,
      fromName: 'Tarlen trip support',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `Extension request.`,
 
        name: `<h2  style="
        color: #777;">Hi ${user.ownerName
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}<h2>`,
        image: image,
        userDetails: `<P style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">You have an extension request from ${user?.userName}. Please navigate in-app and respond to this request.</p>`,
      },
    };
  },

  GUSER_EXTENSION_REQUEST_ACCEPTED(user: any, image: any) {
    return {
      to: user.email,
      fromName: 'Tarlen trip support',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `Extension request accepted.`,
        name: `<h2  style="
        color: #777;">Hi ${user?.userName
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}<h2>`,
        image: image,
        userDetails: `<p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Your Extension Request for ${user.ownerName}’s ${user.vehicleData.vehicle_year} ${user.vehicleData.vehicle_make} ${user.vehicleData.vehicle_model}  has been accepted! Enjoy your trip.</p>`,
      },
    };
  },
  GUSER_EXTENSION_REQUEST_REJECTED(user: any, image: any) {
    return {
      to: user.email,
      fromName: 'Tarlen trip support',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `Extension request rejected.`,
    
        name: `<h2  style="
        color: #777;">Hi ${user?.userName
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}<h2>`,
        image: image,
        userDetails: `<P style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;>our Extension Request for ${user?.ownerName}’s ${user?.vehicleData?.vehicle_year} ${user?.vehicleData?.vehicle_make} ${user?.vehicleData?.vehicle_model}  was rejected!</P>
        <P>Browse our other listings and book a new car for those days.<P>`,
      },
    };
  },
  GUEST_NOTIFY_FOR_CANCEL_TRIP(user: any, image: any) {
    return {
      to: user.email,
      fromName: 'Tarlen trip support',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `Your trip has been cancelled`,
    
        name: `<h2  style="
        color: #777;">Hi ${user?.userName
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}<h2>`,
        image: image,
        userDetails: `<P style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">Your trip has been cancelled. If you did not cancel this trip, you’ll get a full refund and support will be in touch to help you booking another car for similar trip time.</p>

        <p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">If you cancelled, you might incur a cancellation fee. Read our Cancellation policy to learn more. </p>
        
        <p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      "> Contact support if your Host asked you to cancel.</p>`,
      },
    };
  },
  HOST_NOTIFY_TO_CANCELLED(user: any, image: any) {
    return {
      to: user.ownerEmail,
      fromName: 'Tarlen trip support',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `Your trip has been cancelled `,
    
        name: `<h2  style="
      color: #777;">Hi ${user.ownerName
        .toLowerCase()
        .replace(/\b\w/g, (s: any) => s.toUpperCase())}<h2>`,
        image: image,
        userDetails: `<P style="
      color: #777;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    ">Your trip has been cancelled. If you did not cancel this trip, you won’t be charged a cancellation fee.</p>

        <p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      ">If you cancelled, you might incur a cancellation fee and a cancellation review on your profile. Read our Cancellation policy to learn more. </p>
        
      <p style="
      color: #777;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    "> Contact support if your Guest asked you to cancel.</p>`,
      },
    };
  },
  HOST_ACCEPT_BOOKING_REQUEST(userCredential: any, image: any) {
    return {
      to: userCredential.booking.email,
      templateId: TEMPLATE_IDS.HOST_ACCEPT_BOOKING_REQUEST,
      fromName: 'Tarlen trip support',
      dynamic_template_data: {
        subject: `Your Request for ${userCredential?.booking?.ownerName}’s ${userCredential?.booking?.vehicleData?.vehicle_year} ${userCredential?.booking?.vehicleData?.vehicle_make} ${userCredential?.booking?.vehicleData?.vehicle_model}, has been accepted! `,
        userDetails: userCredential,
        image: image,
        urlPath: 'host-trip/my-trips',
      },
    };
  },
  HOST_REJECT_BOOKING_REQUEST(userCredential: any, image: any) {
    return {
      to: userCredential.email,
      templateId: TEMPLATE_IDS.HOST_REJECT_BOOKING_REQUEST,
      fromName: 'Tarlen trip support',
      dynamic_template_data: {
        subject: `Your Request for ${userCredential?.ownerName}’s ${userCredential?.vehicleData?.vehicle_year} ${userCredential?.vehicleData?.vehicle_make} ${userCredential?.vehicleData?.vehicle_model}, has been Rejected! `,
        userDetails: userCredential,
        name: userCredential?.userName,
        image: image,
        urlPath: 'host-trip/my-trips',
      },
    };
  },

  HOST_REIMBURSEMENT_REQUESTED_ACCEPTED_BY_GUEST(user: any,image:any) {
    return {
      to: user?.ownerEmail,
      fromName: 'Tarlen Carshare',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `Your guest has accepted your
        Reimbursement request`,
    
        name: `<h2  style="
        color: #777;">Hi ${user?.userName
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}<h2>`,
        image: image,
        userDetails: `<P style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;>You payment request has been accepted by your Guest. Your trip payment will be disbursed soon.</p>
  
        <p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;>If you have any inquiries, please contact support at tripsupport@tarlen.co.za.</p>`,
      },
    };


    return {
      to: user.email,
      dynamic_template_data: {
        name: `Hi ${name}`,
        message: `<p>You payment request has been accepted by your Guest. Your trip payment will be disbursed soon.</p>
  
          <p>If you have any inquiries, please contact support at tripsupport@tarlen.co.za.</p>`,
      },
    };
  },
  HOST_REIMBURSEMENT_REQUESTED_ESCALATED_BY_GUEST(user: any,image:any) {
    return {
      to: user?.email,
      fromName: 'Tarlen Carshare',
      templateId: TEMPLATE_IDS.OTHERS,
      dynamic_template_data: {
        subject: `Your guest has accepted your
        Reimbursement request`,
    
        name: `<h2  style="
        color: #777;">Hi ${user?.ownerName
          .toLowerCase()
          .replace(/\b\w/g, (s: any) => s.toUpperCase())}<h2>`,
        image: image,
        userDetails: `<P style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;>You payment request has escalated to support for further review. Please give support a few hours to handle request.</p>
          
        <p style="
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;>If you have any inquiries, please contact support at tripsupport@tarlen.co.za</p>`,
      },
    };


    return {
      to: user.email,
      dynamic_template_data: {
        name: `Hi ${name}`,
        message: `<p>You payment request has been accepted by your Guest. Your trip payment will be disbursed soon.</p>
  
          <p>If you have any inquiries, please contact support at tripsupport@tarlen.co.za.</p>`,
      },
    };
  },
  // NEED TO TEST

  RESET_PASSWORD(userCredential: any) {
    return {
      to: userCredential.email,
      subject: 'Tarlen Car Share',
      name: `Hi ${name}`,
      message:
        'You’re trying to reset your password. To continue, please click here or follow the link below. ',
      button: '{{link}}',
    };
  },
  PASSWORD_RESET_SUCCESSFULLY(userCredential: any) {
    return {
      to: userCredential.email,
      dynamic_template_data: {
        name: `Hi ${name}`,
        message:
          'Your password has been changed successfully. If you did not request this change, please contact support immediately.',
      },
    };
  },

  GUSER_REIMBURSEMENT_REQUEST(user: any) {
    return {
      to: user.email,
      dynamic_template_data: {
        name: `Hi ${name}`,
        message: `<P>You have a payment request on your recent trip. Please navigate to the trip to view and respond to request.</P>
  
        <P> Alternatively, contact support at tripsupport@tarlen.co.za to learn more about this request.<P>`,
      },
    };
  },



  HOST_LISTING_ACCEPTED_REJECTED_BY_ADMIN(user: any) {
    return {
      to: user.email,
      dynamic_template_data: {
        name: `Hi ${user?.displayName}`,
        message: `<p> Your listing update has been reviewed and updated successfully.  Renters can see the new changes./p>`,
      },
    };
  },
};
