<ion-button
  [expand]="expand"
  [slot]="slot"
  [id]="id"
  [class]="class"
  [color]="color"
  [disabled]="disabled || loading"
  [fill]="fill"
  (click)="_click.next($event)"
>
  <ng-content *ngIf="!loading"></ng-content>
  <div class="d-flex justify-content-center" *ngIf="loading">
    <ion-spinner class="ms-2"></ion-spinner>
  </div>
</ion-button>
