import { BaseEntity, InsuranceOption, VerificationStatus } from './misc';

export function GetBookingStatusSortValue(bookingStatus: BookingStatus) {
  switch (bookingStatus) {
    case BookingStatus.REQUESTED:
      return 0;
    case BookingStatus.PAYMENT_PENDING:
      return 1;
    case BookingStatus.PAYMENT_CONFIRMED:
      return 2;
    case BookingStatus.COMPLETE:
      return 3;
    case BookingStatus.DECLINED:
      return 4;
    default:
      return 5;
  }
}

export enum BookingStatus {
  CANCELLED = -2,
  DECLINED = -1,
  REQUESTED,
  PAYMENT_PENDING,
  PAYMENT_CONFIRMED,
  COMPLETE,
}

export interface Booking extends BaseEntity, BookingDate {

  // link to user
  userId: string;
  userVerifiedStatus: VerificationStatus;
  userEmail: string;
  // link to vehicle
  vehicleId: string;
  vehicleOwnerId: string;
  vehicleLicensePlate: string;
  // details
  pricePerDay: string;
  insuranceOption: InsuranceOption;
  delivery_address?: any;
  totalPrice: string;
  status: BookingStatus;
  prefferedTime: any;
  location: any;
  bookingType: any;
  paymentMode: any;
  paymentStatus: any;
  startTrip?: any;
  addOns?: any;
  bookingApproved?:any
  /**
   * @deprecated Unused, field exists to future-proof.
   */
  invoiceSent: boolean;
  /**
   * @deprecated Unused, field exists to future-proof.
   */
  invoicePaid: boolean;
}

export interface BookingDate {
  startDate: number;
  endDate: number;
}

export interface BookVehicleDTO {
  insuranceOption: InsuranceOption;
  startDate: number;
  endDate: number;
  vehicleId: string;
  prefferedTime: any;
  location: any;
  bookingType: any;
  paymentMode: any;
  paymentStatus: any;
  addOns?: any;
  bookingApproved?:any
}

export enum BookingJobS {
  TWO_HR_BEFORE_START_TRIP,
  ON_START_START_TRIP,
  ONE_HR_AFTER_START_TRIP,
  TWO_HR_AFTER_START_TRIP,
  TWO_HR_BEFORE_END_TRIP,
  ON_END_TRIP,
  TWELVE_HR_AFTER_END_TRIP,
  ON_EXTENSION_REQUEST_NOTIFICATION,
  ON_EXTENSION_CANCEL,
  ON_BOOKING_REQUEST_NOTIFICATION,
  ON_BOOKING_CANCEL,
  ON_BOOKING_PAYMENT_REFUND,
}

export const BookingJobTypes: { bookingJobType: BookingJobS; value: BookingJobS }[] = Object.keys(BookingJobS).reduce(
  (bookingJobTypes, bookingJobType) => {
    if (!isNaN(Number(bookingJobType))) return bookingJobTypes;
    return [
      ...bookingJobTypes,
      {
        bookingJobType: BookingJobS[bookingJobType as keyof typeof BookingJobS],
        value: BookingJobS[bookingJobType as keyof typeof BookingJobS],
      },
    ];
  },
  [] as { bookingJobType: BookingJobS; value: BookingJobS }[]
);

