import { createAnimation } from '@ionic/angular';
const ANIMATION_DURATION = 200;

function GetModalAnimationElements(baseElement: HTMLElement, opts: any) {
  const baseElementShadowRoot = baseElement.shadowRoot!;

  const rootAnimation = createAnimation()
    .duration(opts.duration || ANIMATION_DURATION)
    .easing('cubic-bezier(.58,.01,.27,.99)');

  const backdropAnimation = createAnimation().addElement(
    baseElementShadowRoot.querySelector('ion-backdrop')!
  );

  const wrapperAnimation = createAnimation().addElement(
    baseElementShadowRoot.querySelector('.modal-wrapper')!
  );

  return {
    rootAnimation,
    backdropAnimation,
    wrapperAnimation,
  };
}

export const getModalAnimation = (direction: 'enter' | 'leave') => {
  return (baseElement: HTMLElement, opts: any) => {
    const { rootAnimation, backdropAnimation, wrapperAnimation } =
      GetModalAnimationElements(baseElement, opts);

    if (direction === 'enter') {
      backdropAnimation.fromTo('opacity', '0.01', 'var(--backdrop-opacity)');
      wrapperAnimation.keyframes([
        {
          offset: 0,
          opacity: '0',
          transform: 'translateX(50%)',
        },
        {
          offset: 1,
          opacity: '0.99',
          transform: 'translateX(0%)',
        },
      ]);
    } else {
      backdropAnimation.fromTo('opacity', 'var(--backdrop-opacity)', '0.01');
      wrapperAnimation.keyframes([
        {
          offset: 0,
          opacity: '0.99',
          transform: 'translateX(0%)',
        },
        {
          offset: 1,
          opacity: '0',
          transform: 'translateX(-100%)',
        },
      ]);
    }

    return rootAnimation
      .duration(
        direction === 'enter' ? ANIMATION_DURATION : ANIMATION_DURATION * 2
      )
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };
};

const getIonPageElement = (element: HTMLElement) => {
  if (element.classList.contains('ion-page')) {
    return element;
  }

  const ionPage = element.querySelector(
    ':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs'
  );
  if (ionPage) {
    return ionPage;
  }

  return element;
};

export const getPageAnimation = (_: HTMLElement, opts: any) => {
  const rootTransition = createAnimation()
    .duration(opts.duration || ANIMATION_DURATION)
    .easing('cubic-bezier(.58,.01,.27,.99)');

  const enteringPage = createAnimation()
    .addElement(getIonPageElement(opts.enteringEl))
    .beforeRemoveClass('ion-page-invisible');

  const leavingPage = createAnimation().addElement(
    getIonPageElement(opts.leavingEl)
  );

  if (opts.direction === 'forward') {
    enteringPage
      .fromTo('transform', 'translateX(50%)', 'translateX(0)')
      .fromTo('opacity', '0', '1');
    leavingPage
      .fromTo('transform', 'translateX(0%)', 'translateX(-50%)')
      .fromTo('opacity', '1', '0');
  } else {
    leavingPage
      .fromTo('transform', 'translateX(0%)', 'translateX(50%)')
      .fromTo('opacity', '1', '0');
    enteringPage
      .fromTo('transform', 'translateX(-50%)', 'translateX(0%)')
      .fromTo('opacity', '0', '1');
  }

  rootTransition.addAnimation(enteringPage);
  rootTransition.addAnimation(leavingPage);
  return rootTransition;
};
