export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDWKH2qNcTKj0UZqjuR9sZmiZkjOnsQT3k",
    authDomain: "tarlen-prod.firebaseapp.com",
    projectId: "tarlen-prod",
    storageBucket: "tarlen-prod.appspot.com",
    messagingSenderId: "731942907506",
    appId: "1:731942907506:web:bbb5b9b33165408af95ceb",
    vapidKey: "AAAA5FB5PZ8:APA91bE01IHqvD9I2_uTWq6zw63xKtFTNsR1WVjkFoksFeqXVdrAgaZ17P_cTQys-JMzGTMnYMDnf6JoYUZgymIf_TZtrITVHzXwEb5Ns47kchUM4sP318aiVL1Cl62Oa9XITuxwVEEy"
  },
  peachPayment: {
    ServerKey: 'OGFjZGE0Y2E4N2U2NDUxODAxODdlYmNhZmQ2YjI1MTR8ZENkYXEyYjdRcQ==',
    EntityId: '8acda4ca87e645180187ebd585eb2569'
  },
      cTrack: {
      production: false,
      apiUrl: 'https://stgapi.ctrackcrystal.co.za/api',
    },
  googleClientId: '731942907506-13vjp2cfmqbbhjnrlpia94v1tvv95p1r.apps.googleusercontent.com',
  ADMIN_UID: 'FvrytDirqAPDOUIbpwUj0IDM3j22',
  url: 'https://us-central1-tarlen-prod.cloudfunctions.net/',
  useEmulators: false,
};
