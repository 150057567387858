//const static_url = 'https://tarlen-prod.web.app/';
const static_url = 'https://tarlen-dev.web.app/';
export const PUSH_NOTIFICATIONS = {
LOGIN(role: string, deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: 'Login successful',
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: role == 'Guest' ? `${static_url}home` : `${static_url}home-host`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: role == 'Guest' ? '/home' : '/home-host'
    },
    to: deviceToken,
  }
},


HOST_SEND_MESSAGE(user: any, deviceToken: any, chatId = '') {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: `${user?.displayName} sent a message!`,
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}chat/list`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/host-chat/list',
      chatId: chatId
    },
    to: deviceToken,
  }
},
GUEST_SEND_MESSAGE(user: any, deviceToken: any, chatId = '') {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: `${user?.displayName} sent a message!`,
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}host-chat/list`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/host-chat/list',
      chatId: chatId
    },
    to: deviceToken,
  }
},


BOOKING_PAYMENT_FAILED(bookingId: any, deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: 'Your payment has failed!',
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}car-booking/booking-payment/${bookingId}`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: `/car-booking/booking-payment/${bookingId}`
    },
    to: deviceToken,
  }
},


BOOKING_CONFIRMATION(bookingId: any, deviceToken: any) {
  return {
    notification: {
      title: 'Booking Confirmation',
      body: `Your trip has been booked successfully. ${bookingId}`,
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}trip/upcoming-trip`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/trip/upcoming-trip',
    },
    to: deviceToken,
  }
},
HOST_GET_CONFIRMATION(bookingId: any, deviceToken: any) {
  return {
    notification: {
      title: 'Booking Confirmation',
      body: `Your car has been booked successfully. ${bookingId}`,
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}trip/host-trip/my-trips`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/host-trip/my-trips',
    },
    to: deviceToken,
  }
},
HOST_GET_BOOKING_REQUEST(user: any, deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: `You have received a booking request from ${user?.displayName}`,
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}host-trip/my-trips`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/host-trip/my-trips',
    },
    to: deviceToken,
  }
},
HOST_ACCEPT_BOOKING(user: any, deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: `Your request to book ${user?.ownerName}'s car has been accepted`,
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}trip/upcoming-trip`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/host-trip/my-trips',
    },
    to: deviceToken,
  }
},
HOST_REJECT_BOOKING(user: any, deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: `Your request to book ${user?.ownerName}'s car has been Rejected`,
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}trip/upcoming-trip`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/trip/upcoming-trip',
    },
    to: deviceToken,
  }
},
HOST_ESCALATED__BOOKING(user: any, deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: `Your reimbursement request has been escalated`,
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}trip/upcoming-trip`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/trip/upcoming-trip',
    },
    to: deviceToken,
  }
},
HOST_EXTENDED_REJECT_BOOKING(user: any, deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: `Your extension request has been rejected`,
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}trip/upcoming-trip`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/trip/upcoming-trip',
    },
    to: deviceToken,
  }
},
HOST_EXTENDED_ACCEPT_BOOKING(user: any, deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen trip support',
      body: `Your extension request has been accepted`,
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}trip/upcoming-trip`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/trip/upcoming-trip',
    },
    to: deviceToken,
  }
},
GUEST_REQUEST_EXTENDED__BOOKING(deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen trip support',
      body: `Your guest wants to extend their trip, please respond`,
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}host-home`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/host-home',
    },
    to: deviceToken,
  }
},
HOST_CANCEL_TRIP(deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: `Your trip has been cancelled`,
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}trip/upcoming-trip`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/trip/upcoming-trip',
    },
    to: deviceToken,
  }
},
GUEST_CANCEL_TRIP(deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: `Your trip has been cancelled`,
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}trip/upcoming-trip`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/trip/upcoming-trip',
    },
    to: deviceToken,
  }
},
VERIFICATION(deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: 'Your license verification was successful. You can now start renting with Tarlen.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}all-vehicles`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/all-vehicles',
    },
    to: deviceToken,
  }
},
UNSUCCESSFUL_VERIFICATION(deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: 'Your verification was unsuccessful. Please try again or get in contact with support.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      // link: 'https://tarlen-dev.web.app/'
      content_available: true,
      mutable_content: true
    },
    data: {
      // url: '/all-vehicles',
    },
    to: deviceToken,
  }
},
HOST_REQUEST_REIBUSRESMENT(deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: 'Your host has request reimbursement on a trip',
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}trip/upcoming-trip`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: 'trip/upcoming-trip',
    },
    to: deviceToken,
  }
},
GUEST_REJECT_REIBUSRESMENT(deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: 'Changes to your listing was rejected',
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}host-trip/my-trips`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/host-trip/my-trips',
    },
    to: deviceToken,
  }
},
GUEST_ACCEPT_REIBUSRESMENT(deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: 'Your reimbursement request has been accepted',
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}host-trip/my-trips`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/host-trip/my-trips',
    },
    to: deviceToken,
  }
},
HOST_REVIEW(deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: 'You have received a new review. Go to your profile to see review.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}host-trip/my-trips`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/host-trip/my-trips',
    },
    to: deviceToken,
  }
},
GUEST_REVIEW(deviceToken: any) {
  return {
    notification: {
      title: 'Tarlen Carshare',
      body: 'You have received a new review. Go to your profile to see review.',
      icon: 'https://firebasestorage.googleapis.com/v0/b/tarlen-dev.appspot.com/o/EmailTemplate%2FGuest%20Logo%20(2).png?alt=media&token=ca3e8579-759a-484f-bd14-5a578e351841',
      link: `${static_url}host-trip/my-trips`,
      content_available: true,
      mutable_content: true
    },
    data: {
      url: '/host-trip/my-trips',
    },
    to: deviceToken,
  }
}
}