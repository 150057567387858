export enum ICONS {
  CHEVRON_LEFT = './icons/chevron-left.svg',
  CHEVRON_RIGHT = './icons/chevron-right.svg',
  CHEVRON_RIGHT_RED = './icons/chevron-red-right.svg',
  CHEVRON_RIGHT_SMALL = './icons/chevron-right-small.svg',
  PASSWORD_HIDDEN = './icons/password-hidden.svg',
  PASSWORD_VISIBLE = './icons/password-visible.svg',
  SUCCESS_TICK = './icons/Group.png',
  RESET_TICK = './icons/reset.png',
  CAR = './icons/car.svg',
  CHECK_CIRCLE = './icons/check-circle.svg',
  ENVELOPE = './icons/envelope.svg',
  WALLET = './icons/wallet.svg',
  GAS_PUMP = './icons/gas-pump.svg',
  MAP = './icons/map.svg',
  CAMERA = './icons/camera.svg',
  COLOR_FILL = './icons/color-fill.svg',
  ENGINE = './icons/engine.svg',
  WARNING = './icons/warning.svg',
  ADD_IMAGE = './icons/add-image.svg',
  CLOSE = './icons/close.svg',
  AMOUNT = './icons/amount.svg',
  CALENDAR = './icons/calendar.svg',
  INSURANCE = './icons/insurance.svg',
  USER_VERIFIED = './icons/user-verified.svg',
  RIGHT_TICK_ICON = './icons/rightTickIcon.svg',
  REQUESTBOOKING= './icons/requestBooking.svg',
  Vroom = './icons/vroom.svg',
  End_Trip = './icons/end-trip.svg',
  RIGHT = 'RIGHT',
  PLUS_IMG = 'PLUS_IMG',
  CROSS_ICON = './icons/cross.svg',
  TRIP_STARTED = './icons/tripstarted.svg',
  SHINING = './icons/shining.svg',
}

export enum IMAGES {
  BANNER_BACKGROUND_ORANGE = 'assets/images/banner-background-orange.png',
  BANNER_BACKGROUND_BLUE = 'assets/images/banner-background-blue.png',
}
