import { Injectable } from '@angular/core';
import { async } from '@firebase/util';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loaderElement: HTMLIonLoadingElement;
  constructor(private loadingCtrl: LoadingController) { }
  timeOut: any;
  isloaderEnabled = new BehaviorSubject<boolean>(false);

  public async showLoader() {
    this.isloaderEnabled.next(true);
  }

  public hideLoader() {
    this.isloaderEnabled.next(false);
  }

  clearTimeout(timeOutId: any) {
    clearTimeout(timeOutId);
  }

  async showLoaderAfter30Sec() {
    this.loaderElement = await this.loadingCtrl.create({
      spinner: 'lines',
      cssClass: 'custom-loader',
      message:
        "Please wait, we're processing your request. This may take a moment...",
      animated: true,
    });
    this.loaderElement.present();
  }
}
