// import  {environment} from '../../../../apps/mobile/src/environments/environment'
//ADMIN_ID for staging ='SyDrznKh9jOkDMkxxdgG9l8zdtD3'
//ADMIN_ID for prod ='FvrytDirqAPDOUIbpwUj0IDM3j22'
const ADMIN_ID = 'SyDrznKh9jOkDMkxxdgG9l8zdtD3';
const ADMIN_NAME = 'Tarlen Admin';
export const IN_APP_NOTIFICATIONS = {
    SIGNUP(newlyCreatedUser: any, role: string) {
        return {
            userId: ADMIN_ID,
            title: 'New Register ',
            type: 'New Register',
            message: `New ${role} is registered`,
            senderName: newlyCreatedUser?.displayName,
            senderId: newlyCreatedUser?.id
            ,
        }
    },
    HOST_LISTING_NEW_CAR(user:any) {
        return {
            userId: ADMIN_ID,
            title: 'New Car Register',
            type: 'New Car Register',
            message: `A vehicle listing has been submitted.`,
            senderName: user?.displayName,
            senderId: user?.id,
        }
    },
    HOST_EDIT_CAR_LISTING(user:any) {
        return {
            userId: ADMIN_ID,
            title: 'Edit car listing ',
            type: 'Edit car listing',
            message: `A vehicle listing has been edited. Please review.`,
            senderName: user?.displayName,
            senderId: user?.id,
        }
    },
    GUEST_PAYMENT_FAILED(user: any) {
        return {
            userId: user.id,
            title: 'Tarlen Carshare',
            type: 'Payment Failed',
            message: `Hi ${user?.displayName} Your trip payment has failed. Please check & update your card details.`,
            senderName: ADMIN_NAME,
            senderId: ADMIN_ID,
            buttonName: 'See review',
        }
    } ,
    GUEST_CONFIRM_BOOKING(user: any,booking:any) {
        return {
            userId: user?.id,
            title: 'Tarlen Carshare',
            type: 'Payment Sucessful',
            message: `${user?.displayName}, Your booking has been confirmed. View trip to see your booking details. Booking confirmation number ${booking.id}`,
            senderName: ADMIN_NAME,
            senderId: ADMIN_ID,
            buttonName: 'View Trip',
        }
    } ,
    HOST_ACCEPT_BOOKING(user: any,booking:any) {
        return {
            userId: user?.id,
            title: 'Tarlen Carshare',
            type: 'Booking Confirm',
            message: `Congratulations, ${user?.displayName}! Your booking request has been accepted. Get ready to hit the road!`,
            senderName: booking.ownerName,
            senderId: booking.vehicleOwnerId,
            buttonName: 'See Trip',
        }
    } ,
    HOST_REJECT_BOOKING(user: any,booking:any) {
        return {
            userId: user?.id,
            title: 'Tarlen Carshare',
            type: 'Booking Rejected',
            message: `${user?.displayName}, We’re sorry, your request has been rejected by Host. Please browse and book other cars.`,
            senderName: booking?.ownerName,
            senderId: booking?.vehicleOwnerId,
            buttonName: 'Browse Car',
        }
    } ,
    HOST_EXTENDED_REJECT_BOOKING(user: any,booking:any) {
        return {
            userId: user?.id,
            title: 'Tarlen Carshare',
            type: 'Booking Rejected',
            message: `<P>Hi ${user?.displayName},</P>
            <P>Your Extension Request for ${booking?.ownerName}’s ${booking?.vehicleData?.vehicle_year} ${booking.vehicleData.vehicle_make} ${booking.vehicleData.vehicle_model}was rejected!
            Browse our other listings and book a new car for those days.</P>`,
            senderName: booking?.ownerName,
            senderId: booking?.vehicleOwnerId,
            buttonName: 'Browse Car',
        }
    } ,
    HOST_EXTENDED_ACCEPT_BOOKING(user: any,booking:any) {
        return {
            userId: user.id,
            title: 'Tarlen Carshare',
            type: 'Booking Rejected',
            message: `<P>${user?.displayName},</P>
            <P>Your Extension Request for ${booking?.ownerName}’s ${booking?.vehicleData?.vehicle_year} ${booking?.vehicleData?.vehicle_make} ${booking.vehicleData.vehicle_model} has been accepted! Enjoy your trip.</P>`,
            senderName: booking?.ownerName,
            senderId: booking?.vehicleOwnerId,
            buttonName: 'Browse Car',
        }
    } ,
    HOST_REQUEST_EXTENDED_BOOKING(user: any,booking:any) {
        return {
            userId: booking?.vehicleOwnerId,
            title: 'Tarlen Carshare',
            type: 'Extended Booking Request',
            message: `Your guest wants to extend their trip, please view extension request and respond.`,
            senderName: user?.displayName,
            senderId: booking?.id,
            buttonName: 'View Request',
        }
    } ,
    HOST_NOTIFY_FOR_CONFIRM_BOOKING(vehicleOwner: any,booking:any) {
        return {
            userId: vehicleOwner?.id,
            title: 'Tarlen Carshare',
            type: 'Payment Sucessful',
            message: `${vehicleOwner?.displayName},Your car has been booked. Arrange drop-off with your Guest and start earning. Booking confirmation number  ${booking?.id}`,
            senderName: ADMIN_NAME,
            senderId: ADMIN_ID,
            buttonName: 'View Trip',
        }
    } ,
    ADMIN_NOTIFY_FOR_CONFIRM_BOOKING(user: any,booking:any) {
        return {
            userId: ADMIN_ID,
            title: 'Tarlen Carshare',
            type: 'Payment Sucessful',
            message: `Booking confirmation ${booking?.id}`,
            senderName: user?.displayName,
            senderId: user?.id,
           
        }
    } ,
    GUEST_SEND_BOOKING_REQUEST(user: any,booking:any) {
        return {
            userId: booking?.vehicleOwnerId,
            title: 'Tarlen Carshare',
            type: 'Booking Request',
            message: `You have received a booking request from ${user?.displayName}. Please review and respond immediately.`,
            senderName: user?.displayName,
            senderId: user?.id,
        }
    } ,
    ADMIN_NOTIFY_FOR_BOOKING_REQUEST(user: any,booking:any) {
        return {
            userId: ADMIN_ID,
            title: 'Tarlen Carshare',
            type: 'Booking Request',
            message: `A ${user?.displayName} sent a booking request for ${booking?.ownerName} ${booking?.vehicleData?.vehicle_year} ${booking?.vehicleData?.vehicle_make} ${booking?.vehicleData?.vehicle_model}.`,
            senderName: user?.displayName,
            senderId: user?.id,
        }
    } ,
    HOST_CANCEL_BOOKING_TRIP(user: any,booking:any) {
        return {
            userId: user?.id,
            title: 'Tarlen Carshare',
            type: 'Booking Request',
            message: `${user?.displayName}, Your trip has been cancelled. View trip to see your booking details.`,
            senderName: user?.displayName,
            senderId: booking?.vehicleOwnerId,
        }
    } ,
    GUEST_CANCEL_BOOKING_TRIP(user: any,booking:any) {
        return {
            userId: booking?.vehicleOwnerId,
            title: 'Tarlen Carshare',
            type: 'Booking Request',
            message: `${user?.displayName}, Your trip has been cancelled. View trip to see your booking details.`,
            senderName: user?.displayName,
            senderId: user?.id,
        }
    } ,
    ADMIN_CANCEL_BOOKING_TRIP(booking:any) {
        return {
            userId: ADMIN_ID,
            title: 'Tarlen Carshare',
            type: 'Booking Request',
            message: `Tarlen admin, Your trip has been cancelled. View trip to see your booking details.`,
            senderName: booking?.ownerName,
            senderId: booking?.vehicleOwnerId,
        }
    } ,
    ADMIN_CANCEL_BOOKING_TRIP_GUEST(user:any) {
        return {
            userId: ADMIN_ID,
            title: 'Tarlen Carshare',
            type: 'Booking Request',
            message: `Tarlen admin, Your trip has been cancelled. View trip to see your booking details.`,
            senderName: user?.displayName,
            senderId: user?.id,
        }
    } ,
    GUEST_VERIFICATION(user:any) {
        return {
            userId: user?.id,
            title: 'Tarlen Carshare',
            type: 'Booking Request',
            message: `${user?.displayName}, Thanks for completing your verification. You can now rent and drive cars on Tarlen.`,
            senderName: ADMIN_NAME,
            senderId: ADMIN_ID,
        }
    } ,
    ADMIN_NOTIFICATION_GUEST_VERIFICATION(user:any) {
        return {
            userId: ADMIN_ID,
            title: 'Tarlen Carshare',
            type: 'Booking Request',
            message: `${user?.displayName}, has just completed verification.`,
            senderName: user?.displayName,
            senderId: user?.id,
        }
    } ,
    ADMIN_NOTIFICATION_GUEST_CheckIn(user:any) {
        return {
            userId: ADMIN_ID,
            title: 'Tarlen Carshare',
            type: 'Booking Request',
            message: `A guest has just checked in to their trip ${user?.id}`,
            senderName: user?.userName,
            senderId: user?.userId,
        }
    } ,
    ADMIN_NOTIFICATION_GUEST_CheckOUT(user:any) {
        return {
            userId: ADMIN_ID,
            title: 'Tarlen Carshare',
            type: 'Booking Request',
            message: `A guest has just checked out and ended their trip ${user?.id}`,
            senderName: user?.userName,
            senderId: user?.userId,
        }
    } ,
    ADMIN_NOTIFICATION_HOST_CheckIn(user:any) {
        return {
            userId: ADMIN_ID,
            title: 'Tarlen Carshare',
            type: 'Booking Request',
            message: `A host has just checked in to their trip ${user?.id}`,
            senderName: user?.ownerName,
            senderId: user?.ownerEmail,
        }
    } ,
    ADMIN_NOTIFICATION_HOST_CheckOUT(user:any) {
        return {
            userId: ADMIN_ID,
            title: 'Tarlen Carshare',
            type: 'Booking Request',
            message: `A host has just checked out and ended their trip  ${user?.id}`,
            senderName: user?.ownerName,
            senderId: user?.ownerEmail,
        }
    } ,
    GUEST_UNSUCESSFUL_VERIFICATION(user:any) {
        return {
            userId: user?.id,
            title: 'Tarlen Carshare',
            type: 'Booking Request',
            message: `${user?.displayName}, Your verification was unsuccessful. Please try again or get in contact with support.`,
            senderName: ADMIN_NAME,
            senderId: ADMIN_ID,
        }
    } ,
    HOST_REQUEST_FOR_REIBURSEMENT(user:any,booking:any) {
        return {
            userId: user.id,
            title: 'Tarlen Carshare',
            type: 'Booking reibursement',
            message: `<P>Hi ${user?.displayName},</P>
            <p>You have a payment request on your recent trip. Please navigate to the trip to view and respond to request.<p>
            <p>Alternatively, contact support at tripsupport@tarlen.co.za to learn more about this request.</p>`,
            senderName: booking?.ownerName,
            senderId: booking?.vehicleOwnerId,
        }
    } ,
    HOST_REJECT_FOR_REIBURSEMENT(user:any,booking:any) {
        return {
            userId: booking?.vehicleOwnerId,
            title: 'Tarlen Carshare',
            type: 'Booking reibursement',
            message: `Hi ${booking?.ownerName},
            Your recent changes to your listing has been rejected. Our support start will be reaching out soon.`,
            senderName: user?.displayName,
            senderId: user?.id,
        }
    } ,
    HOST_ACCEPT_FOR_REIBURSEMENT(user:any,booking:any) {
        return {
            userId: booking?.vehicleOwnerId,
            title: 'Tarlen Carshare',
            type: 'Booking reibursement',
            message: `<p>Hi ${booking?.ownerName},</p>
                    <p>Your payment request has been accepted by your Guest. Your trip payment will be disbursed soon.</p>
                     <p>   If you have any inquiries, please contact support at tripsupport@tarlen.co.za.</p>`,
            senderName: user?.displayName,
            senderId: user?.id,
        }
    } ,
    HOST_ESCALATED_FOR_REIBURSEMENT(user:any,booking:any) {
        return {
            userId: booking?.email,
            title: 'Tarlen Carshare',
            type: 'Booking reibursement',
            message: `<p>Hi ${booking?.userName},</p>
                    <p>Your payment request has been accepted by your Guest. Your trip payment will be disbursed soon.</p>
                     <p>   If you have any inquiries, please contact support at tripsupport@tarlen.co.za.</p>`,
            senderName: user?.displayName,
            senderId: user?.id,
        }
    } ,
    HOST_REVIEW(user:any,booking:any) {
        return {
            userId: booking?.vehicleOwnerId,
            title: 'Tarlen Carshare',
            type: 'Booking reibursement',
            message: `Hi ${booking?.ownerName},
            You’ve just gotten a review. Go to your profile to see review.`,
            senderName: user?.displayName,
            senderId: user?.id,
        }
    } ,
    GUEST_REVIEW(user:any,booking:any) {
        return {
            userId: user?.id,
            title: 'Tarlen Carshare',
            type: 'Booking reibursement',
            message: `Hi ${user?.displayName},
            You’ve just gotten a review. Go to your profile to see review.`,
            senderName: booking?.ownerName,
            senderId: booking?.vehicleOwnerId,
        }
    } ,
}