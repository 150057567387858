import { createAction, props } from '@ngrx/store';
import { Booking, BookVehicleDTO, Vehicle } from '@tarlen/shared';
import { OptionalErrorActionProps } from '@tarlen/angular';

// #region ReadBooking
export const ReqReadBooking = createAction('[Booking] ReqReadBooking');
export const ReadBookingSuccess = createAction(
  '[ReqReadBooking] ReadBookingSuccess',
  props<{
    booking: Booking;
    vehicle: Vehicle;
  }>()
);
export const ReadBookingFail = createAction(
  '[ReqReadBooking] ReadBookingFail',
  OptionalErrorActionProps
);
// #endregion

// #region CreateBooking
export const ReqCreateBooking = createAction(
  '[Booking] ReqCreateBooking',
  props<{ vehicleBooking: BookVehicleDTO }>()
);
export const CreateBookingSuccess = createAction(
  '[ReqCreateBooking] CreateBookingSuccess',
  props<{
    booking: Booking;
    vehicle: Vehicle;
  }>()
);
export const CreateBookingFail = createAction(
  '[ReqCreateBooking] CreateBookingFail',
  OptionalErrorActionProps
);
// #endregion

// #region CancelBooking
export const ReqCancelBooking = createAction(
  '[Booking] ReqCancelBooking',
  props<{ booking: Booking }>()
);
export const CancelBookingSuccess = createAction(
  '[ReqCancelBooking] CancelBookingSuccess',
  props<{
    booking: Booking;
  }>()
);
export const CancelBookingFail = createAction(
  '[ReqCancelBooking] CancelBookingFail',
  OptionalErrorActionProps
);
// #endregion

// #region Store Booking
export const ReqStoreBooking = createAction(
  '[Booking] [NF] ReqStoreBooking',
  props<{ storedBooking: BookVehicleDTO }>()
);
export const ReqClearStoredBooking = createAction(
  '[Booking] [NF] ReqClearStoredBooking'
);
// #endregion

// #region CreateBooking
export const ReqUpdateBooking = createAction(
  '[Booking] ReqUpdateBooking',
  props<any>()
);
export const UpdateBookingSuccess = createAction(
  '[ReqUpdateBooking] UpdateBookingSuccess',
  props<any>()
);
export const UpdateBookingFail = createAction(
  '[ReqUpdateBooking] UpdateBookingFail',
  OptionalErrorActionProps
);
// #endregion
