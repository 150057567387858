import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalPopupComponent } from '../modal-popup/modal-popup.component';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

const routes: Routes = [
  {
    path: '',
    component: ModalPopupComponent,
  },
];

@NgModule({
  declarations: [ModalPopupComponent],
  imports: [CommonModule,
  RouterModule.forChild(routes),
  IonicModule],

})
export class ModalPopupModule {}
