<div
  class="loading-effect-opacity"
  *ngIf="
    !(showHeader && showFooter) && (loaderService?.isloaderEnabled | async)
  "
>
  <ion-spinner name="lines" color="primary"></ion-spinner>
</div>

<ion-tabs *ngIf="showFooter; else noLayout">
  <ion-header *ngIf="showHeader">
    <ion-toolbar>
      <app-global-header></app-global-header>
    </ion-toolbar>
    <ion-progress-bar
      type="indeterminate"
      *ngIf="loaderService?.isloaderEnabled | async"
    ></ion-progress-bar>
  </ion-header>

  <ion-tab-bar class="footer" slot="bottom">
    <app-global-footer></app-global-footer>
  </ion-tab-bar>
</ion-tabs>

<ng-template #noLayout>
  <ion-header *ngIf="showHeader">
    <ion-toolbar>
      <app-global-header></app-global-header>
    </ion-toolbar>
  </ion-header>
  <ion-content
    [ngClass]="{
      'calc-height-ios': showHeader || showFooter ? iosView : false,
      'calc-height-android': showHeader || showFooter ? !iosView : false
    }"
  >
    <ion-router-outlet></ion-router-outlet>
  </ion-content>
</ng-template>
