import { Vehicle } from '@tarlen/shared';
import {
  geohashQueryBounds,
  GeohashRange,
  distanceBetween,
} from 'geofire-common';

export const DEFAULT_QUERY_RADIUS_IN_KM = 100;
export const DEFAULT_QUERY_RADIUS_IN_M = DEFAULT_QUERY_RADIUS_IN_KM * 1000;

export interface BoundVehicles {
  bound: GeohashRange;
  vehicles: Vehicle[];
}

export class GeohashQueryCache {
  private lat = 0;
  private long = 0;
  private queryBounds: GeohashRange[] = [];
  private boundVehicleMap = new Map<GeohashRange, Vehicle>();

  reset() {
    this.lat = 0;
    this.long = 0;
    this.queryBounds = [];
    this.boundVehicleMap = new Map();
  }

  getQueryBounds(lat: number, long: number) {
    if (this.lat === lat && this.long === long) {
      return this.queryBounds;
    }

    this.lat = lat;
    this.long = long;
    this.queryBounds = geohashQueryBounds(
      [lat, long],
      DEFAULT_QUERY_RADIUS_IN_M
    );
    return this.queryBounds;
  }

  getCachedVehicle(bound: GeohashRange) {
    return this.boundVehicleMap.get(bound);
  }

  setCachedVehicle(bound: GeohashRange, vehicle: Vehicle) {
    return this.boundVehicleMap.set(bound, vehicle);
  }

  filterBoundVehicles(boundVehicles: BoundVehicles[]) {
    const filteredVehiclesDictionary: Record<string, Vehicle> = {};
    for (let i = 0; i < boundVehicles.length; i++) {
      const { bound, vehicles } = boundVehicles[i];

      const lastVehicle = vehicles[vehicles.length - 1];
      this.setCachedVehicle(bound, lastVehicle);

      for (let j = 0; j < vehicles.length; j++) {
        const vehicle = vehicles[j];

        const distanceInKm = distanceBetween(
          [vehicle.lat, vehicle.long],
          [this.lat, this.long]
        );

        const distanceInM = distanceInKm * 1000;
        const isOutsideOfRadius = distanceInM > DEFAULT_QUERY_RADIUS_IN_M;

        if (isOutsideOfRadius) {
          continue;
        }

        filteredVehiclesDictionary[vehicle.id] = vehicle;
      }
    }

    return Object.values(filteredVehiclesDictionary);
  }
}
