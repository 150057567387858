import { Component, OnInit, Renderer2, NgZone } from '@angular/core';
import { ErrorService } from './core/services/error.service';
import { AuthService, SplashScreenService } from '@tarlen/angular';
import { filter } from 'rxjs';
import { MessagingService } from './core/services/messaging.service';
import { Capacitor } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { ModalController, Platform } from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
import { BookingService } from './core/services/booking.service';
import { LoaderService } from './core/services/loader.service';
import { SplashScreen } from '@capacitor/splash-screen';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Storage } from '@ionic/storage-angular';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  shouldShowSplashScreen$ = this.SplashScreenService.shouldShowSplashScreen$;
  message: any = null;
  iosView: boolean;
  showHeader = false;
  showFooter = false;

  constructor(
    private SplashScreenService: SplashScreenService,
    private ErrorService: ErrorService,
    private messagingService: MessagingService,
    private renderer: Renderer2,
    private modalController: ModalController,
    private router: Router,
    private bookingService: BookingService,
    private zone: NgZone,
    public loaderService: LoaderService,
    private authService: AuthService,
    private storage: Storage
  ) {
    this.ErrorService.listenToNGRXErrors();
    this.reauthorizeUserOnStartup();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.checkRoutes(event);
      });
    this.initDeeplink();
  }

  ngOnInit(): void {
    this.storage.create();
    this.checkRoutes(this.router);
    this.message = this.messagingService.initPushMessaging();
    if (Capacitor.getPlatform() === 'ios') {
      this.iosView = true;
      this.renderer.addClass(document.body, 'ios');
    }
    this.eventListener();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleNavigation();
        this.initializeApp();
      }
    });
    document.addEventListener('selectstart', function (e) {
      e.preventDefault();
    });
  }

  reauthorizeUserOnStartup() {
    this.SplashScreenService.hide();
  }

  eventListener() {
    App.addListener('backButton', async ({ canGoBack }) => {
      if (canGoBack) {
        if (await this.modalController.getTop()) {
          this.modalController.dismiss();
        } else {
          window.history.back();
        }
      } else {
        App.exitApp();
      }
    });
  }

  async handleNavigation(): Promise<void> {
    if (this.shouldDeleteBooking()) {
      const bookingId: string | null = localStorage.getItem('bookingId'); // Get booking ID from local storage
      if (bookingId) {
        try {
          const documentSnapshot: any = await this.bookingService
            .getById(bookingId)
            .toPromise();
          if (documentSnapshot.exists) {
            const bookingData = documentSnapshot.data();
            if (bookingData?.bookingApproved == 7) {
              this.bookingService
                .deleteBookingById(bookingId)
                .then(() => {
                  localStorage.removeItem('bookingId');
                })
                .catch((error) => {
                  console.error(
                    `Error deleting booking with ID ${bookingId}:`,
                    error
                  );
                });
            }
          }
        } catch (error) {
          console.error(`Error deleting booking with ID ${bookingId}:`, error);
        }
      }
    }
  }

  private shouldDeleteBooking(): boolean {
    const commonPattern = /^(\/car-booking|\/chat)/;
    return !commonPattern.test(this.router.url);
  }

  checkRoutes(event: any) {
    // Header Conditions
    if (
      event.url === '/' ||
      event.url.includes('/auth') ||
      event.url.includes('/chat/messages/') ||
      event.url.includes('/host-chat/messages') ||
      event.url.includes('/legal') ||
      event.url === '/settings/personal-details' ||
      event.url === '/host-settings/host-personal-details' ||
      event.url.includes('/host-trip/my-trips/view') ||
      event.url.includes('trip-ended') ||
      event.url.includes('trip-success') ||
      event.url.includes('trip-started') ||
      event.url.includes('/host-trip/my-trips/request-sent/')
    ) {
      this.showHeader = false;
    } else this.showHeader = true;

    // Footer Conditions
    if (
      event.url.includes('/home') ||
      event.url === '/all-vehicles' ||
      event.url === '/favorites' ||
      event.url === '/trip/upcoming-trip' ||
      event.url === '/chat/list' ||
      event.url === '/settings' ||
      event.url === '/home-host' ||
      event.url === '/all-cars-host' ||
      event.url === '/host-trip/my-trips' ||
      event.url === '/host-chat/list' ||
      event.url === '/host-settings' ||
      event.url === '/vehicle-request-list' ||
      event.url.includes('/trip/trip-in-progress') ||
      event.url.includes('trip-ended') ||
      event.url.includes('trip-success') ||
      event.url.includes('trip-started') ||
      event.url === '/host-trip/my-trips/trip-cancelled'||
      event.url === '/host-add-car/receive-modal'||
      event.url === '/success-popup'||
      event.url.includes('/host-trip/my-trips/request-sent/') ||
      event.url.includes('accept-request') ||
      event.url.includes('payment-success-fail')
    ) {
      this.showFooter = true;
    } else this.showFooter = false;
  }

  initDeeplink() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = 'tarlen-dev.web.app';
        const pathArray = event.url.split(domain);
        const appPath = pathArray.pop();
        if (appPath) {
          this.router.navigateByUrl(appPath);
        }
      });
    });
  }

  async initializeApp() {
    await SplashScreen.hide();
  }
}
