import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  BaseEntity,
  FirestoreCollections,
  arrayBufferToBase64,
} from '@tarlen/shared';
import { Observable, map, mergeMap, of, take, tap, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';

class PathToUrlCache {
  private cache: Record<string, string> = {};
  private getCacheKey(url: string, asBase64: boolean): string {
    return `${url}&asBase64=${asBase64}`;
  }
  get(url: string, asBase64 = false) {
    const cacheKey = this.getCacheKey(url, asBase64);
    return this.cache[cacheKey];
  }
  set(url: string, asBase64 = false, value: string) {
    const cacheKey = this.getCacheKey(url, asBase64);
    return (this.cache[cacheKey] = value);
  }
}

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  pathToUrlCache = new PathToUrlCache();

  constructor(
    private AngularFirestore: AngularFirestore,
    private AngularFireStorage: AngularFireStorage,
    private HttpClient: HttpClient
  ) {}

  Scope<T extends Object>(firestoreCollection: FirestoreCollections) {
    const Collection = this.AngularFirestore.collection<T>(firestoreCollection);

    return {
      ref: Collection.ref,
      Create: this.ScopeCreate<T>(Collection),
      Read: this.ScopeRead<T>(Collection),
      Update: this.ScopeUpdate<T>(Collection),
      UpdateOne: this.ScopeUpdateOne<T>(Collection),
      Delete: this.ScopeDelete(Collection),
      List: this.ScopeList(Collection),
      Pagination: this.ScopePaginationList(Collection)
    };
  }

  private ScopeCreate<T extends Object>(
    collection: AngularFirestoreCollection
  ) {
    return (entity: Omit<T, 'id' | 'createdAt' | 'updatedAt'>, id?: string) =>
      this.Create<T>(collection, entity, id);
  }
  Create<T extends Object>(
    collection: AngularFirestoreCollection,
    entity: Omit<T, 'id' | 'createdAt' | 'updatedAt'> & Partial<BaseEntity>,
    id?: string
  ): Promise<T & BaseEntity> {
    const document = collection.doc(id);
    const documentRef = document.ref;

    const entityWithId = {
      ...entity,
      id: documentRef.id,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    };
    return documentRef
      .set(entityWithId, {
        merge: false,
      })
      .then(() => entityWithId as T & BaseEntity);
  }

  private ScopeRead<T extends Object>(collection: AngularFirestoreCollection) {
    return (id: string) => this.Read<T>(collection, id);
  }
  Read<T extends Object>(collection: AngularFirestoreCollection, id: string) {
    return collection
      .doc(id)
      .get()
      .pipe(
        take(1),
        map((doc) => doc.data() as T)
      );
  }

  private ScopeList<T extends Object>(collection: AngularFirestoreCollection) {
    return () => this.List<T>(collection);
  }
  List<T extends Object>(collection: any) {
    return collection.get().pipe(
      mergeMap((doc: any) => of(doc.docs.map((m: any) => m.data())))
    );
  }

  private ScopePaginationList<T extends Object>(collection: AngularFirestoreCollection) {
    return (searchKey?: string, searchQuery?: string) => this.PaginationList<T>(collection, searchKey, searchQuery);
  }
  PaginationList<T extends Object>(collection: any, searchKey?: string, searchQuery?: string): Observable<any> {
    let query = collection.ref;
    // Apply search filters if searchQuery is provided
    if (searchKey) {
      query = query.where(searchKey, '==', searchQuery);
    } else {
      query = query.orderBy('updatedAt');
    }
    return of(query).pipe(mergeMap((m: any) => m.get()))
    .pipe(mergeMap((doc: any) => of(doc.docs.map((m: any) => m.data()))));
  }

  private ScopeUpdate<T extends Object>(
    collection: AngularFirestoreCollection
  ) {
    return (id: string, entity: T) => this.Update<T>(collection, id, entity);
  }
  Update<T extends Object>(
    collection: AngularFirestoreCollection,
    id: string,
    entity: T
  ): Promise<T> {
    const documentRef = collection.doc(id);

    const {
      id: fieldId,
      createdAt,
      updatedAt,
      ...fieldsThatCanBeUpdated
    } = entity as T & BaseEntity;

    const updatedEntity = {
      ...fieldsThatCanBeUpdated,
      updatedAt: Date.now(),
    };

    return documentRef.update(updatedEntity).then(() => {
      return {
        ...entity,
        ...updatedEntity,
      };
    });
  }

  private ScopeUpdateOne<T extends Object>(
    collection: AngularFirestoreCollection
  ) {
    return (id: string, entity: T) => this.Update<T>(collection, id, entity);
  }
  UpdateOne<T extends Object>(
    collection: AngularFirestoreCollection,
    id: string,
    entity: T
  ): Promise<T> {
    const documentRef = collection.doc(id);

    const {
      id: fieldId,
      createdAt,
      updatedAt,
      ...fieldsThatCanBeUpdated
    } = entity as T & BaseEntity;

    const updatedEntity = {
      ...fieldsThatCanBeUpdated,
      updatedAt: Date.now(),
    };
    return documentRef.set(updatedEntity, { merge: true }).then(() => {
      return {
        ...entity,
        ...updatedEntity,
      };
    });
  }

  private ScopeDelete(collection: AngularFirestoreCollection) {
    return (id: string) => this.Delete(collection, id);
  }
  Delete(collection: AngularFirestoreCollection, id: string): Promise<boolean> {
    return collection
      .doc(id)
      .delete()
      .then((writeResult) => {
        return true;
      });
  }

  urlToBase64(url: string) {
    return this.HttpClient.get(url, {
      responseType: 'arraybuffer',
    }).pipe(map((arrayBuffer) => arrayBufferToBase64(arrayBuffer)));
  }

  pathToUrl(url: string, asBase64: boolean = false): Observable<string> {
    const cachedUrl = this.pathToUrlCache.get(url, asBase64);
    if (cachedUrl) {
      return of(cachedUrl);
    }

    const ref = this.AngularFireStorage.ref(url);

    const downloadUrl$ = ref.getDownloadURL();

    if (!asBase64) {
      return downloadUrl$.pipe(
        tap((downloadUrl) => {
          this.pathToUrlCache.set(url, asBase64, downloadUrl);
        }),
        take(1)
      );
    }

    return downloadUrl$.pipe(
      mergeMap((url) => this.urlToBase64(url)),
      tap((base64) => {
        this.pathToUrlCache.set(url, asBase64, base64);
      }),
      take(1)
    );
  }
}
