import { createEntityAdapter, EntityState, Dictionary } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PaginationOptions, Vehicle } from '@tarlen/shared';
import * as actions from './vehicle.actions';
import { userActions } from '../user';

export const vehicleFeatureKey = 'vehicle';

export const vehicleEntityAdapter = createEntityAdapter<Vehicle>();

export class State implements EntityState<Vehicle> {
  ids: string[] = [];
  entities: Dictionary<Vehicle> = {};
  selectedVehicle: Vehicle;
  bookedDates: number[] = [];
  lastPaginationOptions = new PaginationOptions();
}

export const initialState = new State();

export const reducer = createReducer(
  initialState,
  on(actions.ReadVehicleListingsSuccess, (state, { type, ...payload }) => {
    return vehicleEntityAdapter.setAll(payload.items, {
      ...state,
      lastPaginationOptions: {
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
        hasNextPage: payload.hasNextPage,
      },
    });
  }),
  on(actions.LoadMoreVehicleListingsSuccess, (state, { type, ...payload }) => {
    return vehicleEntityAdapter.upsertMany(payload.items, {
      ...state,
      lastPaginationOptions: {
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
        hasNextPage: payload.hasNextPage,
      },
    });
  }),
  on(actions.ReadVehicleSuccess, (state, { type, ...payload }) => {
    return {
      ...state,
      selectedVehicle: payload.selectedVehicle,
    };
  }),
  on(actions.ReadVehicleBookedDatesSuccess, (state, { type, ...payload }) => {
    return {
      ...state,
      bookedDates: payload.bookedDates,
    };
  }),
  on(userActions.ReqLogout, (state, { type, ...payload }) => {
    return new State();
  })
);
