import { Component, EventEmitter, Output } from '@angular/core';
import { Subscription, filter } from 'rxjs';
import { ROUTES } from '../../../core/consts/routing/routes';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { Location } from '@angular/common';
import { ShardService } from '../../../core/services/shared.service';
import { NotificationService } from '../../../core/services/notification.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserService } from '@tarlen/angular';

@Component({
  selector: 'app-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss'],
})
export class GlobalHeaderComponent {
  destroyed$ = new EventEmitter<void>();
  @Output() dataEvent = new EventEmitter<string>();
  firstWordName: string;
  profileData: any;
  showBackButton: boolean = true;
  headerText: any = [];
  notificationCount: number = 0;
  showUser: boolean = false;
  settings: boolean;
  subscription: Subscription;
  datasubscription: Subscription;
  urls: any;
  history: boolean = false;
  headerData: any;
  role: string | null;
  constructor(
    private router: Router,
    public location: Location,
    public sharedService: ShardService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private auth: AngularFireAuth,
    private userService: UserService
  ) {}

  nevigateHistoryList() {
    this.router.navigateByUrl('/settings/report/history-list');
  }

  ngOnInit() {
    this.role = localStorage.getItem('loggedAsRole');
    if (this.activatedRoute.snapshot.firstChild)
      this.checkHeaderText(this.activatedRoute.snapshot);
    this.checkBackButtonVisibility(this.router);
    this.getUserData();
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.checkBackButtonVisibility(event);
      });

    this.router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        this.checkHeaderText(event.state.root.children[0]);
      }
    });
  }

  checkHeaderText(data: any) {
    this.headerData = data;
    if (
      data?.firstChild?.data['headerText'] ||
      data?.queryParams['headerText']
    ) {
      if (data?.queryParams['bookingApproved'] == 0) {
        this.headerText = 'Booking request';
        return;
      } else if (
        data?.queryParams['isRejected'] == 'false' &&
        data?.queryParams['bookingApproved'] == 3
      ) {
        this.headerText = 'Cancelled Trip';
        return;
      } else if (
        data?.queryParams['isRejected'] == 'true' &&
        data?.queryParams['bookingApproved'] == 3
      ) {
        this.headerText = 'Rejected Booking';
        return;
      } else {
        this.headerText =
          data?.firstChild?.data['headerText'] ||
          data?.queryParams['headerText'];
      }
    } else if (data.children?.length) {
      this.checkHeaderText(data?.children[0]);
    } else {
      this.headerText = '';
    }
  }

  getUserData() {
    this.auth.user.subscribe({
      next: async (res: any) => {
        this.datasubscription = this.userService
          .readAndResponse(res?.uid)
          .subscribe({
            next: (user: any) => {
              if (user) {
                this.profileData = user;
                this.firstWordName =
                  this.profileData?.firstName[0] ||
                  this.profileData.displayName[0];
                this.notificationService
                  .getNotificationsForUser(user.id)
                  .subscribe((notifications) => {
                    this.notificationCount =
                      notifications.unreadNotificationCount;
                  });
                this.checkBackButtonVisibility(this.router);
              }
            },
          });
      },
    });
  }

  isImage(url: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve(true);
      };
      img.onerror = () => {
        resolve(false);
      };
      img.src = url;
    });
  }

  verify() {
    this.router.navigateByUrl(ROUTES.Verification._Path);
    const data = 'ffh';
    this.dataEvent.emit(data);
  }

  goBack() {
    if (this.location.path() === '/host-add-car/receive-modal') {
      this.router.navigate([`home-host`]);
    } else if (this.location.path() === 'verification/two-steps-verification') {
      this.router.navigate([`verification`]);
    } else if (this.location.path() === '/success-popup') {
      this.router.navigate([`all-cars-host`]);
    } else {
      this.location.back();
    }
  }

  redirectedToNotification() {
    this.router.navigateByUrl(ROUTES.Notification._Path);
  }

  checkBackButtonVisibility(event: any) {
    this.urls = event;
    if (
      event.url === '/home' ||
      event.url === '/favorites' ||
      event.url === '/trip/upcoming-trip' ||
      event.url === '/chat/list' ||
      event.url === '/home-host' ||
      event.url === '/all-cars-host' ||
      event.url === '/host-trip/my-trips' ||
      event.url === '/host-chat/list' ||
      event.url.includes('/accept-request') ||
      event.url.includes('/decline-request') ||
      event.url === '/host-add-car/receive-modal' ||
      event.url === '/host-trip/my-trips/trip-cancelled' ||
      event.url.includes('payment-success-fail') ||
      (event.url.includes('/verification') &&
        this.profileData?.isPersonalVerified &&
        this.profileData?.documentUpload) ||
      (event.url.includes('/host-booking-request') &&
        this.headerData.queryParams.isExtended) ||
      event.url === '/success-popup'
    ) {
      // Hide Back Button and show user with notification
      this.showBackButton = false;
      this.showUser = true;
      this.history = false;
    } else if (
      event.url === '/settings' ||
      event.url === '/host-settings' ||
      event.url === '/host-settings'
    ) {
      // Hide Back Button and hide user profile and notification
      this.showBackButton = false;
      this.showUser = false;
      this.history = false;
    } else if (
      event.url.includes('settings/help-and-support') ||
      event.url === '/settings/contact' ||
      event.url.includes('report') ||
      event.url.includes('/host-settings/host-help-and-support') ||
      event.url.includes('delete_user') ||
      event.url.includes('host-trip/end-trip') ||
      event.url.includes('/settings/review') ||
      event.url.includes('/host-settings/host-review') ||
      event.url.includes('/guest-profile') ||
      event.url === '/host-settings/host-request-management' ||
      event.url.includes('/host-settings/host-request-management-view/') ||
      event.url === '/settings/guest-request-management-view' ||
      event.url.includes('/settings/guest-request-management-view') ||
      event.url.includes('host-settings/earn-more-details')
    ) {
      // Show Back Button and hide user profile and notification
      this.showBackButton = true;
      this.showUser = false;
      this.history = false;
      if (
        event.url.includes('settings/report?type') ||
        event.url.includes('host-settings/host-report-an-issue?type')
      ) {
        this.history = true;
      }
    } else if (event.url === '/create-new-password') {
      this.showBackButton = false;
      this.showUser = false;
      this.history = false;
    } else {
      if (this.headerData) {
        if (event.url.includes('/host-booking-request')) {
          this.headerText = this.headerData?.queryParams?.extendedTrip
            ? 'Extension Requests'
            : 'Requests';
        }
      }
      this.showBackButton = true;
      this.showUser = true;
      this.history = false;
    }
  }

  getDataOfPages() {
    this.headerText = this.activatedRoute.snapshot.data['headerText'] || '';
  }

  historyList() {
    if (this.profileData?.role != 'Host') {
      this.router.navigateByUrl('settings/report/history-list');
    } else {
      this.router.navigateByUrl(
        'host-settings/host-report-an-issue/host-history-list'
      );
    }
  }

  ngOnDestroy() {
    this.destroyed$.emit();
    this.subscription.unsubscribe();
    this.datasubscription.unsubscribe();
  }
}
