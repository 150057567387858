import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { CloudFunction } from '@tarlen/shared';
import { map, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CloudFunctionService {
  constructor(private AngularFireFunctions: AngularFireFunctions) {}

  call<T extends CloudFunction<never, any>>(
    cloudFunction: T,
    data?: T['DATA']
  ): Observable<T['RESPONSE']['data']>;
  call<T extends CloudFunction<any, any>>(
    cloudFunction: T,
    data: T['DATA']
  ): Observable<T['RESPONSE']['data']>;
  call<T extends CloudFunction<any, any>>(
    cloudFunction: T,
    data: T['DATA'] extends never ? undefined : T['DATA']
  ) {
    const callableCloudFunction = this.AngularFireFunctions.httpsCallable(
      cloudFunction.name
    );
    return callableCloudFunction(data).pipe(
      take(1),
      map((response: T['RESPONSE']) => {
        if (response.error) {
          throw response.error;
        }
        return response.data as T['RESPONSE']['data'];
      })
    );
  }
}
