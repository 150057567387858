import { LocationDetailsDTO } from '../models';

export const CAPE_TOWN_LOCATION: LocationDetailsDTO = {
  lat: -33.918861,
  long: 18.4233,
  geohash: undefined as any,
  googlePlaceId: 'ChIJ1-4miA9QzB0Rh6ooKPzhf2g',
  road: '' as any,
  suburb: '' as any,
  town: 'Cape Town',
};

export const EMPTY_LOCATION: LocationDetailsDTO = {
  lat: 0,
  long: 0,
  geohash: undefined as any,
  googlePlaceId: '',
  road: '',
  suburb: '',
  town: '',
};
