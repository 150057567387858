import { createReducer, on } from '@ngrx/store';
import { DriverVerification, User } from '@tarlen/shared';
import * as actions from './user.actions';

export const userFeatureKey = 'user';

export class State {
  user: User;
  driverVerification: DriverVerification;
}

export const initialState = new State();

export const reducer = createReducer(
  initialState,
  on(
    actions.SignInWithEmailAndPasswordSuccess,
    actions.ReauthorizeUserSuccess,
    actions.UpdatePersonalDetailsSuccess,
    actions.VerifyDriverSuccess,
    actions.SignInWithGoogleSuccess,
    (state, { type, ...payload }) => {
      return {
        ...state,
        user: payload.user,
      };
    }
  ),
  on(
    actions.ReadDriverVerificationDetailsSuccess,
    actions.VerifyDriverSuccess,
    (state, { type, ...payload }) => {
      return {
        ...state,
        driverVerification: payload.driverVerification,
      };
    }
  ),
  on(actions.LogoutSuccess, (state, { type, ...payload }) => {
    return new State();
  })
);
