import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';

export const selectUserState = createFeatureSelector<fromUser.State>(
  fromUser.userFeatureKey
);

export const selectUser = createSelector(
  selectUserState,
  (state) => state.user
);
export const selectUserQueryableLocation = createSelector(
  selectUserState,
  (state) => {
    if (!state.user) {
      return undefined;
    }

    return {
      lat: state.user.lat,
      long: state.user.long,
      geohash: state.user.geohash,
    };
  }
);

export const selectUserId = createSelector(
  selectUserState,
  (state) => state.user?.id
);

export const selectDriverVerification = createSelector(
  selectUserState,
  ({ driverVerification }) => driverVerification
);
