<ion-content>
    <div class="h-90 d-flex justify-content-center align-items-center">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-center">
          <img src="../../../../assets/images/message.png" class="l-img" />
        </div>
        <div class="mb-5 text-center">
          <h1  class="heading">
            Listing Updated!<span></span>
          </h1>
          <h2 class="g-h2 g-text-white l-text"></h2>
          <p class="g-body-1-regular g-text-disabled-grey l-text" >
            Your listing has been updated. Your edits will be live soon
          </p>
        </div>
        <div class="d-flex justify-content-center btnRoute">
          <div class="closeBtn" >
            <ion-button  expand="block" (click)="home()">
              Home
            </ion-button>
          </div> 
          <br />
        </div>
      </div>
    </div>
  </ion-content>