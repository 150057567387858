import { createAction, props } from '@ngrx/store';
import {
  PaginatedData,
  QueryableLocationDetails,
  Vehicle,
} from '@tarlen/shared';
import { OptionalErrorActionProps } from '@tarlen/angular';

// #region ReadVehicleListings
export const ReqReadVehicleListings = createAction(
  '[Vehicle] ReqReadVehicleListings',
  props<{ userLocation: QueryableLocationDetails }>()
);
export const ReadVehicleListingsSuccess = createAction(
  '[ReqReadVehicleListings] ReadVehicleListingsSuccess',
  props<PaginatedData<Vehicle>>()
);
export const ReadVehicleListingsFail = createAction(
  '[ReqReadVehicleListings] ReadVehicleListingsFail',
  OptionalErrorActionProps
);
// #endregion

// #region LoadMoreVehicleListings
export const ReqLoadMoreVehicleListings = createAction(
  '[Vehicle] ReqLoadMoreVehicleListings',
  props<{ userLocation: QueryableLocationDetails }>()
);
export const LoadMoreVehicleListingsSuccess = createAction(
  '[ReqLoadMoreVehicleListings] LoadMoreVehicleListingsSuccess',
  props<PaginatedData<Vehicle>>()
);
export const LoadMoreVehicleListingsFail = createAction(
  '[ReqLoadMoreVehicleListings] LoadMoreVehicleListingsFail',
  OptionalErrorActionProps
);
// #endregion
// #region ReadVehicle
export const ReqReadVehicle = createAction(
  '[Vehicle] ReqReadVehicle',
  props<{ vehicleId: string }>()
);
export const ReadVehicleSuccess = createAction(
  '[ReqReadVehicle] ReadVehicleSuccess',
  props<{ selectedVehicle: Vehicle }>()
);
export const ReadVehicleFail = createAction(
  '[ReqReadVehicle] ReadVehicleFail',
  OptionalErrorActionProps
);
// #endregion
// #region ReadVehicleBookedDates
export const ReqReadVehicleBookedDates = createAction(
  '[Vehicle] ReqReadVehicleBookedDates',
  props<{ vehicleId: string }>()
);
export const ReadVehicleBookedDatesSuccess = createAction(
  '[ReqReadVehicleBookedDates] ReadVehicleBookedDatesSuccess',
  props<{ bookedDates: number[] }>()
);
export const ReadVehicleBookedDatesFail = createAction(
  '[ReqReadVehicleBookedDates] ReadVehicleBookedDatesFail',
  OptionalErrorActionProps
);
// #endregion
