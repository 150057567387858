import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTES } from './core/consts/routing';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/compat/auth-guard';
import { GuestGuard } from './core/guards/guest-route.guard';
import { HostGuard } from './core/guards/host-route.guard';
import { AuthGuard } from './core/guards/auth-guard';

const redirectToLoginPage = () =>
  redirectUnauthorizedTo(`${ROUTES.Auth.LogIn._Path}?data=Guest`);

const routes: Routes = [
  {
    path: 'guest-profile/:id',
    canActivate: [HostGuard],
    loadChildren: () =>
      import('./features/guest-profile/guest-profile.module').then(
        (m) => m.GuestProfileModule
      ),
  },
  {
    path: 'all-cars-host',
    canActivate: [HostGuard],
    data: {
      // authGuardPipe: redirectUnauthorizedToAuth,
      headerText: 'Car Listing',
    },
    loadChildren: () =>
      import('./features/all-vehice-host/all-vehice-host.module').then(
        (m) => m.AllVehiceHostModule
      ),
  },
  {
    path: 'edit-cars-host/:id',
    canActivate: [HostGuard],
    data: {
      headerText: 'Edit Listing',
    },
    loadChildren: () =>
      import('./features/edit-vehicle-host/edit-vehicle-host.module').then(
        (m) => m.EditVehiceHostModule
      ),
  },
  // EditVehiceHostModule
  // {
  //   path: ROUTES.Auth._RelativePath,
  //   loadChildren: () =>
  //     import('./features/auth/auth.module').then(
  //       ({ AuthModule }) => AuthModule
  //     ),
  // },

  {
    path: ROUTES.Auth._RelativePath,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/auth/auth.module').then(
        ({ AuthModule }) => AuthModule
      ),
  },

  {
    path: ROUTES.HomeHost._RelativePath,
    canActivate: [HostGuard],
    data: {
      // authGuardPipe: redirectUnauthorizedToAuth,
    },
    loadChildren: () =>
      import('./features/home-host/home-host.module').then(
        ({ HomeHostModule }) => HomeHostModule
      ),
  },
  {
    path: 'host-trip',
    canActivate: [HostGuard],
    data: {
      // authGuardPipe: redirectUnauthorizedToAuth,
    },
    loadChildren: () =>
      import('./features/host-trip/host-trip.module').then(
        (m) => m.HostTripModule
      ),
  },
  {
    path: ROUTES.HostBenefits._RelativePath,
    canActivate: [HostGuard],
    data: {
      // authGuardPipe: redirectUnauthorizedToAuth,
    },
    loadChildren: () =>
      import('./features/host-benefits/host-benefits.module').then(
        ({ HostBenefitsModule }) => HostBenefitsModule
      ),
  },

  {
    path: ROUTES.HostSettings._RelativePath,
    canActivate: [HostGuard],
    data: {
      // authGuardPipe: redirectUnauthorizedToAuth,
    },
    loadChildren: () =>
      import('./features/host-settings/host-settings.module').then(
        ({ HostSettingsModule }) => HostSettingsModule
      ),
  },

  {
    path: 'host-chat',
    canActivate: [HostGuard],
    data: {
      // authGuardPipe: redirectUnauthorizedToAuth,
      headerText: 'Inbox',
    },
    loadChildren: () =>
      import('./features/host-chat/host-chat.module').then(
        ({ HostChatModule }) => HostChatModule
      ),
  },

  {
    path: 'vehicle-request-list',
    canActivate: [HostGuard],
    data: {
      headerText: 'Booking Request',
    },
    loadChildren: () =>
      import(
        './features/vehcile-request-list/vehicle-request-list.module'
      ).then(({ VehcileRequestListModule }) => VehcileRequestListModule),
  },
  {
    path: ROUTES.HostSettings.HostReportAnIssue._Path,
    canActivate: [HostGuard],

    loadChildren: () =>
      import(
        './features/host-settings/components/host-report-an-issue/host-report-an-issue.module'
      ).then(({ HostReportAnIssueModule }) => HostReportAnIssueModule),
  },

  {
    path: ROUTES.HostSettings.HostEarning._Path,
    canActivate: [HostGuard],
    data: {
      headerText: 'My Earning',
    },
    loadChildren: () =>
      import(
        './features/host-settings/components/host-earning/host-earning.module'
      ).then(({ HostEarningModule }) => HostEarningModule),
  },
  {
    path: ROUTES.Settings.Contact._Path,

    loadChildren: () =>
      import('./features/contact/contact.module').then(
        ({ ContactModule }) => ContactModule
      ),
  },
  {
    path: ROUTES.Legal._RelativePath,
    loadChildren: () =>
      import('./features/legal/legal.module').then(
        ({ LegalModule }) => LegalModule
      ),
  },
  {
    path: 'host-add-car',
    canActivate: [HostGuard],
    loadChildren: () =>
      import('./features/host-add-car/host-add-car.module').then(
        (m) => m.HostAddCarModule
      ),
  },
  // {
  //   path: 'host-after-add-car',
  //   canActivate: [ HostGuard],
  //   loadChildren: () =>
  //     import('.//features/host-after-add-car/host-after-dd-car.module').then(
  //       (m) => m.HostAfterAddCarModule
  //     ),
  // },
  {
    path: 'host-booking-request/:id',
    canActivate: [HostGuard],
    data: {
      headerText: 'Request',
    },
    loadChildren: () =>
      import(
        './features/host-booking-request/host-booking-request.module'
      ).then((m) => m.HostBookingRequestModule),
  },
  {
    path: 'host-chat',
    canActivate: [HostGuard],
    loadChildren: () =>
      import('./features/host-chat/host-chat.module').then(
        (m) => m.HostChatModule
      ),
  },

  /////Guest/////////////

  {
    path: ROUTES.AllVehicles._RelativePath,
    data: {
      headerText: 'All Cars',
    },
    loadChildren: () =>
      import('./features/all-vehicles/all-vehicles.module').then(
        ({ AllVehiclesModule }) => AllVehiclesModule
      ),
  },
  {
    path: ROUTES.CarDetail._RelativePath,
    data: {
      headerText: 'Car Details',
    },
    loadChildren: () =>
      import('./features/car-detail/car-detail.module').then(
        ({ CarDetailModule }) => CarDetailModule
      ),
  },

  {
    path: ROUTES.Verification._RelativePath,
    canActivate: [GuestGuard],
    data: {
      headerText: 'Verification',
    },
    loadChildren: () =>
      import('./features/verification/verification.module').then(
        ({ VerificationModule }) => VerificationModule
      ),
  },
  {
    path: ROUTES.Home._RelativePath,
    canActivate: [GuestGuard],
    loadChildren: () =>
      import('./features/home/home.module').then(
        ({ HomeModule }) => HomeModule
      ),
  },
  {
    path: 'favorites',
    canActivate: [GuestGuard],
    data: {
      headerText: 'My Favourites',
    },
    loadChildren: () =>
      import('./features/favorites/favorites.module').then(
        (m) => m.FavoritesModule
      ),
  },

  {
    path: 'trip',
    canActivate: [GuestGuard],
    loadChildren: () =>
      import('./features/trip/trip.module').then((m) => m.TripModule),
  },
  {
    path: ROUTES.Settings._RelativePath,
    canActivate: [GuestGuard],
    loadChildren: () =>
      import('./features/settings/settings.module').then(
        ({ SettingsModule }) => SettingsModule
      ),
  },

  {
    path: ROUTES.ChatApp._Path,
    canActivate: [GuestGuard],
    data: {
      headerText: 'Inbox',
    },
    loadChildren: () =>
      import('./features/chatModule/chat.module').then(
        ({ ChatModule }) => ChatModule
      ),
  },
  {
    path: 'car-booking',
    canActivate: [GuestGuard],
    loadChildren: () =>
      import('./features/car-booking/car-booking.module').then(
        (m) => m.CarBookingModule
      ),
  },
  {
    path: ROUTES.Settings.Report._Path,
    canActivate: [GuestGuard],
    data: {
      headerText: 'Report an issue',
    },
    loadChildren: () =>
      import('./features/settings/components/report-guest/report.module').then(
        ({ ReportModule }) => ReportModule
      ),
  },

  //shared//
  {
    path: ROUTES.Notification._Path,
    canActivate: [AngularFireAuthGuard],
    data: {
      headerText: 'Notifications',
      authGuardPipe: redirectToLoginPage,
    },
    loadChildren: () =>
      import('./features/notification/notification.module').then(
        ({ NotificationModule }) => NotificationModule
      ),
  },
  {
    path: 'delete_user',
    canActivate: [AngularFireAuthGuard],
    data: {
      headerText: 'Delete My Account',
      authGuardPipe: redirectToLoginPage,
    },
    loadChildren: () =>
      import('./features/delete-user-confirm/delete-user-confirm.module').then(
        ({ DeleteUserConfirmModule }) => DeleteUserConfirmModule
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTES.Auth._RelativePath,
  },
  {
    path: 'success-popup',
    canActivate: [HostGuard],
    data: {
      headerText: 'Car Listing',
    },
    loadChildren: () =>
      import('./features/modal-popup/modal-popup.module').then(
        ({ ModalPopupModule }) => ModalPopupModule
      ),
  },
  {
    path: 'create-new-password',
    data: {
      headerText: 'New Password',
    },
    loadChildren: () =>
      import('./features/create-new-password/create-new-password.module').then(
        ({ CreateNewPasswordModule }) => CreateNewPasswordModule
      ),
  },
  { path: '**', redirectTo: ROUTES.Auth._RelativePath },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
