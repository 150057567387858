import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {  UserService as UserServiceFromShared } from '@tarlen/angular';
import { ModalController } from '@ionic/angular';
import { UserService } from '../../../core/services/user.service';
import { LoaderService } from '../../../core/services/loader.service';
@Component({
  selector: 'app-mission-statement',
  templateUrl: './mission-statement.component.html',
  styleUrls: ['./mission-statement.component.scss'],
})
export class MissionStatementComponent implements OnInit {
 userRole: any
  user: any;

  constructor(
    private auth: AngularFireAuth,
    private _user: UserServiceFromShared,
    private userService: UserService,
    private ModalController: ModalController,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.auth.user.subscribe({
      next: (res: any) => {
        if (res?.uid) {
          this.userById(res?.uid);
        }
      },
    });
  }
  async userById(ownerid: string) {
    this._user.readAndResponse(ownerid).subscribe({
      next: (user: any) => {
        this.user = user;
        this.userRole = this.user.role
      },
    });
  }
  updateDetails() {
    this.loaderService.showLoader()
    let paylod: any = {
      firstNamePopUp: true,
    };
    this.userService.updateUser(this.user.id, paylod).subscribe((res:any)=>{
      location.reload()
      this.loaderService.hideLoader()
    });
  }
}
