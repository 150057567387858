import { createReducer, on } from '@ngrx/store';
import { Booking, BookVehicleDTO, Vehicle } from '@tarlen/shared';
import * as actions from './booking.actions';
import { userActions } from '../user';

export const bookingFeatureKey = 'booking';

export class State {
  currentBooking: Booking;
  bookedVehicle: Vehicle;
  storedBooking: BookVehicleDTO | undefined;
}

export const initialState = new State();

export const reducer = createReducer(
  initialState,
  on(actions.CancelBookingSuccess, (state, { type, ...payload }) => {
    return {
      ...state,
      currentBooking: payload.booking,
    };
  }),
  on(
    actions.ReadBookingSuccess,
    actions.CreateBookingSuccess,
    (state, { type, ...payload }) => {
      return {
        ...state,
        currentBooking: payload.booking,
        bookedVehicle: payload.vehicle,
      };
    }
  ),
  on(actions.ReqStoreBooking, (state, { storedBooking }) => {
    return {
      ...state,
      storedBooking,
    };
  }),
  on(actions.ReqClearStoredBooking, (state) => {
    return {
      ...state,
      storedBooking: undefined,
    };
  }),
  on(userActions.ReqLogout, (state, { type, ...payload }) => {
    return new State();
  })
);
