import { createAction, props } from '@ngrx/store';
import {
  DriverVerification,
  User,
  UserRegistrationDTO,
  VerifyDriverDTO,
} from '@tarlen/shared';
import { OptionalErrorActionProps } from '@tarlen/angular';
import { ROUTES } from '../../consts/routing';

// #region CreateUser
export const ReqCreateUser = createAction(
  '[User] ReqCreateUser',
  props<UserRegistrationDTO>()
);
export const CreateUserSuccess = createAction(
  '[ReqCreateUser] CreateUserSuccess',
  props<{ user: User }>()
);
export const CreateUserFail = createAction(
  '[ReqCreateUser] CreateUserFail',
  OptionalErrorActionProps
);
// #endregion

// #region SignInWithEmailAndPassword
export const ReqSignInWithEmailAndPassword = createAction(
  '[User] ReqSignInWithEmailAndPassword',
  props<{ email: string; password: string; newlyCreatedUser?: User; deviceToken?:any,role?:any }>()
);
export const SignInWithGoogleSuccess = createAction(
  '[SignInWithGoogleSuccess] SignInWithGoogleSuccess',
  props<{ user: User }>()
);
export const SignInWithEmailAndPasswordSuccess = createAction(
  '[ReqSignInWithEmailAndPassword] SignInWithEmailAndPasswordSuccess',
  props<{ user: User }>()
);
export const SignInWithEmailAndPasswordFail = createAction(
  '[ReqSignInWithEmailAndPassword] SignInWithEmailAndPasswordFail',
  OptionalErrorActionProps
);
// #endregion

// #region ReauthorizeUser
export const ReqReauthorizeUser = createAction('[User] ReqReauthorizeUser');
export const ReauthorizeUserSuccess = createAction(
  '[ReqReauthorizeUser] ReauthorizeUserSuccess',
  props<{ user: User }>()
);
export const ReauthorizeUserFail = createAction(
  '[ReqReauthorizeUser] ReauthorizeUserFail',
  OptionalErrorActionProps
);
// #endregion

// #region Logout
export const ReqLogout = createAction(
  '[User] ReqLogout',
  (route: string = ROUTES.Auth.Landing._Path) => ({ route })
);
export const LogoutSuccess = createAction('[ReqLogout] LogoutSuccess');
export const LogoutFail = createAction(
  '[ReqLogout] LogoutFail',
  OptionalErrorActionProps
);
// #endregion

// #region UpdatePersonalDetails
export const ReqUpdatePersonalDetails = createAction(
  '[User] ReqUpdatePersonalDetails',
  props<{ user: User }>()
);
export const UpdatePersonalDetailsSuccess = createAction(
  '[ReqUpdatePersonalDetails] UpdatePersonalDetailsSuccess',
  props<{ user: User }>()
);
export const UpdatePersonalDetailsFail = createAction(
  '[ReqUpdatePersonalDetails] UpdatePersonalDetailsFail',
  OptionalErrorActionProps
);
// #endregion

// #region DeleteUser
export const ReqDeleteUser = createAction('[User] ReqDeleteUser');
export const DeleteUserSuccess = createAction(
  '[ReqDeleteUser] DeleteUserSuccess'
);
export const DeleteUserFail = createAction(
  '[ReqDeleteUser] DeleteUserFail',
  OptionalErrorActionProps
);
// #endregion

// #region VerifyDriver
export const ReqVerifyDriver = createAction(
  '[User] ReqVerifyDriver',
  props<VerifyDriverDTO>()
);
export const VerifyDriverSuccess = createAction(
  '[ReqVerifyDriver] VerifyDriverSuccess',
  props<{ user: User; driverVerification: DriverVerification }>()
);
export const VerifyDriverFail = createAction(
  '[ReqVerifyDriver] VerifyDriverFail',
  OptionalErrorActionProps
);
// #endregion

// #region ReadDriverVerificationDetails
export const ReqReadDriverVerificationDetails = createAction(
  '[User] ReqReadDriverVerificationDetails'
);
export const ReadDriverVerificationDetailsSuccess = createAction(
  '[ReqReadDriverVerificationDetails] ReadDriverVerificationDetailsSuccess',
  props<{ driverVerification: DriverVerification }>()
);
export const ReadDriverVerificationDetailsFail = createAction(
  '[ReqReadDriverVerificationDetails] ReadDriverVerificationDetailsFail',
  OptionalErrorActionProps
);
// #endregion
///