import { Injectable } from '@angular/core';
import {
  DriverVerification,
  FirestoreCollections,
  UpdateDriverVerificationCloudFunction,
  VerificationStatus,
  isBase64,
} from '@tarlen/shared';
import { FirestoreService } from './firestore.service';
import { CloudFunctionService } from './cloud-function.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DriverVerificationService {
  private Collection = this.FirestoreService.Scope<DriverVerification>(
    FirestoreCollections.DRIVER_VERIFICATIONS
  );

  constructor(
    private FirestoreService: FirestoreService,
    private CloudFunctionService: CloudFunctionService
  ) {}



  read(id: string) {
    return this.Collection.Read(id);
  }

  readByDriverId(driverId: string) {
    return this.Collection.ref
      .where('userId', '==', driverId)
      .get()
      .then(
        (query) => query.docs.map((doc) => doc.data() as DriverVerification)[0]
      );
  }

  update(driverVerification: DriverVerification) {
    const driverVerificationContainsBase64 = isBase64(
      driverVerification.driverLicense
    );
    if (driverVerificationContainsBase64) {
      return firstValueFrom(
        this.CloudFunctionService.call(
          UpdateDriverVerificationCloudFunction,
          driverVerification
        )
      );
    }

    return this.Collection.Update(driverVerification.id, driverVerification);
  }

  readByStatus(verificationStatuses: VerificationStatus[]) {
    return this.Collection.ref
      .where('status', 'in', verificationStatuses)
      .get()
      .then((query) =>
        query.docs.map((doc) => doc.data() as DriverVerification)
      );
  }
}
