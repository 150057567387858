import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  forkJoin,
  from,
  merge,
  mergeMap,
  Observable,
  of,
  Subject,
  switchMap,
  throwError,
} from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private updateTrigger = new BehaviorSubject<any>(null);

  constructor(
    private firestore: AngularFirestore,
    private auth: AngularFireAuth,
    private toasterService: ToastService
  ) {}

  async getUserById(userId: string): Promise<any> {
    try {
      const userDoc: any = await this.firestore
        .collection('users')
        .doc(userId)
        .get()
        .toPromise();
      if (userDoc.exists) {
        return userDoc.data();
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  async addRatingToHost(
    hostId: string,
    userId: string,
    rating: number
  ): Promise<void> {
    const vehicleRatingsCollection = this.firestore.collection('host_ratings');
    const vehicleRatingDoc = vehicleRatingsCollection.doc(hostId);

    try {
      const vehicleRatingSnapshot: any = await vehicleRatingDoc
        .get()
        .toPromise();

      if (vehicleRatingSnapshot.exists) {
        // Vehicle rating document exists, update it with the new rating
        const existingRatings = vehicleRatingSnapshot.data()?.ratings || [];
        existingRatings.push({ userId, rating, timestamp: Date.now() });

        return vehicleRatingDoc.update({ ratings: existingRatings });
      } else {
        // Vehicle rating document does not exist, create it with the new rating
        const newRatingDoc = {
          ratings: [{ userId, rating, timestamp: Date.now() }],
        };

        return vehicleRatingDoc.set(newRatingDoc);
      }
    } catch (error) {
      throw error;
    }
  }

  updateUser(userId: string, updatedUserData: any): Observable<void> {
    const userDocRef = this.firestore.collection('users').doc(userId);
    // Use the .update() method to update the user document
    return new Observable<void>((observer) => {
      userDocRef
        .update(updatedUserData)
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  async updateUserCard(userId: string, newCard: any): Promise<void> {
    const userRef = this.firestore.collection('users').doc(userId);
    const doc: any = await userRef.get().toPromise();
    if (doc.exists) {
      const userData = doc.data() as any;
      const cards = userData.cards || [];
      const existingCardIndex = cards.findIndex(
        (card: any) => card.card_number === newCard.card_number
      );
      if (existingCardIndex >= 0) {
        cards[existingCardIndex] = newCard;
      } else {
        cards.push(newCard);
      }
      return userRef.update({ cards });
    } else {
      throw new Error('User not found');
    }
  }

  async deleteUserCard(userId: string, cardNumber: string): Promise<void> {
    const userRef: any = this.firestore.collection('users').doc(userId);
    const doc = await userRef.get().toPromise();
    if (doc.exists) {
      const userData = doc.data() as any;
      const cards = userData.cards || [];
      const cardIndexToDelete = cards.findIndex(
        (card: any) => card.card_number === cardNumber
      );
      if (cardIndexToDelete >= 0) {
        cards.splice(cardIndexToDelete, 1);
        return userRef.update({ cards });
      } else {
        throw new Error('Card not found');
      }
    } else {
      throw new Error('User not found');
    }
  }

  updateUserLog(payload: any) {
    this.auth.user.subscribe({
      next: async (res: any) => {
        const user = await this.getUserById(res?.uid);
        if (user) {
          let logs: any = {
            logs: [
              ...(user.logs || []), // Copy the existing logs (if they exist)
              payload,
            ],
          };

          this.updateUser(user.id, logs).subscribe(
            () => {},
            (error) => {
            }
          );
        }
      },
    });
  }

  // An observable to trigger the update
  get updateTrigger$() {
    return this.updateTrigger.asObservable();
  }

  // Emit an update trigger
  triggerUpdate() {
    this.updateTrigger.next(null);
  }

  async deleteUserData(
    userId: string,
    deletionRequestPayload: any
  ): Promise<Observable<any>> {
    let isAnyTripStarted = false;
    const bookings: any[] = [];

    // Check guest bookings
    const guestBookingData = await this.firestore
      .collection('bookings')
      .ref.where('userId', '==', userId)
      .get();

    guestBookingData.forEach((doc) => {
      const booking: any = doc.data();
      bookings.push(booking);

      if (booking.isGuestTripStart || booking.isHostTripStart) {
        isAnyTripStarted = true;
      }
    });

    // Check host bookings
    const hostBookingData = await this.firestore
      .collection('bookings')
      .ref.where('vehicleOwnerId', '==', userId)
      .get();

    hostBookingData.forEach((doc) => {
      const booking: any = doc.data();
      bookings.push(booking);

      if (booking.isGuestTripStart || booking.isHostTripStart) {
        isAnyTripStarted = true;
      }
    });

    // If at least one trip has started, return an error
    if (isAnyTripStarted) {
      return throwError('Your trip is in progress. Cannot delete the account.');
    }

    // Delete user-related data from 'store_vehicles' collection
    const vehicles$ = this.firestore
      .collection('store_vehicles', (ref) => ref.where('ownerid', '==', userId))
      .get()
      .pipe(
        switchMap((snapshot) => {
          const deletePromises: Promise<void>[] = [];
          snapshot.forEach((doc) => {
            // Check if there are documents before attempting to delete
            if (doc.exists) {
              deletePromises.push(doc.ref.delete());
            }
          });
          return forkJoin(deletePromises);
        })
      );

    // Delete user-related data from 'chat1' collection
    const chat1$ = this.firestore
      .collection('chatrooms', (ref) =>
        ref.where('users', 'array-contains', userId)
      )
      .get()
      .pipe(
        switchMap((snapshot) =>
          forkJoin(snapshot.docs.map((doc) => from(doc.ref.delete())))
        )
      );

    // Delete user-related data from 'chat2' collection
    const chat2$ = this.firestore
      .collection('chatRoomsNames', (ref) =>
        ref.where('users', 'array-contains', userId)
      )
      .get()
      .pipe(
        switchMap((snapshot) =>
          forkJoin(snapshot.docs.map((doc) => from(doc.ref.delete())))
        )
      );

    // Delete the authenticated user
    const deleteAuthUser$ = from(this.auth.currentUser).pipe(
      switchMap((user) => {
        if (user) {
          return from(user.delete());
        } else {
          return EMPTY; // No user to delete, return an empty observable
        }
      })
    );

    await this.deleteUser(userId);

    const deletionRequestDocRef = await this.firestore
      .collection('deletionRequests')
      .add(
        deletionRequestPayload,
      );

    const autoGeneratedId = deletionRequestDocRef.id;    
    // Return an observable indicating success
    return merge(vehicles$, chat1$, chat2$, deleteAuthUser$).pipe(
      mergeMap(() =>
        from(
          autoGeneratedId
            ? this.firestore
                .collection('deletionRequests')
                .doc(autoGeneratedId)
                .update({ id: autoGeneratedId })
            : Promise.resolve()
        )
      ),
      catchError((error) =>
        throwError(`Error deleting user data: ${error.message}`)
      ),
      mergeMap(() => of('User data deleted successfully'))
    );
  }

  deleteUser(userId: any): Promise<void> {
    const documentRef = this.firestore.collection('users').doc(userId);
    return documentRef.delete();
  }
}
