
export function constructISODate(date?: number) {
  const fullDate = new Date(date ?? Date.now());
  const year = fullDate.getFullYear();
  const month = fullDate.getMonth() + 1;
  const day = fullDate.getDate();
  return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day
    }`;
}

