import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ICONS } from '../../../core/consts/assets';
import { Router } from '@angular/router';
import { ModalService } from '../../../core/services/modal.service';
export interface StandardSuccessModalProps<T = any> {
  imgPath?: ICONS;
  title?: string;
  body?: string;
  heading?: string;
  subHeading?: string;
  data?: T;
  buttonText?: string;
  buttonText1?: any;
  redirectTo?: any;
  cancle?: any;
  booking?: any;  
}

@Component({
  selector: 'app-standard-success-modal',
  templateUrl: './standard-success-modal.component.html',
  styleUrls: ['./standard-success-modal.component.scss'],
})
export class StandardSuccessModalComponent<T = any> {
  @Input() imgPath?: string;
  @Input() title?: string;
  @Input() body?: string;
  @Input() data?: T;
  @Input() heading?: string;
  @Input() buttonText: string;
  @Input() buttonText1: any;
  @Input() redirectTo?: any;
  @Input() cancle?: any;
  @Input() booking?: any;
  @Input() subHeading?: any;
  @Input() isChatRequest?: boolean = false;
  constructor(
    private ModalController: ModalController,
    private router: Router,
    private ModalService: ModalService
  ) {}

  close() {
    if (this.buttonText == 'Home') {
      this.router.navigateByUrl('/home');
    } else if (this.buttonText == 'Verify Yourself') {
      this.router.navigateByUrl('/verification');
    } else if (this.buttonText == 'Browse cars') {
      this.router.navigateByUrl('/home');
    } else if (this.buttonText == 'Done') {
      this.router.navigateByUrl('/home-host');
    }
    this.ModalController.dismiss(this.data);
  }
  redirectChat() {}
}
