import { BookingCloudFunctions } from './booking';
import { DriverVerificationCloudFunctions } from './driver-verification';
import { UserCloudFunctions } from './user';
import { VehicleVerificationCloudFunctions } from './vehicle-verification';

export * from './booking';
export * from './driver-verification';
export * from './user';
export * from './vehicle-verification';

export type CloudFunctions =
  | BookingCloudFunctions
  | DriverVerificationCloudFunctions
  | UserCloudFunctions
  | VehicleVerificationCloudFunctions

