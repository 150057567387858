export const CHATAPP = 'chat';

export enum chatApp {
    List = 'list',
    Messages = 'messages'
  }
  
  export enum chatRouteParams {
    ChatId = 'chat-id'
  }
  