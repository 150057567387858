import { NgModule } from '@angular/core';
import { LoadImageUrlPipe } from './load-image.pipe';
import { FirebaseAppModule } from '@angular/fire/app';

@NgModule({
  declarations: [LoadImageUrlPipe],
  imports: [FirebaseAppModule],
  exports: [LoadImageUrlPipe],
})
export class LoadImageUrlModule {}
