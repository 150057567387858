import { Injectable } from '@angular/core';
import {
  CreateUserCloudFunction,
  DeleteUserCloudFunction,
  FirestoreCollections,
  HelpAndSupportEmailData,

  User,
} from '@tarlen/shared';
import { forkJoin, from, map, Observable, of, switchMap, take } from 'rxjs';
import { AuthService } from '.';
import { CloudFunctionService } from './cloud-function.service';
import { FirestoreService } from './firestore.service';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Geolocation } from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private Collection = this.FirestoreService.Scope<User>(
    FirestoreCollections.USERS
  );

  constructor(
    private FirestoreService: FirestoreService,
    private CloudFunctionService: CloudFunctionService,
    private authService: AuthService,
    private firestore: AngularFirestore
  ) { }

  list(): Observable<[]> {
    return this.Collection.List();
  }

  create(user: typeof CreateUserCloudFunction.DATA) {
    return this.CloudFunctionService.call(CreateUserCloudFunction, user);
  }

  read(id: string) {
    return this.Collection.Read(id).pipe(
      map((user) => {
        if (!user) {
          throw new Error("User doesn't exist");
        }
        return user;
      })
    );
  }

  readAndResponse(id: string) {
    return this.firestore.collection(FirestoreCollections.USERS).doc(id).valueChanges()
  }

  getUser(id: string) {
    return this.Collection.Read(id).pipe(
      map((user) => {
        if (!user) {
          // throw new Error("User doesn't exist");
          return of(null);
        }
        return user;
      })
    );
  }

  updateUser(userId: string, updatedUserData: any): Observable<void> {
    const userDocRef = this.firestore.collection(FirestoreCollections.USERS).doc(userId);
    // Check if updatedUserData.deviceToken is not an empty string
    if (updatedUserData.deviceToken !== '') {
      // Fetch users with the specified deviceToken
      return from(
        this.firestore
          .collection(FirestoreCollections.USERS, (ref) => ref.where('deviceToken', '==', updatedUserData.deviceToken))
          .get()
      ).pipe(
        switchMap((snapshot) => {
          if (snapshot.docs.length === 0) {
            return of(null); // Return an observable with a null value
          } else {
            // Clear deviceToken for all users
            const observables = snapshot.docs.map((doc) => {
              return from(doc.ref.update({ deviceToken: '' }));
            });
            return forkJoin(observables).pipe(take(1));
          }
        }),
        switchMap(() => {
          return this.updateDocument(userDocRef, updatedUserData);
        })
      );
    } else {
      return this.updateDocument(userDocRef, updatedUserData);
    }
  }

  updateDocument(docRef: any, data: any): Observable<void> {
    return new Observable<void>((observer) => {
      docRef
        .update(data)
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch((error: any) => {
          observer.error(error);
        });
    });
  }



  update(user: User) {
    return this.Collection.Update(user.id, user);
  }

  async setNewUser(user: any, role: any) {
    try {
      const userDoc: any = await this.firestore
        .collection(FirestoreCollections.USERS)
        .doc(user.uid)
        .get()
        .toPromise();
      if (userDoc.exists) {
        return userDoc.data();
      } else {
        const data: any = {
          id: user.uid,
          email: user.email,
          displayName: '',
          photoURL: user.photoURL,
          firstName: '',
          lastName: '',
          role: role,
          verifiedDriver: 0,
          deviceToken: user.deviceToken,
          logs: user.logs,
          ...(await this.getCurrentLocation()),
        };

        const filteredDataToStorea: any = Object.fromEntries(
          Object.entries(data).filter(([key, value]) => value !== undefined)
        );
        await this.Collection.Create(filteredDataToStorea, user.uid);
        return data;
      }
    } catch (error) {
      return null;
    }
  }

  delete() {
    return this.CloudFunctionService.call(DeleteUserCloudFunction);
  }
  async handleLogout() {
    localStorage.clear();
    sessionStorage.clear();
    this.authService.logout();
    await GoogleAuth.signOut();
    // this.router.navigate(['/auth/landing'])
  }

  async getCurrentLocation() {
    try {
      const coordinates = await Geolocation.getCurrentPosition();
      const lat = coordinates.coords.latitude;
      const long = coordinates.coords.longitude;
      return { lat, long };
    } catch (error) {
      return { lat: -33.918861, long: 18.4233 };
    }
  }
}
