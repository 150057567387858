import { Injectable } from '@angular/core';
import { ToastController, ToastButton } from '@ionic/angular';

export enum ButtonRoles {
  Cancel = 'cancel',
  Confirm = 'confirm',
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  private async dismissTopToast() {
    return this.toastController.getTop().then((top) => top?.dismiss());
  }

  async show(message: string, buttons?: string[], duration: number = 2000) {
    await this.dismissTopToast();

    const toast = await this.toastController.create({
      message,
      buttons,
      duration,
    });
    toast.present();
    return toast;
  }

  async showConfirmation(
    message: string,
    confirmText = 'Yes',
    cancelText = 'No'
  ) {
    const confirmButton: ToastButton = {
      text: confirmText,
      role: ButtonRoles.Confirm,
    };
    const cancelButton: ToastButton = {
      text: cancelText,
      role: ButtonRoles.Cancel,
    };

    await this.dismissTopToast();

    const toast = await this.toastController.create({
      message,
      buttons: [confirmButton, cancelButton],
    });
    toast.present();
    return {
      ...toast,
      onDidDismiss: () =>
        toast.onDidDismiss().then((event) => {
          if (event.role === ButtonRoles.Confirm) {
            return Promise.resolve({
              ...event,
              data: confirmText as any,
            });
          }
          return Promise.resolve({
            ...event,
            data: cancelText,
          });
        }),
    };
  }

  showBasic(message: string) {
    return this.show(message);
  }

  showBasicError(
    message: string = 'Oh no! Something went wrong, please try again.',
    buttonText: string = 'Okay'
  ) {
    return this.show(message, [buttonText], 5000);
  }
}
