import { Injectable } from '@angular/core';
import {
  Booking,
  BookingJobS,
  BookingJobTypes,
  FirestoreCollections,
  UpdateBookingCloudFunction,
} from '@tarlen/shared';
import { CloudFunctionService, FirestoreService } from '@tarlen/angular';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { catchError, concatMap, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DateUtilService } from './date-util.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserService } from './user.service';
import {
  AddTimeWithDate,
  convertDateTime,
  dateFormatter,
  setHoursZero,
} from '../utils/toTimestamp';
import { VehicleService } from './vehicle.service';
import { PeachPaymentService } from './peach-payment.service';
import { environment } from 'apps/mobile/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  user: any;
  private Collection = this.FirestoreService.Scope<Booking>(
    FirestoreCollections.BOOKINGS
  );

  constructor(
    private FirestoreService: FirestoreService,
    private CloudFunctionService: CloudFunctionService,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private http: HttpClient,
    private dateUtilService: DateUtilService,
    private auth: AngularFireAuth,
    private userService: UserService,
    private vehicleService: VehicleService,
    private peachPaymentService:PeachPaymentService
  ) {
    this.auth.user.subscribe({
      next: async (res: any) => {
        const user = await this.userService.getUserById(res?.uid);
        if (user) {
          this.user = user;
        }
      },
    });
  }

  create(vehicleBooking: any): Observable<any> {
    return new Observable((observer) => {
      const attemptCreate = () => {
        const bookingId = this.generateRandomId(6);
        vehicleBooking.id = bookingId;

        this.firestore
          .collection('bookings')
          .doc(bookingId)
          .get()
          .subscribe((docSnapshot) => {
            if (docSnapshot.exists) {
              attemptCreate();
            } else {
              this.firestore
                .collection('bookings')
                .doc(bookingId)
                .set(vehicleBooking)
                .then(() => {
                  observer.next(vehicleBooking);
                  observer.complete();
                })
                .catch((error) => {
                  observer.error(error);
                });
            }
          });
      };

      attemptCreate();
    });
  }

  private generateRandomId(length: number): string {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  read(userId: string) {
    return this.Collection.Read(userId);
  }

  update(booking: Booking) {
    return this.Collection.Update(booking.id, booking);
  }

  getPaymentStatus(bookingId: string): Observable<any> {
    return this.firestore.collection('bookings').doc(bookingId).valueChanges();
  }

  getBooking(userId: string, bookingRequest: any): Observable<any[]> {
    return this.firestore
      .collection('bookings', (ref) =>
        ref
          .where('vehicleOwnerId', '==', userId)
          .where('bookingApproved', '==', bookingRequest)
      )
      .valueChanges();
  }

  getExtendedBooking(userId: string): Observable<any[]> {
    return this.firestore
      .collection('bookings', (ref) =>
        ref.where('vehicleOwnerId', '==', userId).where('extendedTrip', '==', 0)
      )
      .valueChanges();
  }

  getUserBookingById(fieldName: string, userId: string): Observable<any[]> {
    return this.firestore
      .collection('bookings', (ref) => ref.where(fieldName, '==', userId))
      .valueChanges();
  }

  async getBookingDetailById(bookingId: string): Promise<any | null> {
    try {
      const docSnapshot: any = await this.firestore
        .collection('bookings')
        .doc(bookingId)
        .get()
        .toPromise();

      if (docSnapshot.exists) {
        const bookingData = docSnapshot.data();
        bookingData.id = docSnapshot.id;
        return bookingData;
      } else {
        return null;
      }
    } catch (error) {
      console.error(`Error fetching vehicle with ID ${bookingId}:`, error);
      throw error;
    }
  }

  updateVehicleBooking(booking: any): Observable<any> {
    return new Observable((observer) => {
      const documentRef = this.firestore.collection('bookings').doc(booking.id);
      documentRef
        .update(booking)
        .then(() => {
          observer.next('Update successful');
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  updateBooking(vehicleBooking: Booking) {
    return this.CloudFunctionService.call(
      UpdateBookingCloudFunction,
      vehicleBooking
    );
  }

  private formData = new BehaviorSubject<any>({});

  getFormData() {
    return this.formData.asObservable();
  }

  updateFormData(data: any) {
    this.formData.next(data);
  }

  clearFormData() {
    this.formData.next(null);
  }

  async uploadImages(pathName: any, imageFiles: File[]): Promise<string[]> {
    const imageUrls: string[] = [];

    for (const imageFile of imageFiles) {
      const filePath = `${pathName}/${Date.now()}_${imageFile.name}`;
      const ref = this.storage.ref(filePath);

      try {
        const task = this.storage.upload(filePath, imageFile);
        const snapshot = await task;
        const downloadUrl = await snapshot.ref.getDownloadURL();
        imageUrls.push(downloadUrl);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }

    return imageUrls;
  }

  async uploadProfileImage(pathName: any, imageFiles: File): Promise<string[]> {
    const imageUrls: string[] = [];

    const filePath = `${pathName}/${Date.now()}_${imageFiles?.name}`;
    const ref = this.storage.ref(filePath);

    try {
      const task = this.storage.upload(filePath, imageFiles);
      const snapshot = await task;
      const downloadUrl = await snapshot.ref.getDownloadURL();
      imageUrls.push(downloadUrl);
    } catch (error) {
      console.error('Error uploading image:', error);
    }

    return imageUrls;
  }

  getById(documentId: string) {
    const documentRef = this.firestore.collection('bookings').doc(documentId);
    return documentRef.get();
  }

  getInsuranceById(documentId: string) {
    const documentRef = this.firestore
      .collection('insurance_band')
      .doc(documentId);
    return documentRef.get();
  }

  async isBookingConflict(
    userId: string,
    startDate: any,
    endDate: any,
    timeOfCollection: any,
    vehicleId: string,
    tripInProgress: boolean,
    id: string
  ): Promise<boolean> {
    try {
      const userBookingsQuerySnapshot = await this.firestore
        .collection('bookings')
        .ref.where('userId', '==', userId)
        .where('bookingApproved', '==', 1)
        .get();

      const userBookings: any = userBookingsQuerySnapshot.docs.map((doc) =>
        doc.data()
      );
      for (const booking of userBookings) {
        if (
          (this.dateUtilService.convertToOnlyDate(booking?.startDate) <=
            this.dateUtilService.convertToOnlyDate(startDate) &&
            this.dateUtilService.convertToOnlyDate(startDate) <=
              this.dateUtilService.convertToOnlyDate(booking?.endDate)) ||
          (this.dateUtilService.convertToOnlyDate(booking?.startDate) <=
            this.dateUtilService.convertToOnlyDate(endDate) &&
            this.dateUtilService.convertToOnlyDate(endDate) <=
              this.dateUtilService.convertToOnlyDate(booking?.endDate))
        ) {
          let currentStartDate = AddTimeWithDate(startDate, timeOfCollection);
          let bookingEndDate = AddTimeWithDate(
            new Date(booking?.endDate).toLocaleString('en-US', {
              timeZone: 'Africa/Johannesburg',
            }),
            booking?.prefferedTime
          );
          if (booking?.bookingApproved == 1) {
            if (tripInProgress == true) {
              if (id != booking.id) {
                return true;
              }
            } else {
              if (
                currentStartDate.getTime() >=
                  bookingEndDate.getTime() + 60 * 60 * 1000 &&
                this.dateUtilService.convertToOnlyDate(startDate) !=
                  this.dateUtilService.convertToOnlyDate(booking?.endDate)
              ) {
                return false;
              } else {
                return true;
              }
            }
          }
        }
      }
      const vehicleBookingsQuerySnapshot = await this.firestore
        .collection('bookings')
        .ref.where('vehicleId', '==', vehicleId)
        .where('bookingApproved', '==', 1)
        .get();

      const vehicleBookings: any = vehicleBookingsQuerySnapshot.docs.map(
        (doc) => doc.data()
      );

      for (const booking of vehicleBookings) {
        if (
          (this.dateUtilService.convertToOnlyDate(booking?.startDate) <=
            this.dateUtilService.convertToOnlyDate(startDate) &&
            this.dateUtilService.convertToOnlyDate(startDate) <=
              this.dateUtilService.convertToOnlyDate(booking?.endDate)) ||
          (this.dateUtilService.convertToOnlyDate(booking?.startDate) <=
            this.dateUtilService.convertToOnlyDate(endDate) &&
            this.dateUtilService.convertToOnlyDate(endDate) <=
              this.dateUtilService.convertToOnlyDate(booking?.endDate))
        ) {
          let currentStartDate = AddTimeWithDate(startDate, timeOfCollection);
          let bookingEndDate = AddTimeWithDate(
            new Date(booking?.endDate).toLocaleString('en-US', {
              timeZone: 'Africa/Johannesburg',
            }),
            booking?.prefferedTime
          );
          if (booking?.bookingApproved == 1) {
            if (tripInProgress == true) {
              if (id != booking.id) {
                return true;
              }
            } else {
              if (
                currentStartDate.getTime() >=
                  bookingEndDate.getTime() + 60 * 60 * 1000 &&
                this.dateUtilService.convertToOnlyDate(startDate) !=
                  this.dateUtilService.convertToOnlyDate(booking?.endDate)
              ) {
                return false;
              } else {
                return true;
              }
            }
          }
        }
      }
      return false;
    } catch (error) {
      console.error('Error checking booking conflicts:', error);
      throw error;
    }
  }

  async confirmVehicleReservation(
    vehicleId: string,
    userId: string
  ): Promise<boolean | Date[]> {
    try {
      // For getting all the booking by the vehicle
      const vehicleBookingsPromise = this.firestore
        .collection('bookings')
        .ref.where('vehicleId', '==', vehicleId)
        .where('bookingApproved', '==', 1)
        .get();

      // For getting all the booking by the user
      const userBookingsPromise = this.firestore
        .collection('bookings')
        .ref.where('userId', '==', userId)
        .where('bookingApproved', '==', 1)
        .get();

      const [vehicleBookingsQuerySnapshot, userBookingsQuerySnapshot] =
        await Promise.all([vehicleBookingsPromise, userBookingsPromise]);

      const vehicleBookings: any[] = vehicleBookingsQuerySnapshot.docs.map(
        (doc) => {
          const data: any = doc.data();
          return { id: doc.id, ...data };
        }
      );

      const userBookings: any[] = userBookingsQuerySnapshot.docs.map((doc) => {
        const data: any = doc.data();
        return { id: doc.id, ...data };
      });

      const datesInRange: Date[] = [];
      const today = convertDateTime();
      const formattedToday = this.formatDate(today);

      if (vehicleBookings && vehicleBookings.length > 0) {
        const vehicleDatesInRange = await this.processBookings(
          vehicleBookings,
          formattedToday
        );
        datesInRange.push(...vehicleDatesInRange);
      }

      if (userBookings && userBookings.length > 0) {
        const userDatesInRange = await this.processBookings(
          userBookings,
          formattedToday
        );
        datesInRange.push(...userDatesInRange);
      }

      if (datesInRange.length > 0) {
        const uniqueDatesInRange = Array.from(new Set(datesInRange));
        return uniqueDatesInRange;
      }

      return false;
    } catch (error) {
      throw error;
    }
  }

  async processBookings(bookings: any, formattedToday: any) {
    const datesInRange = [];
    for (let booking of bookings) {
      // Check if booking is approved
      if (booking.bookingApproved === 1) {
        const bookingStartDate = new Date(booking?.startDate);
        const bookingEndDate = AddTimeWithDate(
          new Date(booking?.endDate),
          booking?.prefferedTime
        );
        bookingEndDate.setHours(bookingEndDate.getHours() + 2);
        const formattedStartDate = this.formatDate(bookingStartDate);
        const formattedEndDate = this.formatDate(bookingEndDate);

        const dates = await this.getDatesInRange(
          new Date(formattedStartDate),
          new Date(formattedEndDate)
        );

        datesInRange.push(...dates);
      }
    }
    return datesInRange;
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  async getDatesInRange(startDate: Date, endDate: Date): Promise<Date[]> {
    return new Promise((resolve) => {
      const datesInRange: Date[] = [];
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      while (startDate < endDate) {
        datesInRange.push(new Date(startDate));
        startDate.setDate(startDate.getDate() + 1);
      }
      resolve(datesInRange);
    });
  }

  // Function to get bookings and categorize them for the Host
  hostcategorizeBooking(
    bookingStartDate: Date,
    bookingEndDate: Date,
    bookingApproved: number,
    isHostTripStart: boolean,
    isHostTripEnd: boolean,
    id: string
  ): any {
    const currentDate = setHoursZero(new Date());
    if (bookingApproved != 7 && bookingApproved != 5) {
      if (currentDate >= bookingStartDate && currentDate <= bookingEndDate) {
        if (
          isHostTripEnd ||
          (isHostTripStart &&
            (bookingApproved === 3 ||
              bookingApproved === 8 ||
              bookingApproved === 4))
        ) {
          return 'past';
        } else if (
          bookingApproved === 1 ||
          bookingApproved === 0 ||
          bookingApproved === 5
        ) {
          return isHostTripStart ? 'inprogress' : 'upcoming';
        } else {
          return 'past';
        }
      } else if (
        currentDate < bookingStartDate &&
        !isHostTripStart &&
        (bookingApproved === 1 ||
          bookingApproved === 0 ||
          bookingApproved === 5)
      ) {
        return 'upcoming';
      } else {
        return 'past';
      }
    }
  }
  // Function to return all the categorizes data to Host
  getBookingsAndCategorize(vehicleOwnerId?: string): Observable<any> {
    // Define your categorized bookings object
    const categorizedBookings: any = {
      upcoming: [],
      inprogress: [],
      past: [],
    };
    let query: any;
    if (vehicleOwnerId) {
      query = this.firestore.collection('bookings', (ref) =>
        ref.where('vehicleOwnerId', '==', vehicleOwnerId)
      );
    } else {
      // Handle the case where neither userId nor vehicleOwnerId is provided.
      return of(categorizedBookings);
    }

    return query.valueChanges().pipe(
      concatMap((bookings: any[]) => {
        if (bookings.length === 0) {
          return of(categorizedBookings); // Return empty array if there are no bookings
        }
        // Create an array of observables that fetch vehicle data for each booking.
        const observables = bookings.map((booking) => {
          const bookingApproved = booking?.bookingApproved;
          const isHostTripStart = booking?.isHostTripStart;
          const isHostTripEnd = booking?.isHostTripEnd;
          const startDate = new Date(booking.startDate);
          const endDate = new Date(booking.endDate);
          const id = booking?.id;
          const status = this.hostcategorizeBooking(
            setHoursZero(startDate),
            setHoursZero(endDate),
            bookingApproved,
            isHostTripStart,
            isHostTripEnd,
            id
          );
          categorizedBookings[status] = [];
          const vehicleId = booking.vehicleId;
          const fieldNames = [
            'carCoverImage',
            'availability',
            'transmissionType',
            'doors',
            'colors',
            'vehicle_make',
            'vehicle_model',
            'vehicle_year',
            'carSeats',
            'insurance_band',
            'fuelType',
            'powerSteering',
          ];

          return this.getDataByFields(
            'store_vehicles',
            vehicleId,
            fieldNames
          ).pipe(
            map((vehicleData) => {
              // Push the booking into the categorized array
              categorizedBookings[status].push({
                ...booking,
                status,
                vehicleData: vehicleData || null,
              });
            }),
            catchError((error) => {
              console.error(
                `Error fetching data for vehicleId: ${vehicleId}`,
                error
              );
              return of({
                ...booking,
                status,
                vehicleData: null, // Handle the error by setting vehicleData to null
              });
            })
          );
        });
        // Combine all observables into a single observable.
        return forkJoin(observables).pipe(
          map(() => categorizedBookings) // Return the categorized bookings after processing
        );
      })
    );
  }

  // Function to get bookings and categorize them for the Guest
  guestcategorizeBooking(
    bookingStartDate: Date,
    bookingEndDate: Date,
    bookingApproved: number,
    isGuestTripStart: boolean,
    isGuestTripEnd: boolean,
    id: string
  ): any {
    const currentDate = setHoursZero(new Date());
    if (bookingApproved != 7 && bookingApproved != 5) {
      if (currentDate >= bookingStartDate && currentDate <= bookingEndDate) {
        if (
          isGuestTripEnd ||
          (isGuestTripStart &&
            (bookingApproved === 3 ||
              bookingApproved === 8 ||
              bookingApproved === 4))
        ) {
          return 'past';
        } else if (
          bookingApproved === 1 ||
          bookingApproved === 0 ||
          bookingApproved === 5
        ) {
          return isGuestTripStart ? 'inprogress' : 'upcoming';
        } else {
          return 'past';
        }
      } else if (
        currentDate < bookingStartDate &&
        !isGuestTripStart &&
        (bookingApproved === 1 ||
          bookingApproved === 0 ||
          bookingApproved === 5)
      ) {
        return 'upcoming';
      } else {
        return 'past';
      }
    }
  }
  // Function to return all the categorizes data to Guest
  getGuestBookingsAndCategorize(userId?: string): Observable<any[]> {
    const categorizedBookings: any = {
      upcoming: [],
      inprogress: [],
      past: [],
    };
    let query: any;
    if (userId) {
      query = this.firestore.collection('bookings', (ref) =>
        ref.where('userId', '==', userId)
      );
    } else {
      // Handle the case where neither userId nor vehicleOwnerId is provided.
      return of([]);
    }

    return query.valueChanges().pipe(
      concatMap((bookings: any[]) => {
        if (bookings.length === 0) {
          return of(categorizedBookings); // Return empty array if there are no bookings
        }

        // Create an array of observables that fetch vehicle data for each booking.
        const observables = bookings.map((booking) => {
          const bookingApproved = booking?.bookingApproved;
          const startDate = new Date(booking.startDate);
          const isGuestTripStart = booking?.isGuestTripStart;
          const isHostTripStart = booking?.isHostTripStart;
          const isGuestTripEnd = booking?.isGuestTripEnd;
          const isHostTripEnd = booking?.isHostTripEnd;
          const id = booking?.id;
          const endDate = new Date(booking.endDate);
          const status = this.guestcategorizeBooking(
            setHoursZero(startDate),
            setHoursZero(endDate),
            bookingApproved,
            isGuestTripStart,
            isGuestTripEnd,
            id
          );
          categorizedBookings[status] = [];
          const vehicleId = booking.vehicleId;
          const fieldNames = [
            'carCoverImage',
            'availability',
            'transmissionType',
            'doors',
            'colors',
            'vehicle_make',
            'vehicle_model',
            'vehicle_year',
            'carSeats',
            'insurance_band'
          ];

          return this.getDataByFields(
            'store_vehicles',
            vehicleId,
            fieldNames
          ).pipe(
            map((vehicleData) => {
              // if (vehicleData === null) {
              // } else {
              // }

              // Push the booking into the categorized array
              categorizedBookings[status].push({
                ...booking,
                status,
                vehicleData: vehicleData || null,
              });
            }),
            catchError((error) => {
              console.error(
                `Error fetching data for vehicleId: ${vehicleId}`,
                error
              );
              return of({
                ...booking,
                status,
                vehicleData: null, // Handle the error by setting vehicleData to null
              });
            })
          );
        });

        // Combine all observables into a single observable.
        return forkJoin(observables).pipe(
          map(() => categorizedBookings) // Return the categorized bookings after processing
        );
      })
    );
  }

  // Function to retrieve data from a document by multiple field names
  getDataByFields(collectionName: string, docId: string, fieldNames: string[]) {
    return this.firestore
      .collection(collectionName)
      .doc(docId)
      .get()
      .pipe(
        catchError((error) => {
          return of(null); // Handle errors by returning null
        }),
        map((doc: any) => {
          if (doc.exists) {
            const data: any = doc.data();
            const result: any = {};
            fieldNames.forEach((fieldName) => {
              if (fieldName in data) {
                result[fieldName] = data[fieldName];
              }
            });
            return result;
          } else {
            return null;
          }
        })
      );
  }

  updateOne(id: string, data: any) {
    return this.Collection.UpdateOne(id, data);
  }

  private setHeaders() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return headers;
  }

  tripNotification(data: any): Observable<any> {
    const payload: any = {
      id: data.id,
    };
    return this.http.post(
      `${environment.url}tripNotification`,
      // `http://127.0.0.1:5001/tarlen-dev/us-central1/tripNotification`,
      payload,
      { headers: this.setHeaders() }
    );
  }

  tripExtensionNotification(data: any): Observable<any> {
    const payload: any = {
      id: data.id,
    };
    return this.http.post(
      `${environment.url}tripExtensionNotification`,

      payload,
      { headers: this.setHeaders() }
    );
  }

  deleteBookingById(bookingId: string): Promise<void> {
    const bookingDoc = this.firestore.collection('bookings').doc(bookingId);
    return bookingDoc.delete();
  }

  isDateRangeAvailable(
    startDate: Date,
    endDate: Date,
    availabilityDates: Date[]
  ): boolean {
    const dateRange = this.getDateRangeArray(startDate, endDate);
    return dateRange.every((date) =>
      availabilityDates.some((availableDate) =>
        this.isSameDate(date, availableDate)
      )
    );
  }

  private getDateRangeArray(startDate: Date, endDate: Date): Date[] {
    const dateRange: Date[] = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateRange;
  }

  private isSameDate(date1: Date, date2: Date): boolean {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    return d1.getTime() === d2.getTime();
  }

  patternCron(data: any): Observable<any> {
    const payload: any = {
      cronPattern: data.cronPattern,
    };
    return this.http.post(
      `${environment.url}patternCron`,
      payload,
      { headers: this.setHeaders() }
    );
  }

  async addJobs(bookingPayload: any) {
    const batch = this.firestore.firestore.batch();
    for (const jobType of BookingJobTypes) {
      let dynamicBookingPayload: any = {
        cron_type: jobType.value,
        status: 0,
        bookingId: bookingPayload?.bookingId,
        createdAt: convertDateTime(),
      };
      // Handle time difference based on enum value
      const bookingJobTypeEnumValue: BookingJobS = jobType.bookingJobType;
      switch (bookingJobTypeEnumValue) {
        case BookingJobS.TWO_HR_BEFORE_START_TRIP:
          let data0 = this.calculateStartTime(
            'before',
            2,
            bookingPayload?.startDate,
            bookingPayload?.startEndTime
          );
          dynamicBookingPayload.startDate = data0.date;
          dynamicBookingPayload.startEndTime = data0.time;

          break;
        case BookingJobS.ON_START_START_TRIP:
          let data1 = this.calculateStartTime(
            'ontime',
            0,
            bookingPayload?.startDate,
            bookingPayload?.startEndTime
          );
          dynamicBookingPayload.startDate = data1.date;
          dynamicBookingPayload.startEndTime = data1.time;
          break;
        case BookingJobS.ONE_HR_AFTER_START_TRIP:
          let data2 = this.calculateStartTime(
            'after',
            1,
            bookingPayload?.startDate,
            bookingPayload?.startEndTime
          );
          dynamicBookingPayload.startDate = data2.date;
          dynamicBookingPayload.startEndTime = data2.time;
          break;
        case BookingJobS.TWO_HR_AFTER_START_TRIP:
          let data3 = this.calculateStartTime(
            'after',
            2,
            bookingPayload?.startDate,
            bookingPayload?.startEndTime
          );
          dynamicBookingPayload.startDate = data3.date;
          dynamicBookingPayload.startEndTime = data3.time;
          break;
        case BookingJobS.TWO_HR_BEFORE_END_TRIP:
          let data4 = this.calculateStartTime(
            'before',
            2,
            bookingPayload?.endDate,
            bookingPayload?.startEndTime
          );
          dynamicBookingPayload.startDate = data4.date;
          dynamicBookingPayload.startEndTime = data4.time;
          break;
        case BookingJobS.ON_END_TRIP:
          let data5 = this.calculateStartTime(
            'ontime',
            0,
            bookingPayload?.endDate,
            bookingPayload?.startEndTime
          );
          dynamicBookingPayload.startDate = data5.date;
          dynamicBookingPayload.startEndTime = data5.time;
          break;
        case BookingJobS.TWELVE_HR_AFTER_END_TRIP:
          let data6 = this.calculateStartTime(
            'after',
            12,
            bookingPayload?.endDate,
            bookingPayload?.startEndTime
          );
          dynamicBookingPayload.startDate = data6.date;
          dynamicBookingPayload.startEndTime = data6.time;
          break;
        case BookingJobS.ON_EXTENSION_REQUEST_NOTIFICATION:
          let data7 = this.calculateStartTime(
            'before',
            2,
            bookingPayload?.endDate,
            bookingPayload?.startEndTime
          );
          dynamicBookingPayload.startDate = data7.date;
          dynamicBookingPayload.startEndTime = data7.time;
          break;
        case BookingJobS.ON_EXTENSION_CANCEL:
          let data8 = this.calculateStartTime(
            'ontime',
            0,
            bookingPayload?.endDate,
            bookingPayload?.startEndTime
          );
          dynamicBookingPayload.startDate = data8.date;
          dynamicBookingPayload.startEndTime = data8.time;
          break;
        case BookingJobS.ON_BOOKING_REQUEST_NOTIFICATION:
          let data9 = this.calculateStartTime(
            'before',
            2,
            bookingPayload?.startDate,
            bookingPayload?.startEndTime
          );
          dynamicBookingPayload.startDate = data9.date;
          dynamicBookingPayload.startEndTime = data9.time;
          break;
        case BookingJobS.ON_BOOKING_CANCEL:
          let data10 = this.calculateStartTime(
            'ontime',
            0,
            bookingPayload?.startDate,
            bookingPayload?.startEndTime
          );
          dynamicBookingPayload.startDate = data10.date;
          dynamicBookingPayload.startEndTime = data10.time;
          break;
        // case BookingJobS.ON_BOOKING_PAYMENT_REFUND:
        //   let data11 = this.calculateStartTime(
        //     'refund',
        //     1,
        //     bookingPayload?.startDate,
        //     bookingPayload?.startEndTime
        //   );
        //   dynamicBookingPayload.startDate = data11.date;
        //   dynamicBookingPayload.startEndTime = data11.time;
        //   break;
        default:
          break;
      }
      const newDocRef = this.firestore.collection('jobs').doc(); // Generate a new document reference
      batch.set(newDocRef.ref, dynamicBookingPayload);
    }
    try {
      await batch.commit();
    } catch (error) {
      throw error;
    }
  }

  calculateStartTime(
    timeType: string,
    hoursToAdd: number,
    date: string,
    time: string
  ): { date: any; time: any } {
    const newDate = AddTimeWithDate(new Date(date), time);
    switch (timeType) {
      case 'after':
        newDate.setHours(newDate.getHours() + hoursToAdd);
        break;
      case 'before':
        newDate.setHours(newDate.getHours() - hoursToAdd);
        break;
      case 'refund':
        const daysToAdd = 3;
        newDate.setDate(newDate.getDate() + daysToAdd);
        newDate.setHours(newDate.getHours() + hoursToAdd);
        break;
      default:
        break;
    }
    const hours = newDate.getHours().toString().padStart(2, '0');
    const minutes = newDate.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;
    // const fdate = dateFormatter(newDate);
    const year = newDate.getFullYear();
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const day = newDate.getDate().toString().padStart(2, '0');
    return { date: `${year}-${month}-${day}`, time: formattedTime };
  }

  async updateDatesForBooking(payload: any): Promise<void> {
    try {
      const currentDate = new Date(payload.previousDate);
      const bookingendDate = dateFormatter(currentDate);
      const vehicleBookingsQuerySnapshot = await this.firestore
        .collection('jobs')
        .ref.where('bookingId', '==', payload?.bookingId)
        .where('startDate', '>=', bookingendDate)
        .get();

      const vehicleBookings: any[] = vehicleBookingsQuerySnapshot.docs.map(
        (doc) => {
          const data: any = doc.data();
          return { id: doc.id, ...data };
        }
      );
      for (let job of vehicleBookings) {
        let newDate = AddTimeWithDate(new Date(payload.newDate), payload?.time);
        // Example: Add 3 hours to the original startDate
        // const originalStartDate = new Date(booking.startDate);
        // const updatedStartDate = new Date(originalStartDate.getTime() + 3 * 60 * 60 * 1000);
        let payloadHours = Number(payload.time.split(':')[0]);
        let jobHours = Number(job.startEndTime.split(':')[0]);
        // Calculate the absolute difference in hours
        let absoluteDifferenceInHours = Math.abs(payloadHours - jobHours);
        // Add the time difference to newDate
        if (absoluteDifferenceInHours != 0) {
          if (job?.cron_type == 7 || job?.cron_type == 9) {
            newDate.setHours(newDate.getHours() - absoluteDifferenceInHours);
          } else if (job?.cron_type == 11) {
            const daysToAdd = 3;
            newDate.setDate(newDate.getDate() + daysToAdd);
            newDate.setHours(newDate.getHours() + absoluteDifferenceInHours);
          } else {
            newDate.setHours(newDate.getHours() + absoluteDifferenceInHours);
          }
        }
        // Update startEndTime
        const hours = newDate.getHours().toString().padStart(2, '0');
        const minutes = newDate.getMinutes().toString().padStart(2, '0');
        job.startEndTime = `${hours}:${minutes}`;
        // Update startDate
        const currentDate = new Date(newDate);
        job.startDate = dateFormatter(currentDate);
        // Update the 'startDate' of the booking in Firestore
        await this.firestore.collection('jobs').doc(job.id).update(job);
      }
    } catch (error) {
      console.error('Error checking booking conflicts:', error);
      throw error;
    }
  }

  async isTimeDisabled(
    userId: string,
    startDate: any,
    vehicleId: string
  ): Promise<any> {
    try {
      const userBookingsQuerySnapshot = await this.firestore
        .collection('bookings')
        .ref.where('userId', '==', userId)
        .where('bookingApproved', '==', 1)
        .get();

      const userBookings: any = userBookingsQuerySnapshot.docs.map((doc) =>
        doc.data()
      );
      for (const booking of userBookings) {
        if (this.isSameDate(booking?.endDate, startDate)) {
          return booking;
        }
      }

      const vehicleBookingsQuerySnapshot = await this.firestore
        .collection('bookings')
        .ref.where('vehicleId', '==', vehicleId)
        .where('bookingApproved', '==', 1)
        .get();

      const vehicleBookings: any = vehicleBookingsQuerySnapshot.docs.map(
        (doc) => doc.data()
      );
      for (const booking of vehicleBookings) {
        if (this.isSameDate(booking?.endDate, startDate)) {
          return booking;
        }
      }

      return false; // Return false if no conflicts were found
    } catch (error) {
      console.error('Error checking booking conflicts:', error);
      throw error;
    }
  }

  async calculateBookingDetails(bookingData: any) {
    const prefferedTime = bookingData?.prefferedTime;
    const bookedDays: any = this.calculateDays(
      bookingData?.startDate,
      bookingData?.extendedTrip === 1
        ? bookingData?.extendedTripData?.startDate
        : bookingData?.endDate
    );
    let extendedBookedDays: any = 0;
    if (bookingData?.extendedTrip === 1 || bookingData?.extendedTrip === 0) {
      extendedBookedDays = this.calculateDays(
        bookingData?.extendedTripData?.startDate,
        bookingData?.extendedTripData?.endDate
      );
    } else if (this?.user?.role == 'Guest') {
      extendedBookedDays = this.calculateDays(
        bookingData?.extendedTripData?.startDate,
        bookingData?.extendedTripData?.endDate
      );
    }
    let addOnsPrices = 0;
    let extendedAddons = 0;
    if (bookingData?.addOns?.length > 0) {
      try {
        const addonsData = await Promise.all(
          bookingData.addOns.map(async (element: any) => {
            const addOnsData = await this.vehicleService.getAddonsById(element);
            addOnsPrices += JSON.parse(addOnsData.price) * bookedDays;

            if (bookingData?.extendedTrip === 1) {
              extendedAddons +=
                JSON.parse(addOnsData.price) * extendedBookedDays;
            }

            return addOnsData;
          })
        );

        if (addonsData.length > 0) {
          bookingData.addOnnName = addonsData;
        }
      } catch (error) {
        // Handle error
      }
    }

    const calculateCost = (
      pricePerDay: any,
      days: any,
      addons: any,
      deliveryCharges: any
    ) => {
      const tarlenComission: any = this.calculateTarlenComission(
        pricePerDay,
        days,
        addons,
        deliveryCharges
      );
      const totalCost: any =
        pricePerDay * days +
        (deliveryCharges || 0) +
        (addons || 0) -
        tarlenComission;
      return { tarlenComission, totalCost };
    };

    const bookingDetails: any = {
      prefferedTime,
      bookedDays,
      extendedBookedDays,
      addOnsPrices,
      extendedAddons,
      payableAmount: addOnsPrices,
    };
    if (bookingData?.addOns?.length > 0) {
      const { tarlenComission, totalCost } = calculateCost(
        bookingData?.pricePerDay,
        bookedDays,
        addOnsPrices,
        bookingData?.deliveryCharges
      );
      bookingDetails.tarlenComission = tarlenComission;
      bookingDetails.totalPrice = totalCost;

      if (bookingData?.extendedTrip === 1) {
        const {
          tarlenComission: tarlenComissionExtension,
          totalCost: extensionCost,
        } = calculateCost(
          bookingData?.pricePerDay,
          extendedBookedDays,
          extendedAddons,
          bookingData?.deliveryCharges
        );
        bookingDetails.tarlenComissionExtension = tarlenComissionExtension;
        bookingDetails.extensionCost = extensionCost;
        // Calculate actual booking price and actual extended price
        bookingDetails.actualBookingPrice = totalCost;
        bookingDetails.actualExtendedPrice = extensionCost;
        bookingDetails.totalPrice = totalCost + extensionCost;
      }
    } else {
      const { tarlenComission, totalCost } = calculateCost(
        bookingData?.pricePerDay,
        bookedDays,
        0,
        bookingData?.deliveryCharges
      );
      bookingDetails.tarlenComission = tarlenComission;
      bookingDetails.totalPrice = totalCost;
      if (bookingData?.extendedTrip === 1) {
        const {
          tarlenComission: tarlenComissionExtension,
          totalCost: extensionCost,
        } = calculateCost(
          bookingData?.pricePerDay,
          extendedBookedDays,
          0,
          bookingData?.deliveryCharges
        );
        bookingDetails.tarlenComissionExtension = tarlenComissionExtension;
        bookingDetails.extensionCost = extensionCost;
        // Calculate actual booking price and actual extended price
        bookingDetails.actualBookingPrice = totalCost;
        bookingDetails.actualExtendedPrice = extensionCost;
        bookingDetails.totalPrice = totalCost + extensionCost;
      }
    }
    return bookingDetails;
  }

  calculateDays(startDate: any, endDate: any) {
    const timeDifferenceInMilliseconds = endDate - startDate;
    const minTimeDifferenceInMilliseconds = 24 * 60 * 60 * 1000; // One day in milliseconds
    const correctedTimeDifference = Math.max(
      minTimeDifferenceInMilliseconds,
      timeDifferenceInMilliseconds
    );
    const timeDifferenceInDays =
      correctedTimeDifference / (1000 * 60 * 60 * 24);
    return Math.ceil(timeDifferenceInDays);
  }

  calculateTarlenComission(
    pricePerDay: any,
    days: any,
    addons: any,
    deliveryCharges: any
  ) {
    return (
      (pricePerDay * days + (addons || 0) + (deliveryCharges || 0)) *
      0.2
    ).toFixed(2);
  }

  async alreadyBookingAutoCancel(
    userId: string,
    vehicleId: string,
    startDate: any,
    endDate: any,
    id: string
  ): Promise<Observable<string>> {
    try {
      const userBookingsQuerySnapshot = await this.firestore
        .collection('bookings')
        .ref.where('userId', '==', userId)
        .where('bookingApproved', '==', 0)
        .get();

      const userBookings: any = userBookingsQuerySnapshot.docs.map((doc) =>
        doc.data()
      );

      for (const booking of userBookings) {
        if (
          (this.dateUtilService.convertToOnlyDate(booking?.startDate) <=
            this.dateUtilService.convertToOnlyDate(startDate) &&
            this.dateUtilService.convertToOnlyDate(startDate) <=
              this.dateUtilService.convertToOnlyDate(booking?.endDate)) ||
          (this.dateUtilService.convertToOnlyDate(booking?.startDate) <=
            this.dateUtilService.convertToOnlyDate(endDate) &&
            this.dateUtilService.convertToOnlyDate(endDate) <=
              this.dateUtilService.convertToOnlyDate(booking?.endDate))
        ) {
          if (booking.id != id) {
            this.reFundPayment(booking);
            let payload = {
              bookingApproved: 3,
              cancelled_by: 'Host',
              isPaymentCapture: false,
              isAmountRefunded: true,
              isRejected:true
            };
            const documentRef = this.firestore
              .collection('bookings')
              .doc(booking.id);
            await documentRef
              .update(payload)
              .then(() => {
                return of('Update successful'); // Return an observable
              })
              .catch((error) => {
                throw error;
              });
          }
        }
      }

      const vehicleBookingsQuerySnapshot = await this.firestore
        .collection('bookings')
        .ref.where('vehicleId', '==', vehicleId)
        .where('bookingApproved', '==', 0)
        .get();

      const vehicleBookings: any = vehicleBookingsQuerySnapshot.docs.map(
        (doc) => doc.data()
      );

      for (const booking of vehicleBookings) {
        if (
          (this.dateUtilService.convertToOnlyDate(booking?.startDate) <=
            this.dateUtilService.convertToOnlyDate(startDate) &&
            this.dateUtilService.convertToOnlyDate(startDate) <=
              this.dateUtilService.convertToOnlyDate(booking?.endDate)) ||
          (this.dateUtilService.convertToOnlyDate(booking?.startDate) <=
            this.dateUtilService.convertToOnlyDate(endDate) &&
            this.dateUtilService.convertToOnlyDate(endDate) <=
              this.dateUtilService.convertToOnlyDate(booking?.endDate))
        ) {
          if (booking.id != id) {
            this.reFundPayment(booking);
            let payload = {
              bookingApproved: 3,
              cancelled_by: 'Host',
              isPaymentCapture: false,
              isAmountRefunded: true,
              isRejected:true

            };
            const documentRef = this.firestore
              .collection('bookings')
              .doc(booking.id);
            await documentRef
              .update(payload)
              .then(() => {
                return of('Update successful'); // Return an observable
              })
              .catch((error) => {
                throw error;
              });
          }
        }
      }

      return of('No matching booking found'); // Return an observable
    } catch (error) {
      console.error('Error checking booking conflicts:', error);
      throw error;
    }
  }

  async reFundPayment(bookingData:any) {
    try {
      let payload: any = {
        paymentId: bookingData?.payementId,
      };
      const res: any = await this.peachPaymentService
        .reversePayment(payload)
        .toPromise();
      res.booking_id = bookingData?.id;
      this.peachPaymentService.addPayment(res);
      if (res?.result?.code == '000.100.110') {
      } else {
      }
    } catch (error) {
      return error;
    } finally {
    }
  }

  // Function to add a new reimbursement request to the collection
  addReimbursementRequest(requestData: any): Promise<any> {
    return this.firestore.collection('reimbursementRequests').add(requestData);
  }

  // Function to get all reimbursement requests from the collection
  getReimbursementRequests(): Observable<any[]> {
    return this.firestore.collection('reimbursementRequests').valueChanges();
  }

  // Function to get reimbursement requests by host user ID
  getReimbursementRequestsByHostUserId(userId: string): Observable<any[]> {
    return this.firestore
      .collection('reimbursementRequests', (ref) =>
        ref.where('requestedFrom', '==', userId)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a: any) => ({
            id: a.payload.doc.id,
            ...a.payload.doc.data(),
          }))
        )
      );
  }

  // Function to get reimbursement requests by guest user ID
  getReimbursementRequestsByGuestUserId(userId: string): Observable<any[]> {
    return this.firestore
      .collection('reimbursementRequests', (ref) =>
        ref.where('requestedTo', '==', userId)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a: any) => ({
            id: a.payload.doc.id,
            ...a.payload.doc.data(),
          }))
        )
      );
  }
  // Function to get a reimbursement request by ID
  getReimbursementRequestById(requestId: string): Observable<any | undefined> {
    return this.firestore
      .collection('reimbursementRequests')
      .doc(requestId)
      .snapshotChanges()
      .pipe(
        map((action) => {
          const data: any = action.payload.data();
          const id = action.payload.id;
          return { id, ...data };
        })
      );
  }

  // Function to update a reimbursement request by ID
  updateReimbursementRequestById(
    requestId: string,
    updatedData: any
  ): Promise<void> {
    const docRef = this.firestore
      .collection('reimbursementRequests')
      .doc(requestId);
    return docRef.update(updatedData);
  }

  getAllMakeModel(): Observable<any> {
    return new Observable(observer => {
      this.firestore.collection('vehicle_make_model').valueChanges().subscribe((dataArray: any[]) => {        
        const makes = [...new Set(dataArray.map(item => item.make))];
        const models = [...new Set(dataArray.map(item => item.model))];
        const years = [...new Set(dataArray.map(item => item.year))];
        const allData = dataArray;
        observer.next({ makes, models, years, allData });
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }
  
}
