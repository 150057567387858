import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  StandardSuccessModalComponent,
  StandardSuccessModalProps,
} from '../../shared/modal/standard-success-modal/standard-success-modal.component';
import { getModalAnimation } from '../consts/animations.const';
// import { MissionStatementComponent, missionModalProps } from '../../shared/components/mission-statement/mission-statement.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private ModalController: ModalController) {}

  dismissTopModal(data?: any) {
    this.ModalController.getTop().then((top) => {
      if (top === undefined) return;
      top.dismiss(data);
    });
  }

  async showModal(opts: Parameters<typeof this.ModalController.create>[0]) {
    const modal = await this.ModalController.create({
      enterAnimation: getModalAnimation('enter'),
      leaveAnimation: getModalAnimation('leave'),
      ...opts,
    });
    await modal.present();
    return modal;
  }

  showSuccessModal(props: StandardSuccessModalProps) {
    return this.showModal({
      component: StandardSuccessModalComponent,
       componentProps: props,
    });
  }
  // missionStatementModal(props: missionModalProps) {
  //   return this.showModal({
  //     component: MissionStatementComponent,
  //      componentProps: props,
  //     cssClass: 'custom-modal',
  //   });
  // }
}
