import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  mergeMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import * as actions from './booking.actions';
import { BookingService } from '../../services/booking.service';
import { FirebaseError } from 'firebase/app';
import { BookingStatus, FirebaseErrorCodes } from '@tarlen/shared';
import { ModalService } from '../../services/modal.service';
import { Router } from '@angular/router';
import { ICONS } from '../../consts/assets';
import { ROUTES } from '../../consts/routing';
import { userSelectors } from '../user';
import { Store } from '@ngrx/store';
import { VehicleService } from '../../services/vehicle.service';
import { from } from 'rxjs';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private BookingService: BookingService,
    private ModalService: ModalService,
    private Router: Router,
    private Store: Store,
    private VehicleService: VehicleService
  ) { }

  // #region ReqReadBooking
  ReqReadBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ReqReadBooking),
      mergeMap(({ type, ...payload }) => {
        return this.Store.select(userSelectors.selectUserId).pipe(
          filter((userId) => !!userId),
          take(1),
          mergeMap((userId) => this.BookingService.read(userId)),
          mergeMap((booking) => {
            if (!booking) {
              return [
                actions.ReadBookingFail({
                  error: 'No booking found',
                }),
              ];
            }

            return this.VehicleService.read(booking.vehicleId).pipe(
              mergeMap((vehicle) => {
                return [
                  actions.ReadBookingSuccess({
                    vehicle,
                    booking,
                  }),
                ];
              }),
              catchError((error: FirebaseError) => {
                if (error.code === FirebaseErrorCodes.ENTITY_NOT_FOUND) {
                  return [actions.ReadBookingFail({ error })];
                }

                return [
                  actions.ReadBookingFail({
                    error,
                    toast: {
                      message:
                        'Something went wrong while trying to get your booking, please try again.',
                    },
                  }),
                ];
              })
            );
          })
        );
      })
    )
  );
  // #endregion

  // #region ReqCreateBooking
  ReqCreateBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ReqCreateBooking),
      mergeMap(({ type, ...payload }) => {
        return this.BookingService.create(payload.vehicleBooking).pipe(
          mergeMap(({ booking, vehicle }) => {
            // this.ModalService.showSuccessModal({
            //   imgPath: ICONS.SUCCESS_TICK,
            //   title: 'Success!',
            //   body: "We're so glad you could find a car you'd like to rent. Your request has been received and we will confirm availability via email as soon as possible!",
            //   buttonText: 'Great!',
            // });
            // this.Router.navigateByUrl(ROUTES.Home._Path);

            return [actions.CreateBookingSuccess({ booking, vehicle })];
          }),
          catchError((error) => {
            return [
              actions.CreateBookingFail({
                error,
                toast: {
                  message: error,
                },
              }),
            ];
          })
        );
      })
    )
  );
  // #endregion

  // #region ReqCancelBooking
  ReqCancelBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ReqCancelBooking),
      mergeMap(({ type, ...payload }) => {
        return from(
          this.BookingService.update({
            ...payload.booking,
            status: BookingStatus.CANCELLED,
          })
        ).pipe(
          mergeMap((booking) => {
            return [actions.CancelBookingSuccess({ booking })];
          }),
          catchError((error) => {
            return [
              actions.CancelBookingFail({
                error,
                toast: {
                  message: 'Could not cancel booking, please try again.',
                },
              }),
            ];
          })
        );
      })
    )
  );
  // #endregion

  // #region ReqCreateBooking
  ReqUpdateBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ReqUpdateBooking),
      mergeMap(({ type, ...payload }) => {
        return this.BookingService.update(payload.vehicleBooking)
          .then(({ }) => {
            // this.ModalService.showSuccessModal({
            //   imgPath: ICONS.SUCCESS_TICK,
            //   title: 'Success!',
            //   body: "We're so glad you could find a car you'd like to rent. Your request has been received and we will confirm availability via email as soon as possible!",
            //   buttonText: 'Great!',
            // });
            // this.Router.navigateByUrl(ROUTES.Home._Path);

            return actions.UpdateBookingSuccess({});
          })
          .catch((error) => {
            return [
              actions.UpdateBookingFail({
                error,
                toast: {
                  message: 'Could not update your booking, please try again.',
                },
              }),
            ];
          });
      })
    )
  );
  // #endregion
}
