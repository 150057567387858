import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppRoutingModule } from './app-routing.module';
import { NgRxModule } from './core/modules/ngrx.module';
import { FirebaseModule } from './core/modules/firebase.module';
import { AppComponent } from './app.component';
import { getPageAnimation } from './core/consts/animations.const';
import { SplashScreenModule } from './shared/components/splash-screen/splash-screen.module';
import { StandardSuccessModalModule } from './shared/modal/standard-success-modal/standard-success-modal.module';
import SwiperCore, {
  FreeMode,
  Pagination,
  Navigation,
  Scrollbar,
} from 'swiper';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';

SwiperCore.use([FreeMode, Pagination, Navigation, Scrollbar]);
import { environment } from '../environments/environment';
import { initializeApp } from 'firebase/app';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { MessagingService } from './core/services/messaging.service';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AsyncPipe } from '@angular/common';
import { GlobalFooterModule } from './shared/components/global-footer/global-footer.module';
import { GlobalHeaderModule } from './shared/components/global-header/global-header.module';
import { ModalPopupModule } from './features/modal-popup/modal-popup.module';
import { MissionStatementModule } from './shared/components/mission-statement/mission-statement.module';
initializeApp(environment.firebase);
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
      navAnimation: getPageAnimation,
    }),
    AppRoutingModule,
    NgRxModule,
    FirebaseModule,
    SplashScreenModule,
    StandardSuccessModalModule,
    MissionStatementModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    CarouselModule,
    BrowserAnimationsModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    GlobalFooterModule,
    GlobalHeaderModule,
    ModalPopupModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
  ],

  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    MessagingService,
    AsyncPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
