import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandardSuccessModalComponent } from './standard-success-modal.component';
import { IonicModule } from '@ionic/angular';
import { StandardButtonModule } from '../../components/standard-button/standard-button.module';

@NgModule({
  declarations: [StandardSuccessModalComponent],
  imports: [CommonModule, IonicModule, StandardButtonModule],
  exports: [StandardSuccessModalComponent],
})
export class StandardSuccessModalModule {}
