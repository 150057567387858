import { CloudFunction, BookVehicleDTO, Booking, Vehicle } from '../models';

export const CreateBookingCloudFunction = new CloudFunction<
  BookVehicleDTO,
  {
    booking: Booking;
    vehicle: Vehicle;
  }
>('bookingFunctions_CreateBooking');

export const UpdateBookingCloudFunction = new CloudFunction<
  Booking,
  Booking
>('bookingFunctions_UpdateBooking');

export type BookingCloudFunctions =
  | typeof CreateBookingCloudFunction
  | typeof UpdateBookingCloudFunction;
