import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, Subject, from } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { LoaderService } from './loader.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ToastService } from './toast.service';
import { Console, log } from 'console';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private chatCollection: AngularFirestoreCollection<any>;
  private unreadMessageCountsSubject = new Subject<{ [roomId: string]: number }>();
  unreadMessageCounts$ = this.unreadMessageCountsSubject.asObservable();
  _user: any
  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private LoaderService: LoaderService,
    private auth: AngularFireAuth,
    private ToastService: ToastService,

  ) {
    this.chatCollection = this.firestore.collection('chatrooms');
    this.auth.user.subscribe({
      next: (res: any) => {
        this._user = res;
      }
    })
  }

  private receiverIdSubject = new Subject<string>();
  setReceiverId(receiverId: string) {
    this.receiverIdSubject.next(receiverId);
  }

  getReceiverId() {
    return this.receiverIdSubject.asObservable();
  }

  async createChatRoom(senderId: string, receiverId: string): Promise<string> {
    const roomId = this.chatCollection.doc().ref.id; // Generate a unique room ID
    const senderUserData = await this.fetchUserData(senderId);
    const receiverUserData = await this.fetchUserData(receiverId);

    const chatRoom = {
      host_id: receiverId,
      room_id: roomId,
      users: [senderId, receiverId],
      userName: [
        { id: senderId, name: this.constructUserName(senderUserData.firstName, senderUserData.lastName) },
        { id: receiverId, name: this.constructUserName(receiverUserData.firstName, receiverUserData.lastName) }
      ],
      messages: []
    };

    const chatRoomNames = {
      host_id: receiverId,
      room_id: roomId,
      users: [senderId, receiverId],
      userName: [
        { id: senderId, name: this.constructUserName(senderUserData.firstName, senderUserData.lastName) },
        { id: receiverId, name: this.constructUserName(receiverUserData.firstName, receiverUserData.lastName) }
      ],
    };

    await this.chatCollection.doc(roomId).set(chatRoom);
    await this.firestore.collection('chatRoomsNames').doc(roomId).set(chatRoomNames); // Use the new collection name
    return roomId;
  }

  private fetchUserData(userId: string): Promise<any> {
    return this.firestore.collection('users').doc(userId).get().toPromise()
      .then((userDoc: any) => {
        if (userDoc.exists) {
          return userDoc.data();
        } else {
          return null;
        }
      })
      .catch(error => {
        return null;
      });
  }

  private constructUserName(firstName: string, lastName: string): string {
    return `${firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase()} ` +
      `${lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase()}`;
  }


  async addMessageToChatRoom(roomId: string, message: any): Promise<void> {
    const chatRoomDoc = this.chatCollection.doc(roomId);

    try {
      const chatRoomSnapshot: any = await chatRoomDoc.get().toPromise();

      if (chatRoomSnapshot.exists) {
        // Chat room exists, update it with the new message
        const existingChatRoom = chatRoomSnapshot.data();
        const updatedMessages = [...existingChatRoom.messages, message];

        return chatRoomDoc.update({ messages: updatedMessages });
      }
    } catch (error) {
      throw error;
    }
  }


  async getUserById(userId: string): Promise<any> {
    try {
      const userDoc: any = await this.firestore.collection('users').doc(userId).get().toPromise();

      if (userDoc.exists) {
        return userDoc.data();
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  async markAllMessagesAsRead(roomId: string): Promise<void> {
    const chatRoomDoc = this.chatCollection.doc(roomId);

    try {
      const chatRoomSnapshot: any = await chatRoomDoc.get().toPromise();

      if (chatRoomSnapshot.exists) {
        const messages = chatRoomSnapshot.data()?.messages;
        if (messages) {
          const updatedMessages = messages.map((message: any) => {
            if (!message.read) {
              return { ...message, read: true };
            }
            return message;
          });

          return chatRoomDoc.update({ messages: updatedMessages });
        }
      }
    } catch (error) {
      throw error;
    }
  }

  async markMessageAsRead(roomId: string, messageId: string): Promise<void> {
    const chatRoomDoc = this.chatCollection.doc(roomId);
    try {
      const chatRoomSnapshot: any = await chatRoomDoc.get().toPromise();
      if (chatRoomSnapshot.exists) {
        const messages = chatRoomSnapshot.data()?.messages;
        if (messages) {
          const updatedMessages = messages.map((message: any) => {
            if (message.messageId === messageId && !message.read) {
              return { ...message, read: true };
            }
            return message;
          });

          return chatRoomDoc.update({ messages: updatedMessages });
        }
      }
    } catch (error) {
      throw error;
    }
  }

  async updateChatRoomMessages(roomId: string, updatedMessages: any[]): Promise<void> {
    const chatRoomDoc = this.firestore.collection('chatrooms').doc(roomId);
    try {
      await chatRoomDoc.update({ messages: updatedMessages });
    } catch (error) {
      throw error;
    }
  }

  getRealTimeChatRoomsByUserWithUnread(userId: string): Observable<any[]> {
    return this.firestore.collection('chatrooms', (ref) => ref.where('users', 'array-contains', userId))
      .valueChanges({ idField: 'room_id' })
      .pipe(
        map((chatRooms: any[]) => {
          return chatRooms.map((room) => {
            const unreadMessages = room.messages.filter((message: any) => !message.read && message.receiverId === userId);
            return { ...room, unreadMessageCount: unreadMessages.length };
          });
        })
      );
  }

  getMyChatRoomsByUser(userId: string, role: string): Observable<any[]> {
    return this.firestore.collection('chatRoomsNames').valueChanges({ idField: 'room_id' }).pipe(
      map((chatRooms: any[]) =>
        chatRooms.filter(room => {
          // if (role === 'Host') {
          // return room.host_id === userId;
          return room.users && room.users.includes(userId)

          // } else {
          //   return  room.host_id != userId  && ( room.users && room.users.includes(userId))
          // }
        })
      )
    );
  }




  getChatRoomsByUser(userId: string, role: string): Observable<any[]> {
    return this.chatCollection.valueChanges({ idField: 'room_id' }).pipe(
      map(chatRooms =>
        chatRooms.filter(room => {
          room.users && room.users.includes(userId)
          // if (role === 'Host') {
          // return room.host_id === userId;
          return room.users && room.users.includes(userId)
          // } else {
          //   return  room.host_id != userId  && ( room.users && room.users.includes(userId))
          // }
        })
      )
    );
  }


  async getOrCreateChatRoom(senderId: string, receiverId: string): Promise<any> {
    try {
      const chatRoomNamesQuerySnapshot: any = await this.firestore.collection('chatRoomsNames').get().toPromise();
      const chatRooms: any[] = [];
      chatRoomNamesQuerySnapshot.forEach((doc: any) => {
        const chatRoomData = doc.data() as any;
        chatRooms.push(chatRoomData);
      });
      const matchingChatRoom = chatRooms.find(chatRoom =>
        chatRoom.users.includes(senderId) && chatRoom.users.includes(receiverId)
      );
      if (matchingChatRoom) {
        return matchingChatRoom;
      } else {
        const newRoomId = await this.createChatRoom(senderId, receiverId);
        const senderUserData = await this.fetchUserData(senderId);
        const receiverUserData = await this.fetchUserData(receiverId);
        const userName = [
          { id: senderId, name: this.constructUserName(senderUserData.firstName, senderUserData?.lastName), profileName: 'H1' },
          { id: receiverId, name: this.constructUserName(receiverUserData.firstName, receiverUserData?.lastName), profileName: 'HT' }
        ];
        return {
          room_id: newRoomId,
          users: [senderId, receiverId],
          messages: [],
          host_id: receiverId,
          userName
        };
      }
    } catch (error) {
      return null;
    }
  }

  getChatRoomById(roomId: string): Observable<any> {
    return this.firestore.collection('chatrooms').doc(roomId).valueChanges();
  }



  async startChat(bookingId: any, type: string) {
    try {
      this.LoaderService.showLoader();
      const documentRef = this.firestore.collection('bookings').doc(bookingId);
      documentRef.get().toPromise().then((doc: any) => {
        if (doc.exists) {
          let reciverId
          const documentData = doc.data();
          if (type == 'guest') {
            reciverId = documentData?.vehicleOwnerId
          } else {
            reciverId = documentData?.userId
          }
          this
            .getOrCreateChatRoom(this._user.uid, reciverId)
            .then((chatRoom: any) => {
              this.LoaderService.hideLoader();
              if (chatRoom) {
                this.LoaderService.hideLoader();
                setTimeout(() => {
                  if (type == 'guest') {
                    this.router.navigateByUrl(
                      'chat/messages/' + chatRoom.room_id
                    );
                  } else {
                    this.router.navigateByUrl(
                      'host-chat/messages/' + chatRoom.room_id
                    );
                  }
                }, 1000);

              }
            })
            .catch((error) => {
              this.ToastService.showBasic('Error fetching or creating chat room');
            });
        } else {
          this.LoaderService.hideLoader();
          this.ToastService.showBasic('Error fetching or creating chat room');
        }
      }).catch((error) => {
        this.LoaderService.hideLoader();
        this.ToastService.showBasic('Error fetching or creating chat room');
      });
    } catch (error) {
      this.LoaderService.hideLoader();
    }

    // The rest of your logic goes here...
    // this.LoaderService.showLoader();
    // ...
  }


}
