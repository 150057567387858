import { CommonModule } from '@angular/common';
import {
  Component,
  DoCheck,
  EventEmitter,
  HostBinding,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';

type NonMutableKeys =
  | keyof Pick<ImageComponent, 'ngOnInit' | 'ngDoCheck' | 'config'>
  | 'applyConfig'
  | 'configDifference';
type Config = Partial<Omit<ImageComponent, NonMutableKeys>>;

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit, DoCheck {
  @HostBinding('attr.class') @Input('hostClass') hostClass: string;
  @Input() class: string;
  @Input() src: string | null;
  @Input() height = 'auto';
  @Input() width = '100%';
  @Input() borderRadius: string;
  @Input() maxHeight: string;
  @Input() maxWidth: string;
  @Input() border: string;
  @Input() alt: string;
  @Output() click = new EventEmitter<MouseEvent>();
  // image attributes
  @Input() objectFit = 'cover';
  @Input() objectPosition = 'center';
  @Input() verticalAlign = 'middle';
  // div attributes
  @Input() renderAsBackgroundImage = false;
  @Input() backgroundRepeat = 'no-repeat';
  @Input() backgroundPosition = 'center';
  @Input() backgroundSize = 'contain';
  // overrides values individually defined by input
  @Input() config: Config;

  private configDifference: KeyValueDiffer<string, any>;

  constructor(private KeyValueDiffers: KeyValueDiffers) {}

  ngOnInit() {
    this.setConfigDifference(this.config);
  }

  ngDoCheck() {
    if (this.config) {
      const changes = this.configDifference.diff(this.config);
      if (changes) {
        this.applyConfig(this.config);
        this.setConfigDifference(this.config);
      }
    }
  }

  private setConfigDifference(config: Config) {
    this.configDifference = this.KeyValueDiffers.find(config || {}).create();
  }

  private applyConfig(config: Config) {
    Object.assign(this, config || {});
  }
}

@NgModule({
  declarations: [ImageComponent],
  imports: [CommonModule],
  exports: [ImageComponent],
})
export class ImageModule {}
