export enum FirestoreCollections {
  VEHICLES = 'store_vehicles',
  VEHICLE_VERIFICATIONS = 'vehicleVerifications',
  USERS = 'users',
  DRIVER_VERIFICATIONS = 'driverVerifications',
  BOOKINGS = 'bookings',
  FAVORITES = 'favorites',
  VEHICLE_RATINGS = 'vehicle_ratings',
  GUEST_RATINGS = 'guest_ratings',
  HOST_RATINGS = 'host_ratings',
  ISSUES = 'issues',
  UPDATEVEHICLES ='updateVehicleRequests'
}

export enum FirebaseStorageCollections {
  PROOFS_OF_INSURANCE = 'proofsOfInsurance',
  PROOFS_OF_REGISTRATION = 'proofsOfRegistration',
  DRIVER_LICENSES = 'driverLicenses',
  VEHICLE_IMAGES = 'vehicleImages',
}

export enum FirebaseErrorCodes {
  USER_NOT_FOUND = 'auth/user-not-found',
  EXPIRED_ACTION_CODE = 'auth/expired-action-code',
  INVALID_ACTION_CODE = 'auth/invalid-action-code',
  WEAK_PASSWORD = 'auth/weak-password',
  ENTITY_NOT_FOUND = 'functions/not-found',
  PERMISSION_DENIED = 'permission-denied',
}

export const DEFAULT_REGION = 'europe-west1';
