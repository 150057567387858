import { EMPTY_LOCATION } from '../constants';
import { BaseEntity, LocationDetailsDTO, VerificationStatus } from './misc';
import { User } from './user';

export enum TransmissionType {
  Manual,
  Automatic,
  Tiptronic,
}
export const TRANSMISSION_TYPES = Object.keys(TransmissionType).reduce(
  (transmissionTypes, transmissionType) => {
    if (!isNaN(Number(transmissionType))) return transmissionTypes;

    return [
      ...transmissionTypes,
      {
        transmissionType,
        value:
          TransmissionType[transmissionType as keyof typeof TransmissionType],
      },
    ];
  },
  [] as { transmissionType: string; value: TransmissionType }[]
);

export enum FuelType {
  Petrol,
  Diesel,
  Electric,
  Hybrid,
}
export const FUEL_TYPES = Object.keys(FuelType).reduce(
  (fuelTypes, fuelType) => {
    if (!isNaN(Number(fuelType))) return fuelTypes;

    return [
      ...fuelTypes,
      {
        fuelType,
        value: FuelType[fuelType as keyof typeof FuelType],
      },
    ];
  },
  [] as { fuelType: string; value: FuelType }[]
);

export enum VehicleColour {
  White,
  Black,
  Silver,
  Grey,
  Red,
  Brown,
  Blue,
  Other,
}
export const VEHICLE_COLORS = Object.keys(VehicleColour).reduce(
  (vehicleColors, vehicleColor) => {
    if (!isNaN(Number(vehicleColor))) return vehicleColors;

    return [
      ...vehicleColors,
      {
        vehicleColor,
        value: VehicleColour[vehicleColor as keyof typeof VehicleColour],
      },
    ];
  },
  [] as { vehicleColor: string; value: VehicleColour }[]
);

export interface Vehicle extends BaseEntity, LocationDetailsDTO {
  // link to user
  rating?: number;
  userId: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  // details
  licensePlate: string;
  verified: VerificationStatus;
  brand: string;
  vehicle_model: string;
  vehicle_make: string;
  vehicle_year: any;
  year: string;
  vehicleType: string;
  imageUrls: string[];
  transmissionType: TransmissionType;
  fuelType: FuelType;
  colour: VehicleColour;
  pricePerDay: string;
  archived: boolean;
  suburb: string;
  town: string;
  bookedDates: number[];
  reservedDates: number[];
  carCoverImage?: string[];
  isLive: boolean;
}

export class DefaultVehicle implements Vehicle {
  userId: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  licensePlate: string = '';
  verified: VerificationStatus = VerificationStatus.PENDING;
  brand: string = '';
  vehicle_model: string = '';
  vehicle_make: string;
  vehicle_year: any;
  year: string = '';
  vehicleType: string = '';
  imageUrls: string[] = [];
  transmissionType: TransmissionType = TransmissionType.Manual;
  fuelType: FuelType = FuelType.Petrol;
  colour: VehicleColour = VehicleColour.Black;
  pricePerDay: string = '';
  archived: boolean = false;
  isLive: boolean;
  bookedDates: number[] = [];
  reservedDates: number[] = [];
  id: string = '';
  createdAt: number = Date.now();
  updatedAt: number = Date.now();

  lat = EMPTY_LOCATION.lat;
  long = EMPTY_LOCATION.long;
  geohash = EMPTY_LOCATION.geohash;
  googlePlaceId = EMPTY_LOCATION.googlePlaceId;
  road = EMPTY_LOCATION.road;
  suburb = EMPTY_LOCATION.suburb;
  town = EMPTY_LOCATION.town;

  constructor(user: User) {
    this.userId = user.id;
    this.userEmail = user.email;
    this.userFirstName = user.firstName;
    this.userLastName = user.lastName;
  }
}
